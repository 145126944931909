// @ts-nocheck

let urlBase = null;

export const getHostUrlInternal = (scriptUrl) => {
  return (
    urlBase ||
    (function () {
      const url = new URL(scriptUrl);
      url.pathname = '';
      url.search = '';
      url.hash = '';

      return (urlBase = url.toString().replace(/\/$/, ''));
    })()
  );
};

export const getHostUrl = () => {
  if (window.ensOverrideHostUrl) {
    return window.ensOverrideHostUrl;
  }

  return import.meta.env.MODE === 'test' ? 'http://local.dev' : getHostUrlInternal(import.meta.url);
};

window.__loadDynamicResource = function (file: string) {
  const fullPathFile = getHostUrl(false) + '/api/ens/v1/frontfile/frontend/' + file;
  return fullPathFile;
};
