import React from 'react';

import SingleBikeInput from './SingleBikeInput';
import { useAppSelector } from '@/hooks';
import { Space } from '@mantine/core';

const BikeInput = () => {
  const userData = useAppSelector((state) => state.userData);
  return (
    <>
      {Object.keys(userData.bikes).map((bikeId, index) => (
        <React.Fragment key={bikeId}>
          {index === 0 ? null : <Space h={15} />}
          <SingleBikeInput
            bikeId={bikeId}
            bikeIndex={index + 1}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default BikeInput;
