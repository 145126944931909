import { getIsOfferRequestProcess } from '@/processes/offerRequestProcess/IsOfferRequestProcess';
import { getSavedFilterValues } from './customFilterSaver';

export const resetFilterProposal = () => {
  return async (dispatch, getState: RootStateGetter) => {
    const state = getState();

    let valuesOverride = null;

    if (!getIsOfferRequestProcess(state)) valuesOverride = getSavedFilterValues(getState());

    await dispatch({ type: 'FILTER_PROPOSALS_RESET', valuesOverride, loadDefaultValues: true });
  };
};
