import React from 'react';
import { TabledPriceDisplay } from '../shared/pricing/FullPriceDisplay';

export const ZusammenfassungPriceDisplay = () => {
  return (
    <>
      <TabledPriceDisplay hideSum></TabledPriceDisplay>
    </>
  );
};
