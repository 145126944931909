import { EnsuranceOffer } from '@/actions/types/EnsuranceOffer';

export const sortByPrice = (a: any, b: any) => {
  const price = (compareEl) => {
    if (compareEl.ranges?.length) {
      // average of range
      const rangeAvgs = compareEl.ranges.map((range) => {
        return (range.offerMin + range.offerMax) / 2;
      });
      return rangeAvgs.reduce((prevVal, currVal) => prevVal + currVal, 0) / compareEl.ranges.length;
    } else if (compareEl.offerMin) {
      return compareEl.offerMin;
    } else if (compareEl.prices?.brutto.length && compareEl.prices?.brutto[0] > 0) {
      // sum prices of all bikes
      return compareEl.prices.brutto.reduce((prevVal, currVal) => prevVal + currVal, 0);
    } else {
      return 9999999;
    }
  };
  return price(a) > price(b) ? 1 : -1;
};

// Function currently unused
export const sortByService = (a, b) => {
  const trueA = a.headpoints.filter((el) => {
    return el[1] === true;
  }).length;
  const trueB = b.headpoints.filter((el) => {
    return el[1] === true;
  }).length;
  if (trueA < trueB) return 1;
  if (trueA > trueB) return -1;

  const falseA = a.headpoints.filter((el) => {
    return el[1] === false;
  });
  const falseB = b.headpoints.filter((el) => {
    return el[1] === false;
  });
  if (falseA < falseB) return -1;
  if (falseA > falseB) return 1;

  const intermediateA = a.headpoints.filter((el) => {
    return el[1] === '->';
  });
  const intermediateB = b.headpoints.filter((el) => {
    return el[1] === '->';
  });
  if (intermediateA < intermediateB) return 1;
  if (intermediateA > intermediateB) return -1;

  return 0;
};

export const sortByValuation = (a: EnsuranceOffer, b: EnsuranceOffer) => {
  if (!a?.badgeDetails?.percent && !b?.badgeDetails?.percent) return sortByService(a, b);

  if (!a?.badgeDetails?.percent) return 1;
  if (!b?.badgeDetails?.percent) return -1;

  const valA = parseInt(a.badgeDetails.percent?.toString());
  const valB = parseInt(b.badgeDetails.percent?.toString());
  return valB - valA;
};

export const sortByPopularity = (a, b) => {
  if (a.orderCount > b.orderCount) return -1;
  if (a.orderCount < b.orderCount) return 1;
  return 0;
};
