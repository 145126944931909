import { MdHelp } from 'react-icons/md';
import React from 'react';
import { Box, Tooltip } from '@mantine/core';

const Infobutton = ({ text, children = null, style = null }) => {
  return (
    <Tooltip
      offset={5}
      label={text}>
      <span>
        {children}
        <Box
          style={style}
          ml="3px"
          display={'inline-block'}>
          <MdHelp />
        </Box>
      </span>
    </Tooltip>
  );
};

export default Infobutton;