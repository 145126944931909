import { Validator } from 'validatorjs';
import { DynamicEntryQuestionType } from '@/actions/types/DynamicEntryQuestionType';
import { getCountryCode } from '@/actions/shared/getCountryCode';

export const mapValidatorJsErrors = (err, validator: Validator<any>) => {
  const allErrors = validator.errors.all();
  // eslint-disable-next-line no-param-reassign
  err = [...err, ...Object.keys(allErrors)];
  return err;
};

const customFilterToValidatorJsString = (field: DynamicEntryQuestionType, state?: RootState) => {
  let valJsSplits = field.customValidation?.split('|') || [];

  const requiredString = ['checkbox', 'confirmation'].includes(field.fieldType) ? 'accepted' : 'required';
  const addRequired = field.required && !valJsSplits.includes(requiredString);

  // Custom Filter for PLZ https://app.asana.com/0/1174272655772669/1204969351492297/f
  if (field.name === 'plz') {
    valJsSplits = valJsSplits.filter((val) => !val.startsWith('digits_between'));
    valJsSplits.push('digits_between:' + (getCountryCode(state) === 'AT' ? '4,4' : '5,5'));
  }

  return valJsSplits.join('|') + (addRequired ? (field.customValidation ? '|' : '') + requiredString : '');
};

export const customFieldToValidatorJs = (fields: DynamicEntryQuestionType[], state?: RootState): Validator.Rules => {
  const validatorJsRules = {};
  fields.forEach((field) => {
    validatorJsRules[field.name] = customFilterToValidatorJsString(field,state);
  });

  return validatorJsRules;
};
