import { useAppSelector } from '@/hooks';
import { createSlice } from '@reduxjs/toolkit';

const ensOptionsSlice = createSlice({
  name: 'ensOpt',
  initialState: {} as EnsOptions,
  reducers: {},
});

export const useEnsOptions = () => useAppSelector((state) => state.ensOptions);

export default ensOptionsSlice.reducer;
