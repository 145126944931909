import queryString from 'query-string';
import { focusByClass } from './shared/focusByClass';
import { getOriginalWindowSearch } from './shared/QueryParams';
import { DeepPartialExcept } from './types/lib/DeepPartial';

export const focusErrorField = () => focusByClass('ensurance_error_field');

export { getUrl } from './shared/getUrl';
export { getWindowUrl } from './shared/getWindowUrl';
export { getPageMode } from './shared/getPageMode';

export const getRefererId = (
  state: DeepPartialExcept<RootState, { ensOptions: { refererOverride } }>
): string | null => {
  const parsed = queryString.parse(getOriginalWindowSearch());

  if (window.ensRefererOverride) {
    return window.ensRefererOverride;
  } else if (state.ensOptions.refererOverride) {
    return state.ensOptions.refererOverride;
  } else if (parsed.refererId || parsed.referId) {
    const queryVal = parsed.refererId || parsed.referId;
    return Array.isArray(queryVal) ? (queryVal.length > 0 ? queryVal[0] : null) : queryVal;
  } else {
    return null;
  }
};
