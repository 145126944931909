import { useAutoloadEnsurances } from '@/features/AutoloadEns';
import { useAppDispatch } from '@/hooks';
import { useMantineTheme } from '@mantine/core';
import { useMediaQuery, useViewportSize } from '@mantine/hooks';
import { useEffect, useMemo } from 'react';

export const CompareTableElementCount = (props: {}) => {
  const dispatch = useAppDispatch();

  const mode = useAutoloadEnsurances();

  const theme = useMantineTheme();

  const sizes = [
    useMediaQuery(`(min-width: ${theme.breakpoints.xl}px)`),
    useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`),
    useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`),
    useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`),
  ];

  const newValue = useMemo(() => {
    if (mode !== 'compare_standalone') return null;

    const [isXl, isLg, isMd, isSm] = sizes;

    if (isXl) return 6;
    if (isLg) return 6;
    if (isMd) return 5;
    if (isSm) return 4;

    return 4;
  }, [mode, ...sizes]);

  useEffect(() => {
    dispatch({ type: 'COMPARE_TABLE_ELEMENT_COUNT', payload: newValue });
  }, [newValue]);

  return null;
};
