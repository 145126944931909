import { validate as validateEmail } from 'email-validator';
import isValidDomain from 'is-valid-domain';
import { parseDomain } from 'parse-domain';

export const ensEmailValidate = (email: string) => {
  if (!validateEmail(email)) {
    return false;
  }

  if (!email.includes('@')) {
    return false;
  }

  const domain = email.split('@')[1];

  const domainInfo = parseDomain(domain);

  if (domainInfo.type !== 'LISTED') return false;

  return true;
};
