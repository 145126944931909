export const getArraySortFunc = (props: string | string[]) => {
  if (!Array.isArray(props)) props = [props];

  return (a, b) => {
    for (const sortProp of props) {
      if (a[sortProp] < b[sortProp]) return -1;
      else if (b[sortProp] < a[sortProp]) return 1;
    }
    return 0;
  };
};
