import React, { useEffect, useRef } from 'react';
import { forwardRef } from 'react';

export const WheelCapture = React.memo((props: React.PropsWithChildren<{ onWheel: (e: WheelEvent) => void }>) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.addEventListener('wheel', props.onWheel);
    return () => ref.current?.removeEventListener('wheel', props.onWheel);
  }, [props.onWheel]);

  return <div ref={ref}>{props.children}</div>;
});
