import { createSlice } from '@reduxjs/toolkit';

const ensPresetSlice = createSlice({
  name: 'ensPrst',
  initialState: {
    ensPreset: {} as {
      customer?: any;
      bike?: any;
      bike2?: any;
      object?: any;
      object2?: any;
      presetId?: any;
    } | null,
    ensPresetData: null,
  },
  reducers: {
    updatePresetData: (state, action) => {
      state.ensPresetData = action.payload;
    },
  },
});

export default ensPresetSlice.reducer;

export const { updatePresetData } = ensPresetSlice.actions;
