import { DynamicEntryQuestionType } from '@/actions/types/DynamicEntryQuestionType';
import { Box, Button, Flex, SimpleGrid } from '@mantine/core';
import React, { KeyboardEvent, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import DynamicEntryQuestion, { evaluateFilter } from '../DynamicEntryQuestion';
import { useFadeOutClicks } from '@/lib/useFadeOutClick';
import { useAppDispatch } from '@/hooks';
import { ButtonLink } from '@unserkunde/enscompare-components';
import { EnsIcon } from '../AvailalbeEnsIcons';
import { validateDynamic } from '@/actions/validation/lib/DynamicFieldValidation';
import { ValidationState } from '@/reducer/validation';
import { GetIsDevMode } from '../IsDevMode';
import { useGlobalBackAction } from '@/processes/shared/GlobalBackContext';

function useTriggerNextWhenEmpty(
  selectedEntries: DynamicEntryQuestionType[],
  bikeId: string | undefined,
  pageId,
  fieldGroupIndex,
  onContinue: () => void,
  onReset: () => void
) {
  const dispatch = useAppDispatch();

  const [rememberedPageId, setRememberedPageId] = useState<string | number | null>(null);

  useLayoutEffect(() => {
    dispatch((_, getState: RootStateGetter) => {
      if (rememberedPageId !== pageId) {
        setRememberedPageId(pageId);
        onReset();
        return;
      }

      const state = getState();
      const validFields = selectedEntries.map((setting) => !!evaluateFilter(setting, bikeId, state)).filter((x) => x);

      if (validFields.length === 0) onContinue();
    });
  }, [bikeId, fieldGroupIndex, selectedEntries, rememberedPageId, pageId, onContinue]);
}

const useGroupedFields = (fields: DynamicEntryQuestionType[], bikeId?: string): DynamicEntryQuestionType[][] => {
  return useMemo(() => {
    const target: Record<string, DynamicEntryQuestionType[]> = {};

    fields.forEach((f) => {
      const targetGroup = f.groupWithField || f.name;
      if (!target[targetGroup]) target[targetGroup] = [];
      target[targetGroup].push(f);
    });

    return Object.values(target);
  }, [fields, bikeId]);
};

export const ProceduralCheckoutInputs = (props: {
  fields: DynamicEntryQuestionType[];
  onCompleted?: () => void;
  onPrevious?: () => void;
  bikeId?: string;
  pageId: string | number;
}) => {
  const dispatch = useAppDispatch();

  const fieldGroups = useGroupedFields(props.fields, props.bikeId);

  const [fieldGroupIndex, setFieldGroupIndex] = useState(0);

  const selectedEntries = useMemo(() => {
    return fieldGroups[fieldGroupIndex] ?? [];
  }, [fieldGroupIndex, fieldGroups]);

  const onContinue = useCallback(() => {
    const nextIndex = fieldGroupIndex + 1;
    if (nextIndex < fieldGroups.length - 1) {
      setFieldGroupIndex(nextIndex);
      return;
    }

    props.onCompleted?.();
    setFieldGroupIndex(0);
  }, [fieldGroups, fieldGroupIndex, props.pageId, props.onCompleted]);

  const onPrevious = useCallback(() => {
    dispatch((_, getState: RootStateGetter) => {
      const state = getState();
      let prevGroupPointer = fieldGroupIndex - 1;
      while (prevGroupPointer >= 0) {
        const prevGroup = fieldGroups[prevGroupPointer];
        const validFields = prevGroup.map((setting) => !!evaluateFilter(setting, props.bikeId, state)).filter((x) => x);

        if (validFields.length > 0) {
          setFieldGroupIndex(prevGroupPointer);
          return;
        }

        prevGroupPointer--;
      }

      setFieldGroupIndex(0);
      props.onPrevious?.();
    });
  }, [fieldGroups, fieldGroupIndex, props.bikeId, props.onPrevious]);

  const [[onContinueFade, onPreviousClick], FadeContainer] = useFadeOutClicks(
    200,
    selectedEntries,
    onContinue,
    onPrevious
  );

  const hasGlobalBackHandler = useGlobalBackAction(onPreviousClick);

  useTriggerNextWhenEmpty(selectedEntries, props.bikeId, props.pageId, fieldGroupIndex, onContinue, () =>
    setFieldGroupIndex(0)
  );

  const onContinueClick = useCallback(() => {
    // validate current
    dispatch((_, getState: RootStateGetter) => {
      const state = getState();
      const validationResult = selectedEntries.flatMap((field) =>
        validateDynamic(
          state.userData,
          (sel) => sel.name === field.name && sel.position === field.position,
          state,
          props.bikeId
        )
      );

      const error: ValidationState = props.bikeId
        ? {
            bikeErrors: {
              [props.bikeId]: validationResult,
            },
            errors: [],
          }
        : { bikeErrors: {}, errors: validationResult };

      dispatch({
        type: 'CHANGE_VALIDATION_ERRORS',
        ...error,
      });

      if (validationResult.length > 0) return;
      onContinueFade();
    });
  }, [onContinueFade]);

  const enterListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') onContinueClick();
    },
    [onContinueClick]
  );

  return (
    <>
      {GetIsDevMode() && (
        <pre>
          {JSON.stringify({
            fieldGroups: fieldGroups.length,
            fieldGroupIndex,
          })}
        </pre>
      )}
      <FadeContainer onKeyDown={enterListener}>
        <Flex
          w={'100%'}
          direction={'column'}>
          {selectedEntries
            .filter((o) => o)
            .map((entry, i) => (
              <React.Fragment key={entry?.name + '_' + i}>
                <DynamicEntryQuestion
                  setting={entry}
                  disableWrap
                  style={{ width: '100%' }}
                  bikeId={props.bikeId}
                  maw={1000000}
                  autoFocus={true}
                />
              </React.Fragment>
            ))}
        </Flex>
        <SimpleGrid
          mt='3em'
          cols={2}
          style={{ alignItems: 'center' }}>
          {!hasGlobalBackHandler ? (
            <ButtonLink
              style={{ justifySelf: 'start' }}
              leftIcon={
                <EnsIcon
                  icon='FaArrowLeft'
                  useAppColor={true}
                />
              }
              onClick={onPreviousClick}>
              Zurück
            </ButtonLink>
          ) : (
            <Box />
          )}
          <Button
            onClick={onContinueClick}
            color='secondary'
            my='sm'>
            Weiter
          </Button>
        </SimpleGrid>
      </FadeContainer>
    </>
  );
};
