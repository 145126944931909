import { getUrl } from '../data';
import requestEnsurance from './requestEnsurance';
import { addCustomFilter, addUserDataPostFields } from './sharedPostField';

let controller: AbortController;

export const updateVoucher = (options: { skipUpdateEnsurance?: boolean; waitForUpdateEnsurance?: boolean } = {}) => {
  return async (dispatch, getState: RootStateGetter) => {
    if (controller) controller.abort('Got a new voucher request');
    controller = new AbortController();

    const state = getState();
    const currentVoucherCode = state.userData.voucherCode;
    await dispatch({ type: 'ENS_VOUCHER_LOADING', code: currentVoucherCode });

    const request = JSON.parse(JSON.stringify(state.userData));

    const formData = new FormData();
    addUserDataPostFields(state, request, formData);
    addCustomFilter(state, formData);

    const result = await fetch(getUrl('/wp-json/ens/v1/voucher/' + encodeURI(currentVoucherCode)), {
      method: 'post',
      body: formData,
      signal: controller.signal,
    })
      .then((o) => o.json())
      .catch(() => null);

    const oldCode = getState().voucher?.voucher?.code;
    const oldState = getState().voucher?.voucher?.valid;
    const newValidValid = result && result.valid;

    await dispatch({
      type: 'ENS_VOUCHER_LOADED',
      voucher: result,
      valid: newValidValid,
    });

    if (
      getState().checkout.offer?.name &&
      (oldState !== newValidValid || (newValidValid && oldCode !== result?.voucher?.code)) &&
      !options?.skipUpdateEnsurance
    ) {
      const reloadAction = () => dispatch(requestEnsurance());

      if (!options.waitForUpdateEnsurance) {
        reloadAction();
      } else {
        await reloadAction();
      }
    }
  };
};
