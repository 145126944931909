import { getQueryStrings } from '../../actions/shared/QueryParams';

let isDevMode: boolean | null = null;

export const GetIsTestMode = () => {
  return process.env.NODE_ENV === 'test';
};

export const GetIsDevMode = () => {
  if (isDevMode === null) isDevMode = !!getQueryStrings().devmode;

  return isDevMode;
}; //|| getOriginalWindowHost().includes('localhost');

const IsDevMode = ({ children }) => {
  if (!GetIsDevMode()) return null;
  return children;
};

export default IsDevMode;
