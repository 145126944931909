const defaultState = {
  suspenseLoading: false
};

const AppReducer = (state: typeof defaultState = defaultState, action): typeof defaultState => {
  if (action.type === 'SUSPENSE_LOADING') {
    return { ...state, suspenseLoading: true };
  } else if (action.type === 'SUSPENSE_LOADED') {
    return { ...state, suspenseLoading: false };
  }

  return state;
};

export default AppReducer;
