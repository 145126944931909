import hotjar from './hotjar';
import gtm from './gtm';
import fbevents from './fbevents';
import { consoleTracker } from './console';
import { getPresetId } from '@/actions/shared/getPresetId';
import { MicrosoftAdsTracker } from './MicrosoftAds';

// Siehe https://app.asana.com/0/1174272655772669/1203795911406277/f
const noExternalTrackingPresetIds = ['64a2bab8287d65.22704576', '64f88e4726fe25.88397171'];

export const isExternTrackingEnabled = (ensPreset: RootState['ensPreset']['ensPreset']) => {
  return !noExternalTrackingPresetIds.includes(getPresetId(ensPreset));
};

export const trackers = [hotjar, fbevents, gtm, MicrosoftAdsTracker, consoleTracker];

export const track = (trackingObj) => {
  return (dispatch, getState) => {
    if (!isExternTrackingEnabled(getState().ensPreset.ensPreset)) return;
    trackers.map((tr) => tr(trackingObj, dispatch));
  };
};

export const trackByName = (name) => {
  return track({ name });
};
