import { CustomProducSelectState } from '@/reducer/customProducts/customProducts.types';
import { getUrl } from '../data';
import { formatPostValue } from '../post';
import { submitGeneral } from '../progress';
import { addUserDataPostFields } from './sharedPostField';
import { validateInspectionVoucher } from '@/actions/validation/validationInspectionVoucher';

export const loadVSNInfoForPremiumprocess = () => {
  return async (dispatch: AppDispatch, getState: RootStateGetter) => {
    const state = getState();

    if (!state.inspectionVoucher.userData.vsn || !state.inspectionVoucher.userData.lastname) {
      dispatch({ type: 'PREMIUMSERVICE_VSN_USER_LOADED', found: null, a: '1' });
      return;
    }

    dispatch({ type: 'PREMIUMSERVICE_VSN_USER_LOADING' });

    const res = await fetch(getUrl('/wp-json/ens/v1/inspectionVoucher/getUserByVSN'), {
      body: JSON.stringify({
        vsn: state.inspectionVoucher.userData.vsn,
        lastname: state.inspectionVoucher.userData.lastname,
      }),
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((e) => e.json());

    const { found, ...data } = res;

    if (found) dispatch({ type: 'PREMIUMSERVICE_VSN_USER_LOADED', ...data, found });
    else dispatch({ type: 'PREMIUMSERVICE_VSN_USER_LOADED', found });
  };
};

export const addInspectionVoucherPostFields = (state: RootState, formData: FormData) => {
  const request = JSON.parse(JSON.stringify(state.userData));

  addUserDataPostFields(state, request, formData);

  formData.set('orderGroup', state.orderSubmit.orderGroup);

  for (const userDataKey in state.inspectionVoucher.userData) {
    if (formData.get(userDataKey) && !state.inspectionVoucher.userData[userDataKey]) continue;
    formData.set(userDataKey, formatPostValue(state.inspectionVoucher.userData[userDataKey]));
  }
};

export const updateInspectionVoucherPrice = () => {
  return async (dispatch: AppDispatch, getState: RootStateGetter) => {
    const state = getState();
    const formData = new FormData();
    addInspectionVoucherPostFields(state, formData);

    dispatch({ type: 'INSPECTIONVOUCHER_PRICE_LOAD' });

    const result = await fetch(getUrl('/wp-json/ens/v1/inspectionVoucher/getPrice'), {
      body: formData,
      method: 'post',
    }).then((o) => o.json());

    dispatch({ type: 'INSPECTIONVOUCHER_PRICE_LOADED', result });
  };
};

export type GpsTrackerTrackerProduct = CustomProducSelectState<'gpsTracker'>;

export const submitInspectionVoucher = (
  options: {
    smallPremiumservice?: boolean;
    overrideBookPremiumserivce?: boolean;
    gpsProductInfo?: null | GpsTrackerTrackerProduct;
  } = {}
) => {
  
  return async (dispatch: AppDispatch, getState: RootStateGetter) => {
    const onSubmit = async () => {
      
      const { smallPremiumservice = false, overrideBookPremiumserivce = false, gpsProductInfo = null } = options;
      
      dispatch({ type: 'INSPECTIONVOUCHER_ORDER' });

      const formData = new FormData();
      addInspectionVoucherPostFields(getState(), formData);
      formData.append('smallPremiumservice', smallPremiumservice ? '1' : '0');
      if (overrideBookPremiumserivce) {
        formData.set('bookPremiumservice', '1');
      }
      
      if(gpsProductInfo) {
        formData.set('gpsProductInfo', JSON.stringify(gpsProductInfo));
      }

      const result = await fetch(getUrl('/wp-json/ens/v1/inspectionVoucher/submitOrder'), {
        body: formData,
        method: 'post',
      }).then((o) => o.json());

      dispatch({ type: 'INSPECTIONVOUCHER_ORDER_COMPLETE', result });
    };

    dispatch(submitGeneral(validateInspectionVoucher, null, onSubmit, (state) => state));
  };
};

export const submitInspectionVoucherLegacy = (smallPremiumservice = false, overrideBookPremiumserivce = false) => {
  return submitInspectionVoucher({ smallPremiumservice, overrideBookPremiumserivce });
};
