import { createReducer } from '@reduxjs/toolkit';

const DefaultState = {
  loaded: false,
  loading: false,
  id: null as string,
  idSources: {} as Record<VisitorIdSource, string>,
};

export type VisitorIdSource = 'store' | 'predef';

export const setVisitorId = (id: string, source: VisitorIdSource = 'store') => {
  return {
    type: 'VISITOR_ID_LOADED',
    payload: id,
    source,
  };
};

export const VisitorIdReducer = createReducer(DefaultState, (builder) => {
  builder.addCase('VISITOR_ID_LOADING', (state) => {
    state.loading = true;
  });
  builder.addCase('VISITOR_ID_LOADED', (state, action: any) => {
    state.idSources[action.source || 'store'] = action.payload;
    state.loaded = true;
    state.loading = false;

    state.id = state.idSources.predef || state.idSources.store || '';
  });
});
