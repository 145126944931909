import React from 'react';
import { connect } from 'react-redux';
import { Container, Title } from '@mantine/core';

import { changeUserInput } from '@/actions/form';

import { useDynamicFields } from '@/hooks';
import DynamicEntryQuestion, { EntryQuestionContainer } from '@/forms/shared/DynamicEntryQuestion';
import { useInsuranceObjectName } from '@/forms/shared/InstanceNaming';

const SingleBikeInput = ({ bikeUserData, bikeIndex, bikeId }) => {
  const customQuestions = useDynamicFields('objectQuestions');

  const ensuranceObjectName = useInsuranceObjectName();

  return (
    <>
      <Container mb={'xl'}>
        <Title order={2}>
          Dein {ensuranceObjectName} {bikeIndex}{' '}
          {!bikeUserData.price ? null : <span style={{ fontSize: '14px' }}>(Kaufpreis: {bikeUserData.price} €)</span>}
        </Title>
      </Container>

      <EntryQuestionContainer>
        {customQuestions.map((e, i) => (
          <DynamicEntryQuestion
            bikeId={bikeId}
            setting={e}
            key={i}
          />
        ))}
      </EntryQuestionContainer>
    </>
  );
};

const mapStateToProps = (state, props) => {
  return {
    bikeUserData: state.userData.bikes[props.bikeId],
    hasError: (value) =>
      (state.validation.bikeErrors || {})[props.bikeId]
        ? state.validation.bikeErrors[props.bikeId].includes(value)
        : false,
    options: state.options.options
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onChangeInput:
      (field, selector = null) =>
      (value) =>
        dispatch(changeUserInput(field, (selector || ((o) => o))(value), props.bikeId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleBikeInput);
