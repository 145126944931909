import React from 'react';

export const TextStyle = { fontSize: '14px' };
export const TextBiggerStyle = { fontSize: '16px' };
export const TextBigStyle = { fontSize: '18px' };

export const Text = ({ children, style }) => (
  <span style={!style ? TextStyle : { ...TextStyle, ...style }}>{children}</span>
);

export const TextBigger = ({ children, style }) => (
  <span style={!style ? TextBiggerStyle : { ...TextBiggerStyle, ...style }}>{children}</span>
);

export const TextBig = ({ children, style }) => (
  <span style={!style ? TextBigStyle : { ...TextBigStyle, ...style }}>{children}</span>
);
