import React, { useEffect, useMemo, useState } from 'react';
import InputWrapperAutowrap from '@unserkunde/enscompare-components/src/components/inputs/InputWrapperAutowrap';
import DateInput from '@unserkunde/enscompare-components/src/components/inputs/EnsDateInput';
import { useAppSelector, useUserData } from '@/hooks';
import { HookedRadioSwitchRow, NoStretchWidth } from '@/forms/shared/HookedInputs';
import { getDefaultEnsuranceStartDate } from '@/reducer/userData';
import { hasEnsfieldFlag } from '@/reducer/ensfields';

const options: [string, string][] = [
  ['Nächstmöglich', '1'],
  ['Anderes Datum', '0']
];

const Versicherungsbeginn = () => {
  const [value, onChangeHook, hasError] = useUserData('ensuranceStartDate', null, (e) => e);

  const useNextAvailableEnsuranceStartDate = useAppSelector(
    (state) => state.userData.useNextAvailableEnsuranceStartDate
  );

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (hasEnsfieldFlag(useNextAvailableEnsuranceStartDate) && loaded) {
      onChangeHook(getDefaultEnsuranceStartDate());
    }
  }, [useNextAvailableEnsuranceStartDate]);
  useEffect(() => {
    setLoaded(true);
  }, []);

  const styleProps = useMemo(() => ({ maw: NoStretchWidth + 4, gutter: 4 }), []);

  return (
    <>
      <HookedRadioSwitchRow
        field="useNextAvailableEnsuranceStartDate"
        values={options}
        title="Gewünschter Versicherungsbeginn"
      />
      <InputWrapperAutowrap
        error={hasError}
        label={' '}>
        <DateInput
          {...styleProps}
          value={value}
          onBlur={onChangeHook}
          hasError={hasError && !hasEnsfieldFlag(useNextAvailableEnsuranceStartDate)}
          disabled={hasEnsfieldFlag(useNextAvailableEnsuranceStartDate)}
        />
      </InputWrapperAutowrap>
    </>
  );
};

export default Versicherungsbeginn;
