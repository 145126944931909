import { getUrl } from '@/actions/data';
import { addCustomFilter, addUserDataPostFields } from '@/actions/data/sharedPostField';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const reloadCustomProducts = createAsyncThunk('customProducts/reloadCustomProducts', async (props, thunkApi) => {
  const state = thunkApi.getState() as RootState;

  const { name } = state.checkout;

  const request = {
    ...JSON.parse(JSON.stringify(state.userData)),
  };

  const formData = new FormData();

  addUserDataPostFields(state, request, formData);
  addCustomFilter(state, formData);
  formData.append('ensName', name);

  const result = await fetch(getUrl('/api/ens/v1/customProducts/byRequest'), {
    body: formData,
    method: 'post',
  }).then((o) => o.json());


    return result;
});
