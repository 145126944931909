import queryString from 'query-string';
import { getOriginalWindowSearch } from '../actions/shared/QueryParams';
import { Devicemode } from './ensfields';
import { getIsOfferRequestProcess } from 'processes/offerRequestProcess/IsOfferRequestProcess';

declare let window: any;

export type Devicemode_selectable = 'spedelec' | 'pedelec' | 'bike' | 'gewerblich' | '' | null;

export type DeviceMode = {
  devkey?: Devicemode_selectable;
  isselect?: boolean;
};

const getDefaultValue = (action: { devicemodes: Devicemode[]; ensOptions: EnsOptions }): DeviceMode => {
  const parsed = queryString.parse(getOriginalWindowSearch());

  if (parsed.inspectionvouchertest === '1') {
    return { devkey: 'pedelec', isselect: false };
  }

  const selectableModes = action.devicemodes.filter((mode) => mode.selectable).map((mode) => mode.devkey);

  let dkey = parsed.ensDevicemode || window.devicekey || action.ensOptions?.devicekey;

  const queryDKey = parsed.devicemode || parsed.devicekey;
  if ((!dkey || dkey === 'select') && queryDKey) {
    dkey = queryDKey;
  }

  if (getIsOfferRequestProcess({ options: { presetIdData: {} }, ensOptions: action.ensOptions })) {
    dkey = action.devicemodes.find((mode) => mode.default)?.devkey || action.devicemodes[0].devkey;
  } else if (dkey === 'select') {
    dkey = '';
  } else if (!selectableModes.includes(dkey)) {
    dkey = action.devicemodes.find((mode) => mode.default)?.devkey || action.devicemodes[0].devkey;
  }

  const startValue = { devkey: dkey, isselect: dkey === '' };
  return startValue;
};

export default function devicemode(state: DeviceMode = {}, action): DeviceMode {
  if (action.type === 'APP_REQUIREMENTS_LOADED') {
    return state.devkey ? state : getDefaultValue(action);
  }
  if (action.type === 'DEVICEMODE_SELECTED') {
    return {
      ...state,
      devkey: action.devkey === 'select' ? '' : action.devkey,
    };
  }

  if (action.type === 'PREFED_LOADED' && action.data.devicekey) {
    return { ...state, devkey: action.data.devicekey };
  }

  return state;
}
