import Audit from '@/actions/data/audit';
import React from 'react';
import { detect } from 'detect-browser';

//import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundary } from '@sentry/react';

const transformStacktrace = async (stack: string) => {
  const { mapStackTrace } = await import('sourcemapped-stacktrace');

  return await new Promise((resolve) => {
    try {
      mapStackTrace(stack, (mapped) => {
        resolve(mapped);
      });
    } catch (e) {
      resolve(stack);
    }
  });
};

export const EnsErrorBoundary = (props: React.PropsWithChildren<unknown>) => {
  const [auditRes, setAuditRes] = React.useState(null);

  const onLogError = async (error: unknown, stack: string | unknown, eventId: string) => {
    const transformedStack = typeof stack !== 'string' ? null : await transformStacktrace(stack);

    Audit(
      { error: error.toString(), stack, transformedStack, eventId, browser: detect() },
      '0',
      'client:error:critical'
    )
      .then((res) => res.json())
      .then((res) => {
        const regRes = /(\d+)/.exec(res);
        if (!regRes || !regRes.length) return;
        setAuditRes(regRes[0]);
      });
  };

  return (
    <ErrorBoundary
      fallback={
        <div role='alert'>
          <p>Es ist ein Fehler aufgetreten. Unsere IT arbeitet an einer Lösung ({auditRes})</p>
        </div>
      }
      onError={onLogError}>
      {props.children}
    </ErrorBoundary>
  );
};
