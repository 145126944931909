import requestEnsurance from '@/actions/data/requestEnsurance';
import { changeUserInput } from '@/actions/form';
import { usePremiumserviceChecklist } from '@/forms/InspectionVoucher/InspectionVoucherOrderComponent';
import { GpsTrackerHeadpoints } from '@/forms/success/GpsSelectionCard';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { closeOverlay } from '@/processes/overlay/overlayReducer';
import { setProductState } from '@/reducer/customProducts';
import { CustomProductNames } from '@/reducer/customProducts/customProducts.types';
import { Anchor, Button, Group, Stack, Title } from '@mantine/core';
import { Checklist } from '@unserkunde/enscompare-components';
import React, { useEffect, useMemo } from 'react';

const useAction = (product: CustomProductNames) => {
  const dispatch = useAppDispatch();
  const productSel = useAppSelector((state) => state.customProducs.productSelection[product]);

  return useMemo(
    () => ({
      onBack: () => dispatch(closeOverlay()),
      onContinue:
        productSel && productSel.amount > 0
          ? null
          : () => {
              dispatch(setProductState(product, { amount: 1, variant: 'default' }));
              dispatch(requestEnsurance());
              dispatch(closeOverlay());
            },
    }),
    [productSel]
  );
};

const ButtonGroup = (props: { product: CustomProductNames }) => {
  const callback = useAction(props.product);

  return (
    <Group position='right'>
      <Anchor
        color='gray'
        onClick={callback.onBack}>
        Zurück
      </Anchor>
      {callback.onContinue && (
        <Button
          compact
          onClick={callback.onContinue}>
          Hinzufügen und weiter
        </Button>
      )}
    </Group>
  );
};

export const GpsTrackerDetailsOverlay = (props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(changeUserInput('orderPremiumservice__text', JSON.stringify(GpsTrackerHeadpoints.items)));
  }, []);

  return (
    <Stack>
      <Title order={2}>GPS Tracker Produktdetails</Title>
      <Checklist
        items={GpsTrackerHeadpoints.items}
        preview={10000}
        labelClosed={undefined}
        labelOpen={undefined}
      />
      <ButtonGroup product='gpsTracker' />
    </Stack>
  );
};

export const PremiumserviceDetailsOverlay = (props) => {
  const checklist = usePremiumserviceChecklist(false);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(changeUserInput('orderGps__text', JSON.stringify(checklist.items)));
  }, [checklist]);

  return (
    <Stack>
      <Title order={2}>Premiumservice Produktdetails</Title>
      <Checklist
        items={checklist.items}
        preview={10000}
        labelClosed={undefined}
        labelOpen={undefined}
      />
      <ButtonGroup product='premiumservice' />
    </Stack>
  );
};
