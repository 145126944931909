export const localStorageGet = (key: string, defaultValue: string) => {
  try {
    const value = localStorage.getItem(key);
    return value || defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

export const localStorageSet = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
    return true;
  } catch (e) {
    return false;
  }
};

export const localStorageHas = (key: string) => {
  try {
    return key in localStorage;
  } catch (e) {
    return false;
  }
};
