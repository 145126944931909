import { createEmotionCache } from '@mantine/core';
import { detect } from 'detect-browser';

// https://github.com/emotion-js/emotion/issues/2508
export const applyWebkitFix =
  (() => {
    const browser = detect();
    if (!browser) return false;
    return browser.name === 'safari' || browser.name === 'ios';
  })() || true; // Testweise immer nutzen

export const createEnsEmotionCache = (rootElement: HTMLDivElement) =>
  createEmotionCache({
    container: rootElement,
    key: 's',
    ...(applyWebkitFix ? { speedy: false } : {}),
  });
