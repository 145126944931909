import React, { CSSProperties } from 'react';

import { MdHelp } from 'react-icons/md';
import { Row, Col } from 'react-grid-system';

import { Box, Tooltip } from '@mantine/core';
import { UserDataOrBikeProp } from '../../reducer/userData';
import { getLabel } from './Labels';
import Infobutton from './Infobutton';

export { default as Infobutton } from './Infobutton';
export { default as PrevNextButtonRow } from './PrevNextButtonRow';

export const TitleLabel = (props) => (
  <div style={{ marginTop: '10px', minHeight: '14px' }}>
    <label
      style={{ fontWeight: 'bold' }}
      {...props}>
      {props.children}
    </label>
  </div>
);

export const RowTitleLabel = (props) => (
  <label
    style={{ fontWeight: 'bold' }}
    {...props}>
    {props.children}
  </label>
);

export const InputRow = React.memo(
  (
    props: {
      title: string;
      hasError?: any;
      helpText?: string;
      inputSize?: number;
      style?: CSSProperties;
      [x: string]: any;
    } = { title: '', style: {} }
  ) => {
    const inputSize = props.inputSize || 8;

    return (
      <Row
        style={{
          marginTop: '1.3rem',
          borderBottom: '1px dotted #00000000',
          paddingBottom: '3px',
          ...props.style,
        }}
        className={props.hasError ? 'ensurance_error_field' : ''}>
        <Col
          sm={12 - inputSize - (props.helpText ? 1 : 0)}
          xs={6}
          style={{ display: 'flex', alignItems: 'center' }}>
          <RowTitleLabel>{props.title}</RowTitleLabel>
        </Col>
        {!props.helpText ? null : (
          <Col
            sm={1}
            style={{ display: 'flex', alignItems: 'center' }}>
            <Infobutton text={props.helpText} />
          </Col>
        )}
        {(Array.isArray(props.children) ? props.children : [props.children]).map((child, childIndex) => (
          <Col
            key={childIndex}
            sm={
              props.ratio
                ? props.ratio[childIndex]
                : !Array.isArray(props.children)
                  ? inputSize
                  : inputSize / props.children.length
            }
            style={{ fontWeight: 'normal !important' }}>
            {child}
            {!props.descText ? null : (
              <div
                onClick={props.onDescClick}
                style={{
                  color: 'gray',
                  fontSize: '0.8em',

                  ...(props.onDescClick
                    ? {
                        cursor: 'pointer',
                      }
                    : null),
                }}>
                {props.descText}
              </div>
            )}
          </Col>
        ))}
      </Row>
    );
  }
);

export const ValidationContainer = ({ hasError, children, style = {} }) => {
  return (
    <div
      className={hasError ? 'ensurance_error_field' : ' '}
      style={style}>
      {children}
    </div>
  );
};

export const frequentDisplayName = (type) => {
  switch (type) {
    case 'yearly':
    case '1':
      return 'Jährlich';
    case '2':
      return 'Halbjährlich';
    case '4':
      return 'Vierteljährlich';
    case 'monthly':
    case '12':
      return 'Monatlich';
    case 'once':
      return 'Einmalig';
    default:
      return type;
  }
};

export const voucherChangedValue = (value, voucher, period) => {
  if (period === 'once') period = 1;
  switch (voucher.amount?.unit) {
    case 'cent':
      const voucherValuePerPeriod = period ? voucher.amount.value / period : voucher.amount.value;
      const res = value - voucherValuePerPeriod / 100;
      return res > 0 ? res : 0;
    case 'percent':
      return value - (value * voucher.amount.value) / 100;
    default:
      console.warn('unable to calculate value by voucher');
      return value;
  }
};

export const Labeled = ({
  control,
  field,
  label = null,
  optional = false,
  tooltip = '',
  ...additionalProps
}: {
  [x: string]: any;
  field: UserDataOrBikeProp;
}) => {
  const Control = control;
  const labelText = [
    <Box
      lh='2'
      display={'inline-block'}>
      <span key={'label'}>{label || getLabel(field)}</span>
    </Box>,
  ];

  if (optional) labelText.push(<i key={'op_label'}> {getLabel('_optional')}</i>);

  if (tooltip) {
    labelText.push(
      <Tooltip
        offset={5}
        label={tooltip}
        key={'tooltip'}>
        <Box
          ml='5px'
          display={'inline-block'}>
          <MdHelp />
        </Box>
      </Tooltip>
    );
  }

  return (
    <label>
      {labelText}
      <Control
        {...additionalProps}
        field={field}
      />
    </label>
  );
};
