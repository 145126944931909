/* eslint-disable react/prop-types */

import { connect } from 'react-redux';
import queryString from 'query-string';
import * as uuid from 'uuid';

import { bikeStartValue } from '../reducer/userData';

import { updatePreset, updatePresetUserData, loadPresetId, updatePresetIdState } from '../actions/features/preset';

import { getOriginalWindowSearch } from '../actions/shared/QueryParams';
import { useAppSelector, useOnRequiredFieldsLoaded } from '../hooks';

import { getPresetId } from '@/actions/shared/getPresetId';
import { getPresetObjectFromQueryParam } from './PresetFromQueryParam';
import { useEnsOptions } from '@/reducer/ensOptions';
export { getPresetId } from '@/actions/shared/getPresetId';

declare let window: any;

const Preset = (props) => {
  const ensOptions = useEnsOptions();

  const ensPreset = useAppSelector((state) => state.ensPreset.ensPreset);

  useOnRequiredFieldsLoaded(() => {
    const preset = queryString.parse(getOriginalWindowSearch()).preset || ensOptions?.preset || window.preset;
    const presetId = getPresetId(ensPreset);

    if (preset) {
      props.setPreset(preset);
    }

    // All devicemode that are loaded
    const bikeDevicemode: any[] = [];

    const presetSettings = ensPreset;
    if (ensPreset) {
      if (presetSettings.customer) {
        props.setPresetUserData(presetSettings.customer);
      }
    }

    const queryPresetObject = getPresetObjectFromQueryParam();

    if (ensPreset || queryPresetObject) {
      const propsToCheck = ['bike', 'object'];
      const indiciesToCheck = ['', '1', '2'];

      let objectSource = presetSettings;

      objectSource = queryPresetObject || objectSource;

      const userData: { bikes?: object; defaultBikeId?: string } = {};

      const loadObject = (bikeValues, isDefault = false) => {
        if (!userData.bikes) userData.bikes = {};
        const bikeId = uuid.v4();
        userData.bikes[bikeId] = {
          ...bikeStartValue,
          ...bikeValues,
        };
        if (isDefault) userData.defaultBikeId = bikeId;
        bikeDevicemode.push(bikeValues.devicemode || bikeValues.device);
      };

      let presetObjectLoaded = false;
      propsToCheck.forEach((objectProp) => {
        indiciesToCheck.forEach((objectIndex) => {
          const presetObjectKey = `${objectProp}${objectIndex}`;
          if (!objectSource[presetObjectKey]) return;
          loadObject(objectSource[presetObjectKey], !presetObjectLoaded);
          presetObjectLoaded = true;
        });
      });

      if (presetObjectLoaded) props.setPresetUserData(userData);
    }

    if (presetId) {
      // If the devicemode was set by the bike settings, then dont update them via presetId
      const uniqueDeviceModes = bikeDevicemode.filter((o) => o).filter((v, i, a) => a.indexOf(v) === i);
      props.setPresetId(presetId, uniqueDeviceModes.length === 1 ? uniqueDeviceModes[0] : null);
    } else {
      props.setPresetIdState('not_present');
    }
  });

  return null;
};

const mapDispatchToProps = (dispatch) => ({
  setPreset: (name) => dispatch(updatePreset(name)),
  setPresetId: (presetId, uniqueDeviceMode) => dispatch(loadPresetId(presetId, uniqueDeviceMode)),
  setPresetUserData: (userData) => dispatch(updatePresetUserData(userData)),
  setPresetIdState: (state) => dispatch(updatePresetIdState(state)),
});

export default connect(() => ({}), mapDispatchToProps)(Preset);
