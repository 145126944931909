
import ValidatorJS from 'validatorjs';
import de from 'validatorjs/src/lang/de';

// eslint-disable-next-line react-hooks/rules-of-hooks
ValidatorJS.useLang('de');
ValidatorJS.setMessages('de', de);

ValidatorJS.register(
  'before-today',
  (value) => {
    if (!value) return false;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const date = new Date(value + '');

    if (!date) return false;

    return date < today;
  },
  'Der Wert muss in der Vergangenheit liegen'
);

ValidatorJS.register(
    'before-tomorrow',
    (value) => {
        if (!value) return false;
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);


        const date = new Date(value + '');
        if (!date) return false;
        return date < tomorrow;
    },
    'Der Wert muss in der Vergangenheit liegen'
  );