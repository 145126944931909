import React from 'react';

import requestEnsurance from './data/requestEnsurance';
import requestOffers from './data/requestOffers';
import { focusErrorField } from './data';

import angebotAnfordern, { AngeboAnfordernType } from './data/angebotAnfordern';
import submit from './data/submit';
import { trackByName } from './tracking/index';

import { navigateEnsuranceSelected, navigatePaymentinfo } from './navigation';
import { offerMailProcess } from './data/offerMailProcess';

import { EnsPage, navigateTo } from './shared/navigateTo';
import { changeUserInput } from './form';
import { priceRequestSubmit } from './data/priceRequestSubmit';
import { validateInspectionVoucher } from '@/actions/validation/validationInspectionVoucher';
import {
  validateBike,
  validatePersonal,
  validatePayment,
  validateZusammenfassung,
  validateAngebotAnfordern,
  getValidateOverviewInput,
  hasError,
  validateOfferRequestProcess,
  getValidationResult,
  validatePreisanfrage,
} from '@/actions/validation/validation';
import { get } from 'http';
import { AppStateGetterType } from '@/store';
import { GetIsDevMode } from '@/forms/shared/IsDevMode';
import { openOverlay } from '@/processes/overlay/overlayReducer';
export { navigateTo } from './shared/navigateTo';
export type { EnsPage } from './shared/navigateTo';

declare let window: any;

export const NavigateTopMarker = () => <div className='nav_top_select' />;

const mergeBikeErrors = (bikeErrorList) => {
  const bikeKeys = bikeErrorList
    .map((bikeValidationResult) => Object.keys(bikeValidationResult))
    .flat()
    .filter((value, index, self) => self.indexOf(value) === index); //unique

  const errorMaps = bikeKeys.map((bikeId) => ({
    [bikeId]: bikeErrorList.map((o) => o[bikeId] || []).flat(),
  }));

  const mergeResult = {};
  errorMaps.forEach((em) => Object.assign(mergeResult, em));

  return mergeResult;
};

type UserDataSelectorType = (state: RootState) => any;

export const submitGeneral =
  (
    validationFuncs,
    nextRouterTarget,
    callback: null | ((disptach: AppDispatch, errors: any, getState: RootStateGetter) => void) = null,
    userDataSelector: null | UserDataSelectorType | UserDataSelectorType[] = null
  ) =>
  async (dispatch, getState) => {
    const state: RootState = getState();
    const { options } = state.options;

    if (!userDataSelector) userDataSelector = (state) => state.userData;

    if (!Array.isArray(userDataSelector))
      userDataSelector = [...Array(Array.isArray(validationFuncs) ? validationFuncs.length : 1)].map(
        (_) => userDataSelector
      ) as UserDataSelectorType[];

    const errors = (Array.isArray(validationFuncs) ? validationFuncs : [validationFuncs]).map((f, i) =>
      // @ts-ignore
      f(null, userDataSelector[i](state), options, state)
    );

    const validationResults = errors.map(getValidationResult);

    const mergedValidationResult = {
      errors: validationResults.map((o) => o.errors).flat(),
      bikeErrors: mergeBikeErrors(validationResults.map((o) => o.bikeErrors)),
    };

    window.mergedValidationResult = mergedValidationResult;

    await dispatch({
      type: 'CHANGE_VALIDATION_ERRORS',
      ...mergedValidationResult,
    });

    if (hasError(mergedValidationResult)) {
      dispatch(focusErrorField());
      return;
    }

    if (nextRouterTarget) {
      dispatch(navigateTo(nextRouterTarget));
    } else if (callback) {
      callback(dispatch, errors, getState);
    }
    return true;
  };

export const submitBike = () => submitGeneral(validateBike, 'zusammenfassung');

export const submitPersonal = () => async (dispatch, getState) => {
  dispatch(submitGeneral(validatePersonal, 'paymentInfo'));
};

export const submitPayment = () =>
  submitGeneral(validatePayment, null, (dispatch) => {
    dispatch(navigatePaymentinfo.next);
  });

export const submitZusammenfassung = () =>
  submitGeneral([validateZusammenfassung, validateInspectionVoucher], null, async (dispatch) => {
    await dispatch(changeUserInput('agbAkzeptiert', true));
    await dispatch(submit());
  });

export const submitAngebotAnfordern = (type: AngeboAnfordernType = 'default') =>
  submitGeneral(validateAngebotAnfordern, null, async (dispatch) => {
    dispatch(trackByName('angebotAnforderungSubmit'));
    await dispatch(angebotAnfordern(type));

    if (type === 'default') dispatch(navigateTo('angebotfertig'));
    else if (type === 'link') dispatch(openOverlay('submitAngebotLink'));
  });

export const submitPriceRequest = () =>
  submitGeneral(validatePreisanfrage, null, async (dispatch) => {
    await dispatch(priceRequestSubmit());
  });

export const submitOfferRequestProcess = () =>
  submitGeneral([validateOfferRequestProcess], null, async (dispatch) => {
    dispatch(trackByName('offerMailProcessSubmit'));
    await dispatch(offerMailProcess());
    dispatch(navigateTo('angebotfertig'));
  });

export const confirmEnsuranceOverview = (requestOfferOptions: null | Parameters<typeof requestOffers> = null) => {
  if (GetIsDevMode()) console.log('action: confirmEnsuranceOverview');

  return submitGeneral(getValidateOverviewInput(), null, async (dispatch) => {
    await dispatch(changeUserInput('erstinformationenAkzeptiert', true));
    await dispatch(changeUserInput('beratungsgrundlageVvg60Akzeptiert', true));

    if (requestOfferOptions) await dispatch(requestOffers(...requestOfferOptions));
    else await dispatch(requestOffers());
  });
};

export const goBackOnZusammenfassung = () => async (dispatch, getState) => {
  const isGewerblich = getState().devicemode.devkey === 'gewerblich';
  dispatch(navigateTo(isGewerblich ? 'detailedBikeInput' : 'bikeinfo'));
};

export const addSecondBike =
  (settings?: { skipNavigation?: boolean; successCallback?: () => void } | undefined) =>
  (dispatch: AppDispatch, getState: AppStateGetterType) => {
    const firstBikeKey = Object.keys(getState().userData.bikes)[0];

    dispatch(
      submitGeneral(getValidateOverviewInput([firstBikeKey]), null, async (dispatch, _, getState) => {
        if (Object.keys(getState().userData.bikes).length < 2) dispatch({ type: 'ADD_BIKE' });

        if (settings?.successCallback) settings.successCallback();

        if (settings?.skipNavigation) return;
        dispatch(navigateTo('secondBike'));
      })
    );
  };

export const removeSecondBike = () => async (dispatch, getState) => {
  dispatch({
    type: 'REMOVE_BIKE',
    bikeId: Object.keys(getState().userData.bikes).slice(-1)[0],
  });

  dispatch(navigateTo(''));
};

export const restart = () => async (dispatch) => {
  await dispatch({ type: 'RESET_OFFER' });
  await dispatch({ type: 'RESTART_PROCESS' });
  dispatch(navigateTo(''));
};

export { backToEnsList } from './shared/backToEnsList';

export const ensuranceSelected =
  (
    name,
    settings = {
      overrideNavigationPage: null as EnsPage | null,
    }
  ) =>
  async (dispatch, getState: RootStateGetter) => {
    dispatch(trackByName('onEnsuranceSelected'));

    const item = getState().ensuranceList?.list?.find((item) => item.name === name);

    if (item && item?.options?.includes('externCalc')) {
      window.open(item.settings.externLink, '_blank')?.focus();
      return;
    }

    await dispatch({ type: 'ENSURANCE_SELECTED', name });

    if (item?.options?.includes('submitAsPriceRequest')) {
      await dispatch(requestEnsurance());
      dispatch(navigateTo('requestPriceSubmit'));
    } else {
      // Default progress
      await dispatch(requestEnsurance());

      if (!settings.overrideNavigationPage) await dispatch(navigateEnsuranceSelected.next());
      else await dispatch(navigateTo(settings.overrideNavigationPage));
    }

    await dispatch({ type: 'ENSURANCE_SELECTED_COMPLETED' });
  };
