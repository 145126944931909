import { evaluateFormular } from '@/actions/customFilter';

export const updateDisabledFilter = (excludeFilterName, skipValidityCheck = false) => {
  return async (dispatch, getState) => {
    const state: RootState = getState();

    const filters = state.customFilter.activeFilter;
    const userData = state.userData;
    const offer = state.checkout.offer;

    const disabledFilters = filters
      .filter((filter) => evaluateFormular(filter.disableOnFilter, userData, state, offer, false) && !skipValidityCheck)
      .map((filter) => filter.internalName)
      .filter((filterName) => filterName !== excludeFilterName);

    disabledFilters.forEach((df) => dispatch({ type: 'SET_FILTER_VALUE', name: df, value: false }));
  };
};
