import React from 'react';

import { Title, Container } from '@mantine/core';

import { useDynamicFields } from '../../hooks';
import DynamicEntryQuestion, { EntryQuestionContainer } from '../shared/DynamicEntryQuestion';
import { useInsuranceObjectName } from '../shared/InstanceNaming';
import { EntryQuestionObjectPosition } from '@/actions/types/DynamicEntryQuestionType';
import { OpenType } from '@/actions/types/lib/OpenType';

function OverviewBikeInput({
  bikeIndex,
  bikeId,
  fieldsPosition = 'objectEntryQuestions',
}: OpenType<{
  fieldsPosition?: EntryQuestionObjectPosition;
}>) {
  const customQuestions = useDynamicFields(fieldsPosition);

  const insuranceObjectName = useInsuranceObjectName();

  return (
    <>
      {!bikeIndex ? null : (
        <Container px={0}>
          <Title order={2}>
            Dein {insuranceObjectName} #{bikeIndex}
          </Title>
        </Container>
      )}

      <EntryQuestionContainer>
        {customQuestions.map((e, i) => (
          <DynamicEntryQuestion
            bikeId={bikeId}
            setting={e}
            key={i}
          />
        ))}
      </EntryQuestionContainer>
    </>
  );
}

export default OverviewBikeInput;
