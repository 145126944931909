const defaultState = {
  success: null,
  text: null,
  notifications: null,
  voucherInfoText: null,
  orderGroup: null,
  data: null as any,
  stripe_checkout: null as {
    subscriptionId: string;
    clientSecret: string;
    hosted_invoice_url: string;
  } | null,
};

type OrderSubmitType = typeof defaultState;

export default function counter(
  state = defaultState,
  { type, success, text, notifications, voucherInfoText, orderGroup, stripe_checkout, data }
): OrderSubmitType {
  if (type === 'PREMIUMSERVICE_VSN_USER_LOADED' && orderGroup) {
    return { ...state, orderGroup: orderGroup };
  }

  if (type !== 'SUBMIT_COMPLETED') return state;

  return { success, text, notifications, voucherInfoText, orderGroup, stripe_checkout, data };
}
