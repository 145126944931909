import { useEffect } from 'react';
import { useAppDispatch, useAppSelector, useEffectWithStore } from '../hooks';

const Initializer = () => {
  const requiredFieldsLoaded = useAppSelector((state) => state.ensfields.requiredFieldsLoaded);
  const devicemodes = useAppSelector((state) => state.ensfields.devicemodes);
  const dispatch = useAppDispatch();

  useEffectWithStore(
    (state) => {
      if (!requiredFieldsLoaded) return;
      dispatch({ type: 'APP_REQUIREMENTS_LOADED', devicemodes, ensOptions: state.ensOptions });
    },
    [requiredFieldsLoaded]
  );

  return null;
};

export default Initializer;
