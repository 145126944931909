import React, { useCallback } from 'react';
import { setFilterValue } from '../../actions/customFilter/setFilterValue';
import { useAppDispatch } from '../../hooks';
import { GetOfferRequestProcessSetting, SetOfferRequestProcessSetting } from './data/OfferRequestProcessStore';
import { OfferRequestProcessSettingNames } from './shared/OfferRequestProcessSettingNames';
import { updateValidFilter } from '@/actions/customFilter/updateValidFilter';
import { DynamicFilterList } from 'forms/ensuranceList/DynamicFilterList';
import { OptionsGroup } from '@/forms/ensuranceList/EnsuranceListOptions';

export const InitilizeFilterSettings = (dispatch) => {
  //Load Filtervalue from local store
  const filterValues = JSON.parse(GetOfferRequestProcessSetting(OfferRequestProcessSettingNames.filterValues, '{}'));

  Object.keys(filterValues).forEach((filterValueKey) => {
    dispatch(setFilterValue(filterValueKey, filterValues[filterValueKey], true, true));
  });

  dispatch(updateValidFilter(true));
};

export const persistentFilterValues = (filterValues = null) => {
  return function (_, getState) {
    const state: RootState = getState();
    const newFilters = filterValues || state.customFilter.filterValues;
    SetOfferRequestProcessSetting(OfferRequestProcessSettingNames.filterValues, JSON.stringify(newFilters));
  };
};

export const OfferRequestProcessFilterSettings = () => {
  const dispatch = useAppDispatch();
  const onFilterChanged = useCallback(() => {
    dispatch(persistentFilterValues());
  }, []);

  return (
    <OptionsGroup>
      <DynamicFilterList
        showGroupNames={true}
        skipReload={true}
        onFilterChanged={onFilterChanged}
      />
    </OptionsGroup>
  );
};
