import { EnsPriceInfo } from '@/forms/shared/pricing/EnsPriceInfo';
import { useAppSelector } from '@/hooks';
import { Group, Box, Title, Space, Text } from '@mantine/core';
import { Price2 } from '@unserkunde/enscompare-components';
import numeral from 'numeral';
import React, { useMemo } from 'react';
import { useCashbackIsSelected } from './productInfos/useCashbackIsSelected';
import { usePreriodGroupedTotalPrices } from './usePreriodGroupedTotalPrices';

const TotalRowVersand = () => {
  const versandItem = useAppSelector((state) => state.customProducs.serverProductSelection['shipping'] || null);

  const ensPrices = useMemo(
    () => (versandItem?.prices || []).map((prc) => EnsPriceInfo.fromCustomProductPrice(prc)),
    [versandItem]
  );

  if (!versandItem) return null;

  return (
    <>
      {ensPrices.map((priceInfo) => (
        <Price2.Price
          mt='xs'
          size='sm'
          label={versandItem.displayName}>
          {priceInfo.displayFormat()} €
        </Price2.Price>
      ))}
    </>
  );
};

export const TotalRow = () => {
  const periodGroupedTotalPrices = usePreriodGroupedTotalPrices();
  const periodGroupedTotalPricesUnlisted = usePreriodGroupedTotalPrices(true);

  const hasCashback = useCashbackIsSelected();
  const voucherAmount = useAppSelector((state) => state.checkout.offer?.voucherTotalEuroCent);

  const deductableVoucherAmount = hasCashback ? 0 : voucherAmount;

  const firstInvoiceAmount = useMemo(() => {
    return EnsPriceInfo.once(
      periodGroupedTotalPricesUnlisted.reduce((sum, priceInfo) => sum + priceInfo.amount, 0) -
        (deductableVoucherAmount || 0)
    );
  }, [periodGroupedTotalPricesUnlisted, deductableVoucherAmount]);

  return (
    <>
      <Group
        position='apart'
        mt='lg'>
        <Text></Text>
        <Box>
          {hasCashback ? (
            <>
              <Title
                order={6}
                ta='end'
                mb='sm'>
                Du erhältst eine Auszahlung in Höhe von {numeral(voucherAmount).format('0.00')} €
              </Title>
            </>
          ) : (
            <Title
              order={6}
              ta='end'
              mb='sm'>
              Dein Kundenkonto erhält eine Gutschrift in Höhe von {numeral(voucherAmount).format('0.00')} €
            </Title>
          )}
          {periodGroupedTotalPrices.map((priceInfo) => (
            <Price2.Price
              key={priceInfo.period}
              size='md'
              label={<>Deine {priceInfo.getPeriodAdverb()} kosten</>}>
              {priceInfo.displayFormat()} €
            </Price2.Price>
          ))}
          <TotalRowVersand />
          <Space h={'sm'} />

          {!hasCashback && (
            <Text mb='sm'>
              Nach Verrechnung der Gutschrift stellen wir dir erstmalig folgenden Betrag in Rechnung:{' '}
              <Box
                component='span'
                c='primary'
                fw='bold'>
                {firstInvoiceAmount.isNegative() ? '0,00' : firstInvoiceAmount.displayFormat()}&nbsp;€
              </Box>
            </Text>
          )}
        </Box>
      </Group>
    </>
  );
};
