import { createStyles, keyframes } from '@mantine/core';
import React from 'react';

const spinnerKeyframe = keyframes({
  to: {
    transform: 'rotate(360deg)'
  }
});

const useStyle = createStyles((theme: MantineEnsTheme, props: any) => ({
  indicator: {
    height: '50px',
    width: '50px',

    '&:before': {
      content: '""',
      boxSizing: 'border-box',
      display: 'block',
      position: props.relative ? 'relative' : 'absolute',
      top: '50%',
      left: '50%',
      width: '50px',
      height: '50px',
      marginTop: '-25px',
      marginLeft: '-25px',
      borderRadius: '50%',
      border: '5px solid #ccc',
      borderTopColor: theme.colors[theme.primaryColor][theme.primaryShade || 0],
      animation: `${spinnerKeyframe} 1.0s linear infinite`
    }
  }
}));

function EnsLoadingIndicator(props) {
  const { classes } = useStyle(props);

  return <div className={classes.indicator} />;
}

export default EnsLoadingIndicator;
