
      import '/src/index.ts';
      window.ensInitialize(
        'ensurance_view_root',
        {
          ensVoucherCode: 'test',
        },
        {
          /* presetId: '6729099c8609d9.51042433' */
        }
      );
      //window.ensInitialize('ensurance_view_root2', {}, { presetId: '6729099c8609d9.51042433' });
    