import React from 'react';
import { LoadingOverlay, Space } from '@mantine/core';
import EnsLoadingIndicator from './EnsLoadingIndicator';
import { selectorIsLoading, useAppSelector } from '@/hooks';

const EnsLoadingOverlay = () => {
  const loading = useAppSelector((state) => selectorIsLoading(state));
  const ensuranceComapreIsLoading = useAppSelector((state) => state.ensCompare.loading);
  const submitting = useAppSelector((state) => state.ensuranceList.submitting);

  if (!loading) return null;

  return (
    <div style={{ position: 'absolute', inset: '0', zIndex: 10005 }}>
      <LoadingOverlay
        loader={' '}
        visible={true}
        overlayBlur={3}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          //height: '100%',
          position: 'sticky',
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 10006,
        }}>
        <EnsLoadingIndicator relative />
        <Space h={'md'} />
        {ensuranceComapreIsLoading && (
          <>
            <span>Lade Vergleichtabelle</span>
          </>
        )}
        {submitting ? (
          <span style={{ textAlign: 'center', marginBottom: '2em' }}>
            Anfrage wird verarbeitet <br />
            Dies kann einen Moment dauern.
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default EnsLoadingOverlay;
