import React, { useCallback, useMemo } from 'react';
import numeral from 'numeral';

import InsuranceCard from '@unserkunde/enscompare-components/src/components/cards/InsuranceCard';
import { useIsXs } from '@/forms/shared/EnsContainerObserver';
import Price2 from '@unserkunde/enscompare-components/src/components/feedback/Price2';
import { Text, keyframes, Tooltip, createStyles, Box, MantineColor, Stack } from '@mantine/core';

import { ensuranceSelected } from '../../actions/progress';

import { ToggleCompareEnsSelect } from '../../actions/compare';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { useObjectOwnerName } from '../shared/InstanceNaming';
import HeadpointTextDiplay from '../shared/headpoints/HeadpointTextDiplay';

import { EnsIcon } from '../shared/AvailalbeEnsIcons';
import OfferPriceHints from './OfferPriceHints';
import { EnsuranceBaseInfo, EnsuranceOffer } from '@/actions/types/EnsuranceOffer';
import { useCanToggleEnsuranceForCompare } from '@/reducer/ensCompare';
import { frequentDisplayName } from '@/forms/shared/Common';
import { GetIsDevMode } from '../shared/IsDevMode';
import { EnsPriceInfo } from '../shared/pricing/EnsPriceInfo';

const keyframes_fadein = keyframes({
  '0%': { opacity: '0' },
  '100%': { opacity: '1' },
});

const useStyles = createStyles((theme, props: { isXs: boolean; bg?: MantineColor }) => ({
  _nowrap: {
    whiteSpace: 'nowrap',
  },
  infoIcon: { fontSize: `${14 * 0.7}px` },

  cardContent: {
    marginTop: props.isXs ? '1.5em' : undefined,
    flexGrow: 1,
    height: 'auto !important',
  },
  offerCard: {
    background: (theme.colors[props?.bg] || { 0: theme.colors.accentSubtle })[0],
    border: `1px solid ${theme.colors.gray[2]}`,
    display: 'flex',
  },

  ensItemIconDisabled: {
    filter: 'grayscale(1)',
    userSelect: 'none',
    userDrag: 'none',
    pointerEvents: 'none',
  },
  ensItemTestSealDisabled: {
    filter: 'grayscale(1) brightness(160%) blur(3px)',
    userSelect: 'none',
    userDrag: 'none',
    pointerEvents: 'none',
  },
  ensItemIcon: {
    maxHeight: '5em',
    maxWidth: 'min(100%,15em)',
  },
  fadeInText: {
    animation: `${keyframes_fadein} 0.5s ease`,
  },
  labelText: {
    textWrap: 'pretty',
  },
  highlightText: {
    fontWeight: 'bold',
    color: 'black',
  },
}));

const useOfferChecklist = (offer: EnsuranceOffer) => {
  const presetHeadpoints =
    useAppSelector((state) => (offer.name ? state.ensuranceList.presetHeadpoints[offer.name] : null)) || [];

  return useMemo(() => {
    return {
      preview: 6,
      items: [...presetHeadpoints, ...(offer.headpoints || [])].map((item) => {
        const typeMarker = Array.isArray(item[1]) ? item[1][0] : item[1];

        const type = !Array.isArray(item)
          ? null
          : typeMarker === true
            ? 'positive'
            : typeMarker === false
              ? 'negative'
              : typeMarker === '->'
                ? 'arrow'
                : 'warning';

        const text = Array.isArray(item) ? item[0] : item;

        return {
          children: (
            <HeadpointTextDiplay
              text={text}
              markers={['ohne', 'inkl']}
            />
          ),
          tmp: typeMarker,
          type,
          uuid: text,
        };
      }),
    };
  }, [offer.headpoints?.length]);
};

export const usePriceProps = (offer: EnsuranceOffer, noFirstYearPrice = false, showVoucherInfo = false) => {
  const isXs = useIsXs();
  const { classes } = useStyles({ isXs });

  const showFirstYearPrices = useAppSelector((state) => !state.customProducs.custom_product_page) && !noFirstYearPrice;

  return useMemo(() => {
    const offerPaymentPeriod = frequentDisplayName(offer.period);

    const formatPrices = (prices: number[]) => {
      const priceList = prices?.length > 2 ? [prices.reduce((a, b) => a + b, 0)] : prices;
      return !priceList
        ? ''
        : priceList.map((price, index) => (
            <React.Fragment key={index}>
              {numeral(price).format('0.00')} €
              {index === priceList.length - 1 ? null : (
                <>
                  {' '}
                  + <wbr />
                </>
              )}
            </React.Fragment>
          ));
    };

    if (!offer.prices?.brutto) return null;

    const TooltipWrap = ({ children, className = undefined }: OpenType<{ className?: undefined | string }>) => (
      <Tooltip label={<OfferPriceHints offer={offer} />}>
        <span className={className}>{children}</span>
      </Tooltip>
    );

    const priceSize = isXs ? 'md' : undefined;

    const hasFirstYearPrice = offer.prices_firstYear?.brutto?.length > 0 && offer.prices_firstYear?.brutto[0];

    if (showFirstYearPrices && hasFirstYearPrice) {
      return {
        preText: (
          <TooltipWrap>
            {offerPaymentPeriod}&nbsp;
            <EnsIcon
              icon='FaInfoCircle'
              useAppColor={true}
              className={classes.infoIcon}
            />
          </TooltipWrap>
        ),
        children: (
          <>
            <Price2.Price
              size={priceSize}
              label={<>1.&nbsp;Jahr</>}>
              {formatPrices(offer.prices_firstYear?.brutto)}
            </Price2.Price>
            <Price2.Price
              size={priceSize}
              label='danach'>
              {formatPrices(offer.prices?.brutto)}
            </Price2.Price>
          </>
        ),
      };
    }

    if ((offer.options || []).includes('offerRange')) {
      return {
        children: (
          <Price2.Price
            size={priceSize}
            label={
              <TooltipWrap className={classes._nowrap}>
                <EnsIcon
                  icon='FaInfoCircle'
                  useAppColor={true}
                  className={classes.infoIcon}
                />
                &nbsp;{offerPaymentPeriod}
              </TooltipWrap>
            }>
            {formatPrices([offer?.offerMin])} - {formatPrices([offer?.offerMax])}
          </Price2.Price>
        ),
      };
    }

    return {
      children: (
        <>
          <Price2.Price
            size={priceSize}
            label={
              <TooltipWrap className={classes._nowrap}>
                <EnsIcon
                  icon='FaInfoCircle'
                  useAppColor={true}
                  className={classes.infoIcon}
                />
                &nbsp;{offerPaymentPeriod}
              </TooltipWrap>
            }>
            {formatPrices(offer.prices?.brutto)}
          </Price2.Price>
          {showVoucherInfo && offer?.voucherTotalEuroCent && (
            <Tooltip
              multiline
              label={<Box>Im Antrag kannst du wählen, wie dein Willkommensgeschenk gutgeschrieben werden soll</Box>}>
              <Text
                ta='end'
                mt={2}
                color='primary'>
                <EnsIcon
                  icon='FaGift'
                  useAppColor
                />
                &nbsp;Wir schenken dir {EnsPriceInfo.once(offer.voucherTotalEuroCent).displayFormat()}&nbsp;€
              </Text>
            </Tooltip>
          )}
        </>
      ),
    };
  }, [offer, isXs, showFirstYearPrices, classes._nowrap, classes.infoIcon, showVoucherInfo]);
};

const useIsSelectedForCompare = (name: string) => {
  return useAppSelector((state) => !!state.ensCompare.selectedNames.includes(name));
};

export const useEyebrowProps = (
  item: EnsuranceOffer | EnsuranceBaseInfo,
  onClick,
  style = undefined,
  useShortVersionOnSmallScreen = false,
  eyeBrowInside = false
) => {
  const isXs = useIsXs();

  return useMemo(() => {
    if (!item?.badgeDetails?.percent) return null;

    const points = Math.round(item?.badgeDetails?.percent || 0) / 10;
    const size = isXs ? 'sm' : 'md';

    const isShortVersion = useShortVersionOnSmallScreen && isXs;

    const pointsDisplay = <>{points} / 10</>;

    if (!item) return {};

    return {
      onClick: onClick,
      children: isShortVersion ? (
        pointsDisplay
      ) : (
        <>
          {item?.badgeDetails?.goldText}
          {!item?.badgeDetails?.goldText ? null : isXs ? ' • ' : <br />}
          {pointsDisplay}
          {!item?.badgeDetails.subtext ? null : ' • '}
          {item?.badgeDetails.subtext}
        </>
      ),
      size: size,
      lh: 1.2,
      style: {
        transform: `translate(-50%, -${eyeBrowInside ? '15' : '50'}%)`,
        borderRadius: '0.4em',
        width: !isXs ? 'auto' : '98%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        textTransform: 'none',
        zIndex: 10,
        ...style,
      },
    };
  }, [
    item?.badgeDetails?.goldText,
    item?.badgeDetails?.percent,
    isXs,
    onClick,
    useShortVersionOnSmallScreen,
    style,
    eyeBrowInside,
  ]);
};

export const getHideItemPriceInfo = (item: EnsuranceOffer) => {
  const submitPriceRequest = item?.options?.includes('submitAsPriceRequest');

  return (
    submitPriceRequest ||
    (item?.options?.includes('externCalc') &&
      !item?.options?.includes('offerRange') &&
      (item?.prices?.brutto || []).reduce((acc, curr) => acc + curr, 0) === 0)
  );
};

function EnsuranceItemControl({
  item,
  disabled = false,
  useSmall = false,
  hideDocs = false,
  highlightOrderCountPercent = false,
  bg = 'accentSubtle',
  cardClassName = undefined,
  eyeBrowInside = false,
}: {
  item: EnsuranceOffer;
  disabled?: boolean;
  useSmall?: boolean;
  hideDocs?: boolean;
  highlightOrderCountPercent?: boolean;
  bg?: MantineColor;
  cardClassName?: string;
  eyeBrowInside?: boolean;
}) {
  const isXs = useIsXs() || useSmall;
  const { classes, cx } = useStyles({ isXs, bg });
  const dispatch = useAppDispatch();
  const checklist = useOfferChecklist(item);
  const documents = useMemo(() => {
    return Object.entries(item.documents ?? {}).map(([key, value]) => {
      return {
        children: key?.length > 15 ? key.slice(0, 15).trim() + '...' : key,
        title: key,
        href: value,
        uuid: key,
      };
    });
  }, [item.documents]);
  const objectOwnerName = useObjectOwnerName();
  const priceProps = usePriceProps(item, false, true);

  const canSelectForCompare = useCanToggleEnsuranceForCompare(item);
  const isSelectedForCompare = useIsSelectedForCompare(item.name || '');
  const toggleSelectForCompare = useCallback(() => {
    if (!canSelectForCompare) return;
    dispatch(ToggleCompareEnsSelect(item.name));
  }, [dispatch, item.name, canSelectForCompare]);
  const onEnsuranceSelected = useCallback(() => dispatch(ensuranceSelected(item.name)), [dispatch, item.name]);

  const iconProps = useMemo(() => {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
      <img
        alt={'Logo ' + item.displayName}
        src={item.logo_maybe_vector || item.logo}
        className={`${classes.ensItemIcon} ${!disabled ? '' : classes.ensItemIconDisabled}`}
        draggable={!disabled}
        onClick={toggleSelectForCompare}
      />
    );
  }, [item.name, item?.logo, toggleSelectForCompare, disabled]);

  const checkboxProps = useMemo(
    () => ({
      label: <Text td='underline'>Vergleichen</Text>,
      onChange: toggleSelectForCompare,
      checked: isSelectedForCompare,
    }),
    [isSelectedForCompare, toggleSelectForCompare]
  );

  const disclaimer = useMemo(() => {
    if (item.reason && disabled) return item.reason;
    if (!item.orderCountPercent) return '';

    const externDisclaimer = item?.options?.includes('externCalc');

    return (
      <>
        <Text
          ta='end'
          className={cx(
            classes.fadeInText,
            classes.labelText,
            highlightOrderCountPercent ? classes.highlightText : undefined
          )}>
          Das Angebot haben {numeral(item.orderCountPercent / 100).format('0.0%')}
          der {objectOwnerName} gewählt
        </Text>
        {externDisclaimer && (
          <Text
            ta='end'
            mt={'xs'}
            mb={'-xs'}
            fz={'0.8em'}
            className={cx(
              classes.fadeInText,
              classes.labelText,
              highlightOrderCountPercent ? classes.highlightText : undefined
            )}>
            Zum berechnen auf &quot;beantragen&quot; klicken
          </Text>
        )}
      </>
    );
  }, [
    item.reason,
    item?.options,
    item.orderCountPercent,
    item.voucherTotalEuroCent,
    disabled,
    cx,
    classes.fadeInText,
    classes.highlightText,
    highlightOrderCountPercent,
    objectOwnerName,
  ]);

  const eyebrowSettings = useEyebrowProps(item, toggleSelectForCompare, undefined, undefined, eyeBrowInside);

  const hidePriceInfo = getHideItemPriceInfo(item);

  const displayName = useMemo(() => {
    return !GetIsDevMode() ? (
      <>{item?.displayName}</>
    ) : (
      <Stack spacing={0}>
        {item?.displayName}
        <Text fw='lighter'>{item?.name}</Text>
      </Stack>
    );
  }, [item]);

  return (
    <InsuranceCard
      className={cx(classes.offerCard, cardClassName)}
      contentClassName={classes.cardContent}
      disabled={disabled}
      checklist={checklist}
      tariffTitle={displayName}
      btnCallback={onEnsuranceSelected}
      btnLabel={item?.options?.includes('submitAsPriceRequest') ? 'Angebotsanfrage' : 'Jetzt beantragen'}
      icon={iconProps}
      documents={hideDocs ? null : documents}
      checkbox={checkboxProps}
      testSeal={null}
      disclaimer={hidePriceInfo ? null : disclaimer}
      priceComponent={hidePriceInfo ? Box : Price2}
      price={hidePriceInfo ? null : priceProps}
      eyebrow={eyebrowSettings}
      useSmall={useSmall}
    />
  );
}

export default React.memo(EnsuranceItemControl);
