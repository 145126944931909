import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button as MantineButton, Divider, Grid, Group, Text, Title, Button } from '@mantine/core';

import { DynamicBox, PureHtml } from '../shared/style/components';

import { useAppDispatch, useAppSelector, useUserData } from '@/hooks';
import { submitInspectionVoucher, submitInspectionVoucherLegacy } from '@/actions/data/inspectionVoucherActions';
import { GpsSelectionCard } from '@/forms/success/GpsSelectionCard';
import { usePremiumserviceAgb, usePremiumserviceChecklist } from '../InspectionVoucher/InspectionVoucherOrderComponent';
import { PremiumServiceCard } from '@unserkunde/enscompare-components';
import { useIsSize } from '@/hooks/theme';
import { useCustomProduct } from '@/reducer/customProducts';
import { CustomProductVariantSelection } from '@/reducer/customProducts/customProducts.types';
import { CustomProductsPriceDisplay } from '../shared/CustomProductsPriceDisplay';
import { StripeTable } from '../shared/StripeTable';
import stripe, { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
  Elements,
  PaymentElement,
  useStripe,
} from '@stripe/react-stripe-js';
import { set } from 'date-fns';

const SubleButtonProps = { variant: 'light', color: 'gray' };

export const StripeCheckout = () => {
  const checkout = useAppSelector((state) => state.orderSubmit.stripe_checkout);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded || !checkout) return;
    window.open(checkout.hosted_invoice_url, '_blank');

    setLoaded(true);
  }, [loaded, checkout]);

  return null;
};

export const SuccessPagePremiumserviceComponent = ({ isIncluded = false, requireOrderGroup = true }) => {
  const useCta = useAppSelector((state) => state.ensfields?.inspectionvoucher_show_cta === '1');

  const hasOrderGroup = useAppSelector((state) => state.orderSubmit.orderGroup);

  const [hasPVOrdered] = useUserData('orderPremiumservice', undefined);

  const checklist = usePremiumserviceChecklist(false, 1000);

  const [value] = useUserData<boolean>('bookPremiumservice');

  const [premiumservice, setPremiumserviceVariant] = useCustomProduct('premiumservice');

  if (value) setPremiumserviceVariant('small');

  if (hasPVOrdered || !useCta || (!hasOrderGroup && requireOrderGroup)) return null;

  return (
    <>
      <PremiumServiceCard
        disableUncheck={false}
        value={!!premiumservice?.variant}
        title={'Premiumservice'}
        btnCallback={(_, val) => setPremiumserviceVariant('small')}
        btnLabel={isIncluded ? 'Enthalten im GPS-Tracker' : 'Hinzufügen für 15,-€ jährlich'}
        btnLabelDone={'Premiumservice für 15,-€ jährlich'}
        checklist={checklist}
        agbAboveButton={true}
        agbText={''}
        fullHeight={true}
        buttonProps={isIncluded ? SubleButtonProps : undefined}
      />
    </>
  );
};
export const SuccessControls = ({ children }: React.PropsWithChildren) => {
  const [gpsProduct, setGpsProducsVariant] = useCustomProduct('gpsTracker');
  const [premiumservice, setPremiumserviceVariant] = useCustomProduct('premiumservice');

  const [gpsHeadpoints, setGpsHeadpoints] =
    useUserData<CustomProductVariantSelection<'gpsTracker'>>('gpsOrderHeadpoints');

  const bookPremiumservice = !!premiumservice?.variant;

  useEffect(() => {
    if (!bookPremiumservice) return;
    setGpsProducsVariant('none');
  }, [bookPremiumservice]);

  useEffect(() => {
    if (!gpsProduct?.variant) return;
    setPremiumserviceVariant('none');
  }, [gpsProduct, setPremiumserviceVariant]);

  const [submitted, setSubmitted] = useState(false);

  const dispatch = useAppDispatch();

  const agbText = usePremiumserviceAgb(false, 'Zusätzlich kostenpflichtig bestellen');

  const order = useCallback(async () => {
    if (gpsProduct?.variant) {
      dispatch(
        submitInspectionVoucher({
          overrideBookPremiumserivce: true,
          smallPremiumservice: true,
          gpsProductInfo: gpsProduct?.variant === 'rented' ? gpsProduct : undefined,
        })
      );
    } else if (bookPremiumservice) {
      await dispatch(submitInspectionVoucherLegacy(true, true));
    }

    setSubmitted(true);
  }, [gpsProduct, bookPremiumservice, bookPremiumservice, dispatch]);

  const canOrder = useMemo(() => gpsProduct?.variant || bookPremiumservice, [gpsProduct, bookPremiumservice]);

  const premiumserviceResult = useAppSelector((state) => state.inspectionVoucher.submitResult);

  const showPremiumService = false;
  const showGpsTracker = false;
  /**
   * @link https://app.asana.com/0/1174272655772669/1206693087936090/f
   */
  const showStripe = false;

  const isSmall = !useIsSize('sm');

  if (submitted) {
    return (
      <>
        {premiumserviceResult && (
          <DynamicBox type={premiumserviceResult.success ? 'success' : 'warning'}>
            <PureHtml
              preLine
              content={'Der Premiumservice wurde bestellt, Sie erhalten bald weitere Informationen'}
            />
          </DynamicBox>
        )}
      </>
    );
  }

  return (
    <>
      {(showPremiumService || showGpsTracker || showStripe) && (
        <Title
          my='sm'
          order={3}>
          FahrSicherung empfiehlt
        </Title>
      )}
      {showStripe && <StripeTable tableId={'prctbl_1Or2TzK6ezAVR5RHE2304dxr'} />}
      {showPremiumService && (
        <>
          <Grid>
            <Grid.Col
              span={12}
              md={showGpsTracker ? 6 : 12}>
              <SuccessPagePremiumserviceComponent isIncluded={!!gpsProduct?.variant} />
            </Grid.Col>
            {showGpsTracker && (
              <Grid.Col
                span={12}
                md={6}>
                <GpsSelectionCard
                  onDisplayHeadpoints={setGpsHeadpoints}
                  value={gpsProduct?.variant}
                  onChange={setGpsProducsVariant}
                />
              </Grid.Col>
            )}
          </Grid>
          <Text
            mt='sm'
            ta='right'>
            {agbText}
          </Text>
          <CustomProductsPriceDisplay
            mt={'sm'}
            productName={bookPremiumservice ? 'premiumservice' : 'gpsTracker'}
            variantOverride={bookPremiumservice ? 'small' : undefined}
          />
          <Grid
            columns={isSmall ? 1 : 2}
            mt='sm'
            mb='sm'>
            <Grid.Col
              span={1}
              order={isSmall ? 3 : 1}>
              {isSmall && <Divider mb='sm' />}
              <Group position={isSmall ? 'right' : 'left'}>{children}</Group>
            </Grid.Col>
            <Grid.Col
              span={1}
              order={2}>
              <Group position='right'>
                <MantineButton
                  disabled={!canOrder}
                  onClick={order}
                  compact>
                  Zusätzlich kostenpflichtig bestellen
                </MantineButton>
              </Group>
            </Grid.Col>
          </Grid>
        </>
      )}
    </>
  );
};
