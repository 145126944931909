import { getQueryStrings } from '@/actions/shared/QueryParams';
import { IsInIframeContext } from '@/lib/IsInIframeContext';
import { useEffect, useState } from 'react';

import { initializeChildListener } from '@unserkunde/open-iframe-resizer';
import { useEffectWithStore } from '@/hooks';

export const OpenIframeResize = () => {
  const [loaded, setLoaded] = useState(false);

  useEffectWithStore(
    (store) => {
      if (loaded) return;
      if (!store.ensOptions?.useIFrameResize && !getQueryStrings()?.ensUseIFrameResize) return;

      if (!IsInIframeContext()) {
        console.warn('useIFrameResize should only be used in an iframe');
        return;
      }

      setLoaded(true);
      initializeChildListener({});
    },
    [loaded]
  );

  return null;
};
