import { EnsuranceOffer } from '@/actions/types/EnsuranceOffer';

const defaultOffer: EnsuranceOffer = {
  offer: null,
  period: null,
  vst: null,
  failed: false,
  name: null,
  displayName: null,
  badgeUrl: null,
  logo: null,
  logo_maybe_vector: null,
  filterkey: null,
  headpoints: [],
  documents: [],
  options: [],
  orderCountPercent: null,
  prices: null
};

const defaultValue = {
  offer: defaultOffer,
  loading: false,
  name: '',
};

export type CheckoutReducerType = typeof defaultValue;
export type OfferType = typeof defaultOffer;

export default function counter(state: CheckoutReducerType = defaultValue, action): typeof defaultValue {
  if (action.type === 'ENSURANCE_LOADING') {
    return { ...defaultValue, ...state, loading: true };
  } else if (action.type === 'ENSURANCE_LOADED') {
    return { ...defaultValue, ...state, offer: action.offer, loading: false };
  } else if (action.type === 'ENSURANCE_SELECTED') {
    return { ...defaultValue, ...state, name: action.name };
  } else if (action.type === 'RESET_TO_ENSLIST') {
    return { ...defaultValue, ...state, offer: defaultOffer };
  } else if (action.type === 'RESET_OFFER') {
    return defaultValue;
  }

  return state;
}
