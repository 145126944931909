import React, { ReactNode, useMemo } from 'react';

import DescriptionList from '@unserkunde/enscompare-components/src/components/feedback/DescriptionList';
import useZusammenfassungByInstance from './InstanceSpecific/ZusammenfassungByInstance';
import { useAppSelector } from '@/hooks';

const ZusammenfassungCoreInfos = () => {
  const { useCoreInfos } = useZusammenfassungByInstance();

  const instanceCoreInfos = useCoreInfos();

  const tarifName = useAppSelector((state) => state.checkout?.offer?.displayName);

  const joinedCoreInfos: [string, ReactNode][] = useMemo(
    () => [['Tarif', tarifName], ...instanceCoreInfos],
    [instanceCoreInfos]
  );

  const sections = useMemo(() => {
    return [
      {
        uuid: '1',
        title: '',
        entries: joinedCoreInfos.map((item, index) => ({
          uuid: (10 + index * 2).toString(),
          children: item[1],
          label: item[0]
        }))
      }
    ];
  }, [joinedCoreInfos]);

  return <DescriptionList sections={sections} />;
};

export default ZusammenfassungCoreInfos;
