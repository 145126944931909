import React from 'react';

import { Container } from '@mantine/core';
import { useAppSelector } from '../../hooks';
import IsDevMode from '../shared/IsDevMode';
import { CompareTableGrouped } from './CompareTableGrouped';

export const CompareTableInfo = () => {
  const usedCompTableName = useAppSelector((state) => state.ensCompare.displayName);
  const usedCompTableId = useAppSelector((state) => state.ensCompare.tableId);

  return (
    <span>
      <br />
      Benutzte Tabelle: {usedCompTableId} - ({usedCompTableName})<br />
    </span>
  );
};

export const CompareTablePage = () => {
  return (
    <Container className="compareTableTop">
      <CompareTableGrouped />

      <IsDevMode>
        <CompareTableInfo />
      </IsDevMode>
    </Container>
  );
};

export default CompareTablePage;
