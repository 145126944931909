import { EnsuranceOffer } from '../types/EnsuranceOffer';

export type LoadingStreamLoadingInfo = {
  totalItems: string;
  currentItem: string | undefined;
  type: 'LoadingInfo' | 'InsuranceOffer';
  offersLoaded: EnsuranceOffer[] | null;
};

export const MultipartStreamToOfferLoadingStream = async (
  stream: ReadableStreamDefaultReader<string>,
  headers: Headers,
  callback: (event: LoadingStreamLoadingInfo) => void
) => {
  return new Promise<any>((resolve, reject) => {
    MultipartStreamToEventStream(stream, headers, (event) => {
      const eventName = event.headers.find(
        (o) => o.key.toLocaleLowerCase() === 'Ens-Resource'.toLocaleLowerCase()
      )?.value;

      const headersArray = [...event.headers.values()];

      try {
        const eventData = !event.content ? null : JSON.parse(event.content);

        if (eventName === 'CompleteRequest') {
          resolve(eventData);
        } else if (eventName === 'LoadingInfo' || eventName === 'InsuranceOffer') {
          let offers: EnsuranceOffer[] | null = null;
          if (eventName === 'InsuranceOffer') {
            offers = JSON.parse(event.content);
          }

          const item: LoadingStreamLoadingInfo = {
            type: eventName,
            totalItems: headersArray.find((h) => h?.key?.toLocaleLowerCase() === 'ens-total')?.value || '0',
            currentItem: headersArray.find((h) => h?.key?.toLocaleLowerCase() === 'ens-counter')?.value || undefined,
            offersLoaded: offers
          };
          callback(item);
        }
      } catch (e) {
        console.log(e, event.content);
        reject(e);
      }
    });
  });
};

export type MultipartStreamEvent = {
  headers: { key: string; value: string }[];
  content: string;
};

export const MultipartStreamToEventStream = async (
  stream: ReadableStreamDefaultReader<string>,
  headers: Headers,
  callback: (event: MultipartStreamEvent) => void
): Promise<void> => {
  let isDone = false;

  const boundary = [...headers.entries()]
    .find(([key, value]) => key === 'content-type' && value.includes('boundary='))?.[1]
    .split('=')[1];

  let buffer = '';

  const parseBuffer = () => {
    if (buffer.startsWith('\n')) {
      buffer = buffer.substring(1);
    }

    if (!buffer.startsWith(boundary) && buffer.length > 0) {
      console.log('buffer', JSON.stringify(boundary), JSON.stringify(buffer));
      throw new Error('Invalid boundary');
    }

    const headersEnd = buffer.indexOf('\n\n');
    if (headersEnd === -1) return;

    const headers = buffer
      .substring(buffer.indexOf('\n'), headersEnd)
      .split('\n')
      .filter((o) => o)
      .map((o) => o.trim().split(': '));

    const contentStart = headersEnd + 2; //Because of \n\n
    let contentEnd = -1;

    const lengthHeader = headers.find(([key]) => key.toLocaleLowerCase() === 'content-length');
    if (lengthHeader && parseInt(lengthHeader[1]) + contentStart <= buffer.length) {
      contentEnd = contentStart + parseInt(lengthHeader[1]);
    } else if (buffer.includes(boundary, buffer.indexOf('\n'))) {
      contentEnd = buffer.indexOf(boundary, buffer.indexOf('\n'));
    } else if (isDone) {
      contentEnd = buffer.length;
    }

    if (contentEnd === -1) {
      return;
    }

    const content = buffer.substring(contentStart, contentEnd);

    callback({
      headers: headers.map(([key, value]) => ({
        key,
        value
      })),
      content
    });

    buffer = buffer.substring(contentEnd);

    if (buffer.includes(boundary)) {
      parseBuffer();
    }
  };

  while (!isDone) {
    const { done, value } = await stream.read();

    if (value) buffer += value;
    isDone = done;
    parseBuffer();
  }
};
