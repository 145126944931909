import React, { useMemo } from 'react';
import { Anchor, Card, Container, Flex, Tooltip, createStyles } from '@mantine/core';
import { getPageMode, getUrl } from '../../actions/data';
import { MitarbeiterkennungControl } from './MitarbeiterkennungControl';
import ThemedSpan from './ThemedSpan';
import { useEnsfieldsValue } from '@/features/Ensfields';

const useStyle = createStyles(() => ({
  link: {
    color: 'gray',
  },
}));

const ImpressumControl = () => {
  const hideApiUrl = () => {
    return (
      getUrl().includes('ebikeversicherungen.net') ||
      getUrl().includes('fahrsicherung.at') ||
      getUrl().includes('dogvers.de') ||
      getUrl().includes('unserkunde.de')
    );
  };

  const impressumUrl = useEnsfieldsValue(
    'ens_impressum_url',
    'https://ebikeversicherungen.net/e-bike-versicherung/impressum/'
  );
  const datenschutzUrl = useEnsfieldsValue(
    'ens_datenschutz_url',
    'https://ebikeversicherungen.net/e-bike-versicherung/privacy-policy/'
  );
  const byweredby = useEnsfieldsValue('ens_byweredby', 'ebikeversicherungen.net');
  const byweredbyUrl = useEnsfieldsValue('ens_byweredby_url', 'https://ebikeversicherungen.net');

  const hostUrl = useMemo(() => getUrl(), []);

  const { classes } = useStyle();

  return (
    <Container
      px='0'
      fluid>
      <Flex
        direction={'column'}
        gap={'xs'}>
        {getPageMode() === 'intern' ? null : (
          <Card
            bg={'white'}
            radius='md'
            shadow='sm'
            p='xs'
            className='ens_impressum_control'>
            <ThemedSpan>
              <Anchor
                target='_blank'
                className={classes.link}
                rel='noopener noreferrer'
                href={impressumUrl}>
                Impressum
              </Anchor>{' '}
              •{' '}
              <Anchor
                target='_blank'
                className={classes.link}
                rel='noopener noreferrer'
                href={datenschutzUrl}>
                Datenschutz
              </Anchor>{' '}
              •{' '}
              <span className='ens_powered_by'>
                <Tooltip
                  label={'Running at: ' + hostUrl}
                  openDelay={2000}>
                  <span>Powered by</span>
                </Tooltip>{' '}
                <Anchor
                  target='_blank'
                  href={byweredbyUrl}
                  className={classes.link}
                  rel='noopener noreferrer'>
                  {byweredby}
                </Anchor>
              </span>
              {hideApiUrl() ? null : (
                <>
                  &nbsp;• Running API at{' '}
                  <Anchor
                    target='_blank'
                    href={getUrl('/api/ens/v1/ping')}
                    className={classes.link}
                    rel='noreferrer'>
                    {getUrl()}
                  </Anchor>
                </>
              )}
            </ThemedSpan>
          </Card>
        )}
        <MitarbeiterkennungControl style={{ alignSelf: 'flex-end' }} />
      </Flex>
    </Container>
  );
};
export default ImpressumControl;
