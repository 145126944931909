import requestEnsurance from '@/actions/data/requestEnsurance';
import { usePremiumserviceChecklist } from '@/forms/InspectionVoucher/InspectionVoucherOrderComponent';
import { GetIsDevMode } from '@/forms/shared/IsDevMode';
import { EnsPriceInfo } from '@/forms/shared/pricing/EnsPriceInfo';
import { useAppSelector, useAppDispatch } from '@/hooks';
import { useCustomProduct } from '@/reducer/customProducts';
import { CustomProductNames, CustomProduct, CustomProductPrice } from '@/reducer/customProducts/customProducts.types';
import { Card, Group, Box, Title, Spoiler, Stack, Select, LoadingOverlay, Text } from '@mantine/core';
import { Checklist, Price2 } from '@unserkunde/enscompare-components';
import numeral from 'numeral';
import React, { useCallback, useMemo } from 'react';
import { CustomProductImageDisplay } from './CustomProductImageDisplay';
import { GpsTrackerHeadpoints } from '@/forms/success/GpsSelectionCard';
import { useSortedProductKeys } from './useSortedProductKeys';
import { useIsXs } from '@/forms/shared/EnsContainerObserver';

const CustomProductRow = (props: { productKey: CustomProductNames }) => {
  const customProduct = useAppSelector((state) => state.customProducs.serverProductSelection[props.productKey]);

  const productBaseInfo = useAppSelector(
    (state) => state.customProducs.custom_products[props.productKey]
  ) as CustomProduct<typeof props.productKey>;

  const [customProductInfo, setVariant, setAmount] = useCustomProduct(props.productKey);

  const dispatch = useAppDispatch();

  const setAmountCallback = useCallback(
    async (newValue: string) => {
      if (!customProduct) return;
      if (!customProduct.itemsToAdd) {
        const newValueNum = parseInt(newValue);
        if (newValueNum) await setAmount(newValueNum);
        else {
          await setAmount(0);
          await setVariant('none');
        }

        await dispatch(requestEnsurance());
      } else throw new Error('Not implemented incl custom products');
    },
    [customProduct, setAmount, setVariant]
  );

  const priceInfos = useMemo(
    () =>
      customProduct?.prices
        ?.filter((p: CustomProductPrice) => !p?.disablePriceDisplay)
        .map((p) => EnsPriceInfo.fromCustomProductPrice(p)),
    [customProduct]
  );

  const maxAmount = productBaseInfo?.maxAmount;
  const amounts = useMemo(() => {
    return Array.from({ length: (maxAmount || 5) + 1 }, (_, i) => i + 1).map((i) => (i - 1).toString());
  }, [maxAmount]);

  const psChecklist = usePremiumserviceChecklist(false);

  const isXs = useIsXs();

  const DetailsElement = useMemo(() => {
    if (props.productKey === 'premiumservice' || props.productKey === 'gpsTracker') {
      return (
        <Checklist
          isOpen={false}
          /* @ts-ignore */
          items={props.productKey === 'premiumservice' ? psChecklist.items : GpsTrackerHeadpoints.items}
          preview={1000}
          labelClosed={undefined}
          labelOpen={undefined}
        />
      );
    } else if (props.productKey === 'sta_product1') {
      return (
        <Checklist
          isOpen={false}
          preview={1000}
          items={[
            {
              uuid: 1,
              type: 'positive',
              children: <b>Erstinspektionsgutschein im Wert von 70,-€</b>,
            },
            ...[
              'Überprüfung der Kürzungen und Ablehnungen',
              'Schadenmanagement & Direktabrechnung mit Werkstatt',
              'Werkstattsuche im Schadenfall',
              'Papierlose Bearbeitung inkl. digitalem Versicherungsordner',
              'Unterstützung bei der Ersatzteilversorgung',
              'Rückmeldung innerhalb von 24 Stunden werktags garantiert',
            ].map((text, i) => ({
              uuid: i + 2,
              type: 'positive',
              children: text,
            })),
          ]}
          labelClosed={undefined}
          labelOpen={undefined}
        />
      );
    } else if (props.productKey === 'sta_product2') {
      return (
        <Checklist
          preview={1000}
          isOpen={true}
          items={[
            {
              uuid: 1,
              type: 'positive',
              children: <b>Erstinspektionsgutschein im Wert von 70,-€</b>,
            },
          ]}
          labelClosed={undefined}
          labelOpen={undefined}
        />
      );
    } else if (props.productKey === 'cashbackVoucher') {
      return (
        <Text>
          Das Cashback wird zwei Monate nach Versicherungsbeginn, frühstens aber zum darauffolgenden 15. ausgezahlt,
          sofern der Erstbeitrag bezahlt und der Vertrag ungekündigt besteht und nicht widerrufen wurde.
        </Text>
      );
    } else if (props.productKey === 'inspectionVoucher') {
      return (
        <Text>
          Du erhältst einen Inspektionsgutschein im Wert von 50,-€, den du für das versicherte Bike einlösen kannst.
          Nach Kauf und Bezahlung erhältst du den oder die Gutscheincodes per E-Mail. Deinen Gutscheincode kannst du in
          Verbindung mit der Inspektionsrechnung bei uns einreichen und dir die Kosten erstatten lassen.
        </Text>
      );
    }
    return null;
  }, [props.productKey, psChecklist]);

  const SelectControl = (
    <>
      <Stack
        spacing='xs'
        style={{ alignSelf: isXs ? 'end' : undefined }}
        w='min-content'>
        <Select
          value={customProductInfo?.amount?.toString() || '0'}
          onChange={setAmountCallback}
          data={amounts}
          miw='5em'
          maw='8em'
        />

        {priceInfos?.map((priceInfo) => (
          <Price2>
            <Price2.Price
              label={
                <>
                  {priceInfo.getPeriodDisplay()}
                  {(customProduct?.amount || 0) >= 2 ? <>&nbsp;Je</> : ''} {numeral(priceInfo.amount).format('0.00')} €
                </>
              }
              size={'lg'}>
              &nbsp;
            </Price2.Price>
          </Price2>
        ))}
      </Stack>
    </>
  );

  return (
    <Card
      shadow='sm'
      radius='md'
      p={isXs ? 'xs' : 'lg'}
      mx={isXs ? '-md' : '0'}
      style={{ overflow: 'visible' }}
      withBorder>
      <Group
        position='apart'
        align='flex-start'
        noWrap>
        <Group
          align='flex-start'
          noWrap>
          <Stack
            m={0}
            style={{ alignItems: 'flex-start' }}
            spacing={'xs'}>
            <CustomProductImageDisplay productInfo={productBaseInfo} />
            {isXs && SelectControl}
          </Stack>

          <Box>
            <Title order={6}>{customProduct?.displayName}</Title>
            {DetailsElement}
          </Box>
        </Group>

        {!isXs && SelectControl}
      </Group>
      {GetIsDevMode() && (
        <Spoiler
          maxHeight={0}
          hideLabel={'Verbergen'}
          showLabel={'Debug Info'}>
          Test
        </Spoiler>
      )}
    </Card>
  );
};

export const CustomProductsSelection = () => {
  const loading = useAppSelector((state) => state.checkout.loading);

  const sortedProductKeys = useSortedProductKeys(false);

  return (
    <>
      {sortedProductKeys.map((productKey: CustomProductNames, i: number) => (
        <Box
          key={productKey}
          mt={'lg'}>
          <CustomProductRow productKey={productKey} />
        </Box>
      ))}
      <LoadingOverlay visible={loading} />
    </>
  );
};
