import { updateVoucher } from '@/actions/data/updateVoucher';
import { useAppDispatch, useAppSelector } from '@/hooks';
import React, { useEffect, useState } from 'react';

export const InitialVoucherLoader: React.FC = () => {
  const [intitialized, setInitialized] = useState(false);

  const voucherInput = useAppSelector((state) => state.userData.voucherCode);
  const loadedVoucher = useAppSelector((state) => state.voucher?.voucher?.code);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (intitialized) return;
    setInitialized(true);

    if (voucherInput === loadedVoucher) return;
    dispatch(updateVoucher({ skipUpdateEnsurance: true }));
  }, [intitialized]);

  return null;
};
