import React, { KeyboardEvent, useCallback, useEffect, useMemo } from 'react';

import { Button } from '@unserkunde/enscompare-components';
import { Box, Container, Text, createStyles, Title, Card, SimpleGrid } from '@mantine/core';

import { selectDevMode } from '@/actions/devmode';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { EnsIcon, type EnsIconName } from 'forms/shared/AvailalbeEnsIcons';
import { useFadeOutClicks } from '@/lib/useFadeOutClick';
import { useEnsOptions } from '@/reducer/ensOptions';
import { useIsXs } from './shared/EnsContainerObserver';

const useStyles = createStyles((theme) => ({
  selectItemBox: {
    padding: theme.spacing.xs,
  },
  selectItemButton: {
    height: 'auto',
  },
  selectItemButtonInner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing.sm,
  },
  container: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing.xs,
    color: theme.colors[theme.primaryColor][theme.primaryShade as number],
  },
  page: {
    paddingBottom: theme.spacing.xl,
  },
  wrapSpace: {
    whiteSpace: 'normal',
  },
  devicemodeIcon: {
    fontSize: '2.5rem',
  },
  deviceCard: {
    cursor: 'pointer',
    transition: 'transform 0.15s',
    '&:hover': {
      transform: 'scale(1.03)',
    },
    '&:focus-visible': {
      transform: 'scale(1.03)',
      outline: `solid ${theme.colors.primary[8]}`,
    },
    '&:active': {
      transform: 'scale(1)',
    },
  },
}));

const SelectItem = (props) => {
  const { devkey } = props;
  const dispatch = useAppDispatch();
  const onSelectDevice = useCallback(() => dispatch(selectDevMode(devkey)), [dispatch, devkey]);

  const { classes } = useStyles();

  return (
    <Box className={classes.selectItemBox}>
      <Button
        onClick={onSelectDevice}
        variant='outline'
        fullWidth
        className={classes.selectItemButton}>
        <div className={classes.selectItemButtonInner}>
          <Text fz='lg'>
            {!props.icon ? null : (
              <>
                <EnsIcon
                  className={classes.devicemodeIcon}
                  icon={props.icon}
                  useAppColor
                />
                &nbsp;&nbsp;
              </>
            )}
            {props.name}
          </Text>
          {!props.infotext ? null : (
            <Text
              className={classes.wrapSpace}
              ta='center'
              fz='xs'
              c='dimmed'>
              ({props.infotext})
            </Text>
          )}
        </div>
      </Button>
    </Box>
  );
};

const SelectItemNew = (
  props: {
    icon: EnsIconName;
    infotext: string;
    name: string;
    devkey: string;
    onSelectDevicemode;
  } & React.ComponentProps<typeof Card> &
    React.ComponentProps<'div'>
) => {
  const { classes, cx } = useStyles();

  const { icon, infotext, name, devkey, onSelectDevicemode, ...rest } = props;

  return (
    <Card
      className={cx(classes.deviceCard)}
      onClick={() => onSelectDevicemode(devkey)}
      withBorder
      bg={'white'}
      role='button'
      p='sm'
      {...rest}>
      <Card.Section
        bg={'primary'}
        p='lg'
        ta={'center'}>
        <EnsIcon
          icon={icon}
          color='white'
          fontSize={'6em'}
        />
      </Card.Section>
      <Card.Section
        inheritPadding
        py='sm'
        ta={'center'}>
        <Text>{name}</Text>
        {infotext && <Text color={'gray.5'}>({infotext})</Text>}
      </Card.Section>
    </Card>
  );
};

const DeviceSelect = () => {
  const disabledDeviceKeysFromPreset = useAppSelector((state) => state.options.presetIdData?.excludedDeviceSelections);

  const ensOptions = useEnsOptions();

  const disabledDeviceKeys = useMemo(
    () => [...(disabledDeviceKeysFromPreset?.split(',') || []), ...(ensOptions?.disabledDeviceKeys || [])],
    [disabledDeviceKeysFromPreset, ensOptions?.disabledDeviceKeys]
  );
  const devices = useAppSelector((state) => state.ensfields.devicemodes);

  const { classes } = useStyles();

  const dispatch = useAppDispatch();
  const onSelectDevice = useCallback((devkey) => dispatch(selectDevMode(devkey)), [dispatch]);

  const [[onSelectDevicemode], FadeContainer] = useFadeOutClicks(undefined, null, onSelectDevice);

  const selectables = devices
    .filter((device) => device.selectable)
    .filter((device) => !disabledDeviceKeys.includes(device.devkey));

  const isXs = useIsXs();

  return (
    <FadeContainer>
      <Container className={classes.page}>
        <Title
          order={2}
          align='center'
          mb='lg'
          className={classes.title}>
          Was möchtest du versichern?
        </Title>
        {!true ? (
          <div className={classes.container}>
            {selectables.map((device) => (
              <SelectItem
                {...device}
                key={device.devkey}
              />
            ))}
          </div>
        ) : (
          <SimpleGrid cols={isXs ? 1 : Math.min(4, selectables.length)}>
            {selectables.map((device, index) => (
              <SelectItemNew
                {...device}
                key={device.devkey}
                onSelectDevicemode={onSelectDevicemode}
                onKeyDown={(e: KeyboardEvent) => {
                  if (e.key === 'Enter') onSelectDevicemode(device.devkey);
                  else if (e.key === 'ArrowRight') {
                    (e.target as HTMLDivElement).nextElementSibling?.focus();
                  } else if (e.key === 'ArrowLeft') {
                    (e.target as HTMLDivElement).previousElementSibling?.focus();
                  }
                }}
                tabIndex={index + 1}
                aria-label={`Gerät ${device.name} auswählen`}
              />
            ))}
          </SimpleGrid>
        )}
      </Container>
    </FadeContainer>
  );
};

export default DeviceSelect;
