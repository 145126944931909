import * as Sentry from '@sentry/react';
import { GetIsDevMode } from './forms/shared/IsDevMode';
import { useAppSelector, useEffectWithStore } from './hooks';
import { useEffect } from 'react';

import { getPresetId } from './features/Preset';
import { GetPredef } from './features/Predef';
import { useContainerSize } from './forms/shared/EnsContainerObserver';
import { getRefererId } from './actions/data';

const windowUrl = window.location.href;

const sentryIsEnabled =
  !GetIsDevMode() && !windowUrl.includes('tiermedizinportal.de') && !windowUrl.includes('localhost');

if (sentryIsEnabled) {
  Sentry.init({
    dsn: 'https://fd2e70ef9cabebb870ea23fe1b0be51e@o4506117183766528.ingest.us.sentry.io/4508103150993408',
    integrations: [
      Sentry.browserTracingIntegration(),
      /* Sentry.replayIntegration(), */ Sentry.captureConsoleIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost' /* , /^https:\/\/ebikeversicherungen\.net/ */],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export const SentrySetting = !sentryIsEnabled
  ? () => null
  : () => {
      const visitorId = useAppSelector((state) => state.visitorId.id);

      useEffect(() => {
        Sentry.setUser({ id: visitorId });
      }, [visitorId]);

      const devicemode = useAppSelector(
        (state) => (state.userData.deviceModeOverride || state.devicemode.devkey) + ' ' + state.userData?.insuranceType
      );

      const router = useAppSelector((state) => state.router);

      const containerSize = useContainerSize().mtWidth;

      const selectedEns = useAppSelector((state) => state.checkout.name);

      useEffectWithStore(
        (store) => {
          Sentry.setContext('EnsApp', {
            presetId: getPresetId(store.ensPreset.ensPreset),
            predef: GetPredef(),
            devicemode,
            containerSize,
            refererId: getRefererId(store),
            router,
            selectedEns,
          });
        },
        [devicemode, containerSize, visitorId, router, containerSize, selectedEns]
      );

      return null;
    };
