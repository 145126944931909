import { getUrl } from '../data';
import { addCustomFilter, addUserDataPostFields } from './sharedPostField';
import { applyDefaultUserData, getEnsFilter } from './requestOffers';
import { GetOfferRequestProcessSetting } from 'processes/offerRequestProcess/data/OfferRequestProcessStore';
import { OfferRequestProcessSettingNames } from 'processes/offerRequestProcess/shared/OfferRequestProcessSettingNames';

import { updateDisabledFilter } from '@/actions/customFilter/updateDisabledFilter';
import { updateValidFilter } from '@/actions/customFilter/updateValidFilter';

import { InitilizeFilterSettings } from 'processes/offerRequestProcess/OfferRequestProcessFilterSettings';

const UpdateFilter = async (dispatch, skipAllValidity = false) => {
  await dispatch(updateValidFilter(skipAllValidity));
  await dispatch(updateDisabledFilter(null, skipAllValidity));
};

export const offerMailProcess = () => {
  return async (dispatch, getState) => {
    //Only use Filters that apply to request
    await UpdateFilter(dispatch, false);
    await dispatch(applyDefaultUserData());

    const state = getState();

    const request = JSON.parse(JSON.stringify(state.userData));
    const formData = new FormData();
    addUserDataPostFields(state, request, formData);
    addCustomFilter(state, formData);
    formData.append('ensFilter', getEnsFilter(getState()));

    //Override from local Settings
    if (GetOfferRequestProcessSetting(OfferRequestProcessSettingNames.refererId)) {
      formData.set('refererId', GetOfferRequestProcessSetting(OfferRequestProcessSettingNames.refererId));
    }

    if (GetOfferRequestProcessSetting(OfferRequestProcessSettingNames.voucherCode)) {
      formData.set('voucherCode', GetOfferRequestProcessSetting(OfferRequestProcessSettingNames.voucherCode));
    }

    if (GetOfferRequestProcessSetting(OfferRequestProcessSettingNames.presetId)) {
      formData.set('presetId', GetOfferRequestProcessSetting(OfferRequestProcessSettingNames.presetId));
    }

    formData.set('presetName', 'schnellrechner');

    formData.set(
      'offerMailProcessUseRecommendedFilter',
      GetOfferRequestProcessSetting(OfferRequestProcessSettingNames.useRecommendedFilter)
    );

    dispatch({ type: 'SUBMIT_LOADING' });

    const alertError = (e) => {
      alert('Es ist leider ein Fehler aufgetreten. Ihre Anfrage konnte vermutlich nicht bearbeitet werden.');
      console.log('error', e);
    };

    const result = await fetch(getUrl('/api/ens/v1/ensOffer/offerMailProcess'), {
      body: formData,
      method: 'post',
    })
      .then((o) => o.json())
      .catch(alertError);

    let hasError = false;
    if (result && !result.success) {
      hasError = true;
      alertError(result);
    }

    //Restore all Filter values
    await UpdateFilter(dispatch, true);
    InitilizeFilterSettings(dispatch);

    dispatch({
      type: 'SUBMIT_COMPLETED',
      success: !hasError,
      text: result?.text,
    });
  };
};
