import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, Title, createStyles } from '@mantine/core';

import { HookedAutocomplete, HookedTextInput, HookedAutocompleteDynamicInput } from '@/forms/shared/HookedInputs';
import { EntryQuestionContainer } from '@/forms/shared/DynamicEntryQuestion';

import { useAppDispatch, useAppSelector, useUserData } from '@/hooks';
import { changeUserInput } from '@/actions/form';
import { useAdressSuggestionData } from './useAdressSuggestionData';
import { useIsContainerSize } from '@/forms/shared/EnsContainerObserver';
import { useIsInEnsProcessContext } from '@/processes/processDefinition/DefaultEnsProcessContext';

const useStyles = createStyles(() => ({
  flexGrow: {
    flexGrow: 1,
  },
}));

const anreden: [string, string][] = [
  ['Herr', 'Herr'],
  ['Frau', 'Frau'],
];

const StreetInput = () => {
  const { classes } = useStyles();

  // Bei der Straßeneingebe soll die Hausnummer nicht mit eingegeben werden können
  // Siehe https://app.asana.com/0/1174272655772669/1204561671547925/f
  const [_, onUpdateStreet] = useUserData('street', null, (e) => e);
  const onFilterStreet = useCallback(
    (newStreetValue: string) => {
      const numberIndex = newStreetValue.search(/\d/);
      if (numberIndex === -1) return;

      onUpdateStreet(newStreetValue.slice(0, numberIndex));
    },
    [onUpdateStreet]
  );

  const suggestionData = useAdressSuggestionData();
  const mapppedStreetSuggestions = useMemo(() => {
    return (
      suggestionData?.str?.map((suggestion) => ({
        value: suggestion,
        label: suggestion,
      })) || []
    );
  }, [suggestionData?.str]);

  const isNotMd = !useIsContainerSize('md');

  return (
    <>
      <Flex>
        <div className={classes.flexGrow}>
          <HookedAutocompleteDynamicInput
            dataSourceField='plz'
            dataSourceValue='str'
            dataSource='address'
            field='street'
            label='Straße'
            placeholder='Straße'
            autofillName='street-address'
            onChange={onFilterStreet}
            disableWrap={isNotMd}
          />
        </div>
        <HookedTextInput
          field='street_number'
          label='Hausnummer'
          placeholder='Nr.'
          disableWrap={isNotMd}
        />
      </Flex>
    </>
  );
};

const PersonalInput = () => {
  const userData = useAppSelector((state) => state.userData);
  const dispatch = useAppDispatch();

  const suggestionData = useAdressSuggestionData();
  const foundCities = useMemo(() => suggestionData?.city || [], [suggestionData]);

  const [lastPlz, setLastPlz] = useState(userData.plz);

  useEffect(() => {
    if (userData.city) return;
    if (!userData.plz || lastPlz === userData.plz) return;
    if (foundCities?.length > 0) {
      dispatch(changeUserInput('city', foundCities[0]));
      setLastPlz(userData.plz);
    }
  }, [userData.city, userData.plz, lastPlz, foundCities]);

  const isInEnsProcessContext = useIsInEnsProcessContext();

  return (
    <form data-testid='data-input-personal'>
      <EntryQuestionContainer>
        {!isInEnsProcessContext && <Title order={2}>Persönliche Informationen</Title>}

        <HookedTextInput
          field='plz'
          label='Postleitzahl'
          helpText='Bitte eine 5-stellige deutsche Postleitzahl angeben.'
          autofillName='postal-code'
        />

        <HookedAutocomplete
          data={foundCities}
          field='city'
          label='Stadt'
          autofillName='address-level2'
        />

        <StreetInput />

        <HookedTextInput
          field='phone'
          label='Telefon'
          autofillName='tel'
          type='tel'
        />
      </EntryQuestionContainer>
    </form>
  );
};

export default PersonalInput;
