import { CustomFrontendFilter } from './types/CustomFrontendFilter';
import { getIsOfferRequestProcess } from 'processes/offerRequestProcess/IsOfferRequestProcess';
import FormularEvaluate, { GetEvaluationContext } from '@/lib/FormularEvaluate';
import { OfferType } from '@/reducer/checkout';

export const evaluateFormular = (
  filterValue,
  userData,
  state: RootState,
  _ = null as OfferType | null,
  defaultValue = true
) => {
  if (!filterValue) return defaultValue;

  const context = GetEvaluationContext(state);

  context['filter'] = (name) => {
    return state.customFilter.filterValues ? state.customFilter.filterValues[name] : '';
  };

  context['devicemode'] = () => {
    return userData.deviceModeOverride ? userData.deviceModeOverride : state.devicemode ? state.devicemode.devkey : '';
  };

  context['isAngebotsprozess'] = () => {
    return getIsOfferRequestProcess(state);
  };

  return FormularEvaluate(filterValue, context);
};

export const evaluateFilter = (state: RootState, filter: CustomFrontendFilter) => {
  return evaluateFormular(filter.displayFilter, state.userData, state, state.checkout.offer);
};
