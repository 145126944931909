import { getWindowUrl } from './getWindowUrl';

export function getPageMode() {
  const usedUrl = getWindowUrl();

  if (usedUrl.indexOf('ebikeversicherungen.net') !== -1) {
    return 'intern';
  }

  return 'extern';
}
