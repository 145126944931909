import React from 'react';
import { createStyles } from '@mantine/core';
import { useAppSelector } from '@/hooks';

const useStyles = createStyles(() => ({
  text: {
    color: 'red',
    textAlign: 'center'
  },
  col: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

export default function CheckoutErrorDisplay() {
  const hasError = useAppSelector((state: RootState) => state?.checkout?.offer?.failed);
  const reason = useAppSelector((state: RootState) => state.checkout.offer?.reason);

  const { classes } = useStyles();

  if (!hasError) return null;

  return (
    <div className={classes.col}>
      <span className={classes.text}>{reason}</span>
    </div>
  );
}
