import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  SimpleGridProps,
  createStyles,
  Group,
  CloseButton,
  Divider,
  Loader,
} from '@mantine/core';
import { compareModeActivate, ToggleCompareEnsSelect } from '../../actions/compare';
import { submitAngebotAnfordern } from '../../actions/progress';

import { changeUserInput } from '../../actions/form';
import ThemedSpan from '../shared/ThemedSpan';
import styles from './style.css?inline';
import { useEnsfieldsValue } from '@/features/Ensfields';

import { useIsXs } from '@/forms/shared/EnsContainerObserver';

import coStyles from './CompareOverlay.scss?inline';

import { useAppDispatch, useAppSelector } from '@/hooks';
import { getQueryStrings } from '@/actions/shared/QueryParams';
import { useMediaQuery } from '@mantine/hooks';
import { EnsPriceInfo } from '../shared/pricing/EnsPriceInfo';
import { useAppIsInView } from '../shared/AppInViewObserver';
import { IsInIframeContext } from '@/lib/IsInIframeContext';
import { useInlineStyle } from '@/indexElements';
import { useEnsOptions } from '@/reducer/ensOptions';

const useStyles = createStyles((theme, props: any) => {
  const mainColor = props.compareModeAvailable ? 'rgb(255,255,255)' : 'rgba(255,255,255,0.5)';

  return {
    container: {
      background: theme.colors.primary[8],
      bottom: 0,
      borderRadius: '5px 5px 0 0',
      position: 'fixed',
      width: '100%',
      transition: 'opacity 0.3s',
      maxWidth: 1170,
      left: '0',
      right: '0',
      opacity: props.appIsInView ? 1 : 0,
      zIndex: '100',
      ...(props.selectedItemsCount === 0 ? { opacity: '0', pointerEvents: 'none', display: 'none' } : null),
      ...(IsInIframeContext()
        ? {
            top: 0,
            bottom: undefined,
            borderRadius: '5px',
            marginTop: '5px',
            position: 'sticky',
            opacity: 1,
            pointerEvents: 'initial',
            display: undefined,
          }
        : {}),
    },
    vergleicherButton: {
      border: `1.5px solid ${mainColor}`,
      display: 'block',
      backgroundColor: 'transparent !important',
      color: mainColor,
      padding: '8px',
      fontWeight: 'bold',
      borderRadius: '0px',
      flexGrow: 1,
    },
    span: {
      fontWeight: 'bold',
      color: mainColor,
      flexGrow: 1,
      alignSelf: 'center',
    },
    inlineStyleButton: {
      border: `1.5px solid ${mainColor}`,
      background: props.hasError('email') ? 'rgba(255,0,0,0.7)' : 'transparent',
      color: mainColor,
      padding: '3px',
      marginBottom: '0',
      textAlign: 'center',
      outline: 'none',
    },
    mailInput: {
      borderTop: '0 !important',
      borderLeft: '0 !important',
      borderRight: '0 !important',
      overflow: 'hidden',
      flexGrow: 1,
    },
  };
});

const useMaxTarifLimit = () => useEnsfieldsValue('compareTariffLimit', 3);

const SelectedEnsItem = (props: { ensName: string | undefined; isFirst: boolean; isLast: boolean }) => {
  const ensInfos = useAppSelector((state) => state.ensuranceList?.list);

  const matchedEns = useMemo(
    () => (ensInfos || []).filter((ens) => props.ensName && ens.name === props.ensName),
    [ensInfos, props.ensName]
  );

  const selectedEns = matchedEns?.length > 0 ? matchedEns[0] : undefined;

  const isPlaceholder = !selectedEns;

  const dispatch = useAppDispatch();

  const onRemove = useCallback(() => dispatch(ToggleCompareEnsSelect(props.ensName)), [props.ensName]);

  return (
    <Box
      bg={'#fffffff0'}
      style={{
        borderTopLeftRadius: props.isFirst ? '2px' : '0',
        borderBottomLeftRadius: props.isFirst ? '2px' : '0',
        borderTopRightRadius: props.isLast ? '2px' : '0',
        borderBottomRightRadius: props.isLast ? '2px' : '0',
        overflow: 'hidden',
        position: 'relative',
      }}
      w={'100%'}>
      {!isPlaceholder && (
        <>
          <Flex
            gap={'3px'}
            direction={'column'}
            align={'center'}
            p='4px'
            pr={!props.isLast ? '6px' : undefined}
            h='100%'>
            <Group
              w='100%'
              position='apart'
              align='flex-start'>
              <img
                src={selectedEns.logo_maybe_vector}
                alt='Logo des Versicherers'
                style={{
                  height: '1.5em',
                  width: 'auto',
                  filter: 'grayscale(100%) contrast(0.8) opacity(0.7)',
                }}
              />
              <CloseButton
                size={'xs'}
                onClick={onRemove}
              />
            </Group>

            <Group
              c={'black'}
              w='100%'
              position='apart'
              align='flex-end'
              fw={'bold'}
              ta='center'
              style={{ flexWrap: 'nowrap' }}>
              <Text
                fz={'0.8em'}
                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {selectedEns.displayName}
              </Text>

              {selectedEns.period && (
                <Text
                  fz={'0.8em'}
                  style={{ textWrap: 'nowrap' }}>
                  {EnsPriceInfo.once(selectedEns.prices?.brutto[0]).displayFormat()} €{' '}
                  {EnsPriceInfo.empty(EnsPriceInfo.periodFromOffer(selectedEns)).getPeriodDisplay()}
                </Text>
              )}
            </Group>
          </Flex>
        </>
      )}

      {!props.isLast && (
        <Divider
          variant='dashed'
          color='gray.5'
          orientation='vertical'
          style={{ position: 'absolute', right: 0, height: '100%', top: 0 }}
        />
      )}
    </Box>
  );
};

const SelectedEnsRow = (props: SimpleGridProps) => {
  const selectedEns = useAppSelector((state) => state.ensCompare.selectedNames);
  const compareTariffLimit = useMaxTarifLimit();

  const hasVerticalSpace = useMediaQuery(`(min-height: 600px)`);
  const isXs = useIsXs();

  const ensItemPlaces = useMemo(
    () => [...selectedEns, ...Array.from({ length: compareTariffLimit - selectedEns.length })],
    [selectedEns, compareTariffLimit]
  );

  if (!hasVerticalSpace || isXs) return null;
  if (selectedEns.length === 0) return null;

  return (
    <SimpleGrid
      m='-6px'
      spacing={0}
      cols={compareTariffLimit}
      {...props}>
      {ensItemPlaces.map((selectedEns, index) => (
        <SelectedEnsItem
          key={selectedEns || index}
          ensName={selectedEns}
          isFirst={index === 0}
          isLast={index === compareTariffLimit - 1}
        />
      ))}
    </SimpleGrid>
  );
};

const CompareOverlay = ({ compareModeAvailable, selectedItemsCount, hasError, userData, onChangeInput }) => {
  const dispatch = useAppDispatch();

  useInlineStyle('compare-overlay-styles', () => styles);
  useInlineStyle('compare-overlay-costyles', () => coStyles);

  const [compareActivated, setCompareActivated] = useState(false);

  const onCompareModeActivate = useCallback(() => {
    setCompareActivated(true);
    dispatch(compareModeActivate(true));
  }, []);
  const onAngebotanfordern = useCallback(() => dispatch(submitAngebotAnfordern('default')), []);
  const onAngebotLink = useCallback(() => dispatch(submitAngebotAnfordern('link')), []);

  const compareTariffLimit = useMaxTarifLimit();

  const isXs = useIsXs();

  const appIsInView = useAppIsInView();

  const { classes } = useStyles({ compareModeAvailable, hasError, selectedItemsCount, appIsInView });

  const ensOptions = useEnsOptions();

  return (
    <>
      <SimpleGrid
        style={{ filter: 'drop-shadow(1px 3px 4px rgba(0, 0, 0, 0.1)' }}
        cols={isXs ? 2 : 4}
        className={(compareModeAvailable ? 'active' : '') + ' ensCompareOverlayBox ' + classes.container}>
        <SelectedEnsRow style={{ gridColumn: `1 / span ${isXs ? 2 : 4}` }} />

        <ThemedSpan className={classes.span}>
          {selectedItemsCount} von {compareTariffLimit} gewählt
        </ThemedSpan>

        <button
          className={classes.vergleicherButton}
          onClick={onCompareModeActivate}
          disabled={!compareModeAvailable}>
          Jetzt vergleichen{' '}
          {compareActivated && (
            <Loader
              color='yellow'
              size={12}
            />
          )}
        </button>

        <input
          className={`olInput ${classes.inlineStyleButton} ${classes.mailInput}`}
          type='text'
          placeholder={'E-Mail Adresse'}
          onChange={onChangeInput('email')}
          value={userData.email}
          disabled={!compareModeAvailable}
        />
        <button
          className={classes.vergleicherButton}
          onClick={onAngebotanfordern}
          disabled={!compareModeAvailable}>
          Angebot&nbsp;anfordern
        </button>

        {(ensOptions?.allowOfferLinkRequest || getQueryStrings().ensAllowOfferLinkRequest) && (
          <button
            className={classes.vergleicherButton}
            style={{ gridColumn: `1 / span ${isXs ? 2 : 4}` }}
            onClick={onAngebotLink}
            disabled={!compareModeAvailable}>
            Angebotlink&nbsp;anfordern
          </button>
        )}
      </SimpleGrid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    compareModeAvailable: state.ensCompare.compareModeAvailable,
    selectedItemsCount: state.ensCompare.selectedNames.length,
    userData: state.userData,
    hasError: (value) => state.validation.errors.includes(value),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeInput:
      (field, selector = (o) => o.target.value) =>
      (value) =>
        dispatch(changeUserInput(field, selector(value))),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareOverlay);
