import { getUserInputEventDebugInfo } from './form';
import { focusByClass } from './shared/focusByClass';
import { trackByName } from './tracking';

export const selectDevMode = (devkey, skipFocus = false) => {
  return async (dispatch) => {
    await dispatch({ type: 'DEVICEMODE_SELECTED', devkey, ...getUserInputEventDebugInfo() });
    dispatch(trackByName('devicemode_selected'));

    if (!skipFocus) {
      dispatch(focusByClass('ensOverviewInputPrice'));
    }

    /**
     * S-Pedelec Rechner: Schutzbrief-Filter standardmäßig deaktiviert
     * @link https://app.asana.com/0/1174272655772669/1202209469535340
     */
    if (devkey === 'spedelec' || devkey === 'gewerblich') {
      await dispatch({
        type: 'CHANGE_USER_INPUT',
        value: {
          fieldName: 'withSchutzbrief',
          fieldValue: false,
        },
      });
    }
  };
};
