import React, { useMemo } from 'react';
import { useAppSelector } from '@/hooks';
import { Devicemode } from '@/reducer/ensfields';

export const useInstanceNaming = (field) => {
  return useAppSelector((state) => state.ensfields['naming_' + field]);
};

const InstanceNaming = ({ fieldName, defaultValue = '', prefix = '' }) => {
  const name = useInstanceNaming(fieldName);
  return <>{prefix + (name || defaultValue)}</>;
};

const useDeviceMode = (): Devicemode => {
  const devicemode = useAppSelector((state) => state.userData.deviceModeOverride || state.devicemode.devkey);
  const devicemodes = useAppSelector((state) => state.ensfields.devicemodes);
  return useMemo(() => devicemodes.find((d: Devicemode) => d.devkey === devicemode), [devicemode, devicemodes]);
};

export const useInsuranceObjectName = () => {
  const selectedDeviceInfo = useDeviceMode();
  const staticNaming = useInstanceNaming('ensuranceObjectName');

  return selectedDeviceInfo?.insuranceObjectName || staticNaming;
};

export const InstanceNamingObjectInsuranceName = () => {
  const name = useInsuranceObjectName();
  return <>{name}</>;
};

export const useObjectOwnerName = () => {
  const selectedDeviceInfo = useDeviceMode();
  const staticNaming = useInstanceNaming('objectOwner');

  return selectedDeviceInfo?.insuranceObjectOwnerName || staticNaming;
};

export default InstanceNaming;
