const DefaultState = {
  userData: {
    amount: null,
    values: [40],
    IVrechtsbelehrung: false,
    voucher: '',
    bookPremiumservice: false,
    lastname: '',
    vsn: '',
    voucherSelectionMode: {
      noVouchers: false,
      useToggleOrderButton: true,
      hideSubmit: false
    }
  },
  price: 0,
  voucherSum: 0,
  submitComplete: false,
  submitting: false,
  loading: false,
  haenderbindungShopname: null,
  yearlyPrice: null,
  vsnLoading: false,
  vsnFound: null,
  submitResult: null
};

export default function inspectionVoucher(state = DefaultState, action): typeof DefaultState {
  let newState = { ...state, userData: { ...state.userData } };

  if (action.type === 'CHANGE_USER_INPUT_INSPECTIONVOUCHER' && action.field === 'amount') {
    const newValues = Array.apply(null, Array(action.value)).map(function (_, i) {
      return state.userData.values.length > i
        ? state.userData.values[i]
        : state.userData.values[state.userData.values.length - 1] || 40;
    });
    newState = {
      ...state,
      userData: {
        ...state.userData,
        values: newValues,
        [action.field]: action.value
      }
    };
  } else if (action.type === 'CHANGE_USER_INPUT_INSPECTIONVOUCHER') {
    newState = {
      ...state,
      userData: { ...state.userData, [action.field]: action.value }
    };
  } else if (action.type === 'INSPECTION_VOUCHER_SET_VALUE') {
    newState.userData.values = state.userData.values.map((_, i) =>
      action.index === i ? parseFloat(action.value) : state.userData.values[i]
    );
  } else if (action.type === 'INSPECTIONVOUCHER_PRICE_LOAD') {
    return { ...state, loading: true };
  } else if (action.type === 'INSPECTIONVOUCHER_PRICE_LOADED') {
    return {
      ...state,
      loading: false,
      price: action.result.totalPrice,
      voucherSum: action.result.voucherSum,
      haenderbindungShopname: action.result.haenderbindungShopname,
      yearlyPrice: action.result.yearlyPrice
    };
  } else if (action.type === 'INSPECTIONVOUCHER_ORDER') {
    return { ...state, submitting: true };
  } else if (action.type === 'INSPECTIONVOUCHER_ORDER_COMPLETE') {
    return { ...state, submitting: false, submitComplete: true, submitResult: action.result };
  } else if (action.type === 'PREMIUMSERVICE_VSN_USER_LOADED') {
    return { ...state, vsnFound: action.found, vsnLoading: false };
  } else if (action.type === 'PREMIUMSERVICE_VSN_USER_LOADING') {
    return { ...state, vsnLoading: true };
  } else {
    return state;
  }

  return newState;
}
