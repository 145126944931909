import React from 'react';
import { getLaufzeitOptions } from './Ensfeatures';
import { HookedRadioSwitchRow } from './HookedInputs';
import { useAppSelector } from '@/hooks';

export const Vertragslaufzeit = ({ offer }) => {
  const presetData = useAppSelector((state) => state.ensPreset.ensPresetData);
  return (
    <HookedRadioSwitchRow
      helpText={'Eine längere Vertragslaufzeit führt oft zu einem günstigeren Preis'}
      values={getLaufzeitOptions(offer, presetData)}
      field='contractDurationYears'
      title='Vertragslaufzeit'
    />
  );
};
