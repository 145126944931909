import React, { useEffect, useMemo } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useAppDispatch, useAppSelector, useInpectionUserData } from '../../hooks';
import { DynamicBox, PureHtml, SuccessBox } from '../shared/style/components';
import { isInspectionVoucherTest } from '../../reducer/router';
import { ColorSecondary } from '../shared/style/colors';
import { InspectionVoucherAgbRaw } from './InspectionVoucherAgb';
import InspectionVoucherSubmitButton from './InspectionVoucherSubmitButton';
import InspectionVoucherInputs, { InspectionVoucherAmount } from './InspectionVoucherInputs';
import PremiumserviceSelect from './PremiumserviceSelect';
import { useIsPremiumserviceProcess } from 'processes/premiumserviceProcess/PremiumserviceProcessInfo';
import { updateInspectionVoucherPrice } from '@/actions/data/inspectionVoucherActions';

const VoucherSelectionPage = ({
  frameless = false,
  noVouchers = false,
  useToggleOrderButton = false,
  hideSubmit = false,
  children = null,
}) => {
  const dispatch = useAppDispatch();
  const infoText = useAppSelector((state) => state.ensfields.inspectionvoucher_infotext);
  const useCta = useAppSelector((state) => state.ensfields?.inspectionvoucher_show_cta === '1');
  const submitComplete = useAppSelector((state) => state.inspectionVoucher.submitComplete);

  const haenderbindungShopname = useAppSelector((state) => state.inspectionVoucher.haenderbindungShopname);

  const [_, onChangeVoucherSelectionMode] = useInpectionUserData('voucherSelectionMode');
  useEffect(() => {
    onChangeVoucherSelectionMode({ noVouchers, useToggleOrderButton, hideSubmit });
  }, []);

  const frameContainerStyle = useMemo(() => {
    return !frameless
      ? {
          marginTop: '1em',
          background: ColorSecondary.alpha(0.1).string(),
          borderRadius: '10px',
          padding: '1em',
        }
      : {};
  }, [frameless]);

  useEffect(() => {
    dispatch(updateInspectionVoucherPrice());
  }, []);

  const isPremiumserviceProcess = useIsPremiumserviceProcess();
  if (!useCta && !isInspectionVoucherTest() && !isPremiumserviceProcess) return null;

  if (submitComplete) return <SuccessBox>Ihre Gutscheincodes wurden ebenfalls erfolgreich bestellt!</SuccessBox>;

  return (
    <>
      <Container style={frameContainerStyle}>
        <Row>
          <Col style={{ marginTop: '0.5em' }}>
            <PureHtml
              preLine
              content={infoText}
            />
          </Col>
        </Row>

        {children}

        {useToggleOrderButton ? null : <PremiumserviceSelect />}

        {noVouchers ? null : <InspectionVoucherAmount />}

        <InspectionVoucherInputs />

        {!haenderbindungShopname ? null : (
          <DynamicBox type='info'>
            Durch den Rabatt ist der Gutschein Händlergebunden und nur bei{' '}
            <b>
              <i>"{haenderbindungShopname}"</i>
            </b>{' '}
            einzulösen.
          </DynamicBox>
        )}
        {hideSubmit ? null : (
          <Row style={{ marginBottom: '1em' }}>
            <Col
              sm={12}
              style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              {!useToggleOrderButton ? <InspectionVoucherSubmitButton /> : <InspectionVoucherSubmitButton.Toggle />}
            </Col>
          </Row>
        )}
        <InspectionVoucherAgbRaw />
      </Container>
    </>
  );
};

export default VoucherSelectionPage;
