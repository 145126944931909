import { useAppSelector } from '@/hooks';
import { Anchor, Box, Button, CopyButton, Stack, Table, TextInput, Title } from '@mantine/core';
import React from 'react';

const Row = ({ label, value }) => {
  return (
    <tr>
      <Box
        component='td'
        style={{ textWrap: 'nowrap' }}>
        {label}
      </Box>
      <Box
        component='td'
        w={'100%'}>
        <TextInput
          readOnly
          value={value}
        />
      </Box>
      <td>
        <CopyButton value={value}>
          {({ copied, copy }) => (
            <Button
              compact
              variant='light'
              onClick={copy}>
              {copied ? 'Kopiert!' : 'Kopieren'}
            </Button>
          )}
        </CopyButton>
      </td>
      <td>
        <Anchor
          href={value}
          target='_blank'>
          Öffnen
        </Anchor>
      </td>
    </tr>
  );
};

export const CompareLinkResult = () => {
  const data = useAppSelector((state) => state.orderSubmit.data);

  return (
    <>
    <Title order={2} mb="xl">Angebots-Urls</Title>
    <Table>
      <thead>
        <tr>
          <th>Beschreibung</th>
          <th>Link</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <Row
          value={data?.context?.predefUrl}
          label={'Link zum Vergleichsrechner'}
        />
        <Row
          value={data?.context?.predefCompareUrl}
          label={'Link zur Vergleichstabelle'}
        />
      </tbody>
    </Table>
    </>
  );
};
