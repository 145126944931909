import React from 'react';

import { Text } from '@mantine/core';
import { HookedTextInput } from './shared/HookedInputs';
import { getAntragsaenderungState } from '@/features/Antragsaenderung';
import { useHasUserDataError } from '@/hooks/validation';


function Antragsaenderung() {
  const hasError = useHasUserDataError();

   if (getAntragsaenderungState() !== 'ammerlaender') {
    return null;
  }

  return (
    <>
      <HookedTextInput
        field="versicherungsnummer"
        label="Antragsänderung - Versicherungsnummer"
        error={hasError('versicherungsnummer')}
      />
      <Text>Für die Übermittlung an die Ammländer muss die Versicherungsnummer angegeben werden</Text>
    </>
  );
}


export default Antragsaenderung;
