import React from 'react';
import { useDynamicFields } from '../../hooks';
import { DynamicEntryQuestionDisplay, useDisplayFilter } from '../shared/DynamicEntryQuestion';
import ZusammengassungRow from './ZusammengassungRow';
import { DynamicEntryQuestionType } from '@/actions/types/DynamicEntryQuestionType';

// eslint-disable-next-line react/require-default-props
function DynamicZusammnfassungRow({ field, bikeId = null }: { field: DynamicEntryQuestionType; bikeId?: string }) {
  const display = useDisplayFilter(field, bikeId);

  if (!display) return null;

  return (
    <ZusammengassungRow
      data={[
        field.displayName,
        <DynamicEntryQuestionDisplay
          key={1}
          setting={field}
          bikeId={bikeId}
        />
      ]}
    />
  );
}

const DynamicZiFields = ({ position, bikeId = null }) => {
  const fields = useDynamicFields(position, (item) => item.displayPositions.includes('zusammenfassung'));

  return (
    <>
      {fields.map((field,i) => (
        <DynamicZusammnfassungRow
          key={field.name + "_" + i}
          field={field}
          bikeId={bikeId}
        />
      ))}
    </>
  );
};

export default DynamicZiFields;
