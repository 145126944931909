import React, { useCallback, useMemo } from 'react';
import { Text, Card, Grid, Flex, useMantineTheme, Box, Tooltip, Input, Group, TextInput } from '@mantine/core';

import { Button } from '@unserkunde/enscompare-components';

import { FiArrowRight } from 'react-icons/fi';
import StickyObserver from '../../shared/StickyObserver';
import { EnsIcon } from '../../shared/AvailalbeEnsIcons';
import { selectorIsLoading, useAppDispatch, useAppSelector, useRequiredFieldsLoaded, useUserData } from '@/hooks';
import { EnsuranceOffer } from '@/actions/types/EnsuranceOffer';
import { ensuranceSelected, submitAngebotAnfordern } from '@/actions/progress';
import { FaCheckDouble } from 'react-icons/fa';
import ScrollInfoView from '../../shared/ScrollInfoView';
import { useResizeObserver } from '@mantine/hooks';
import { useIsStandaloneMode } from '@/features/AutoloadEns';
import { InnerSizeObserver, SizeObserverContext, useGlobalSizeObserverRef } from '../../shared/InnerSizeObserver';
import { EnsPriceInfo } from '../../shared/pricing/EnsPriceInfo';
import { useCompareHeaderStyles } from './CompareTableHeaderStyles';
import { CompareTableHeaderItemTop } from './CompareTableHeaderTop';
import { CompareTableHeaderItemBottom } from './CompareTableHeaderBottom';
import { CompareTableHeaderItemMiddle } from './CompareTableHeaderMiddle';

const useStyles = useCompareHeaderStyles;

const OrderMailComponent = () => {
  const dispatch = useAppDispatch();

  const submit = useCallback(() => dispatch(submitAngebotAnfordern()), [dispatch]);

  const [email, setEmail, emailHasError] = useUserData('email', null, (e) => e.target.value);

  return (
    <Input.Wrapper
      my='sm'
      label='Angebot per Mail anfordern'
      description='Du möchtest dieses Angebot noch einmal per Mail erhalten? Gib hier deine Adresse ein'>
      <Group>
        <TextInput
          style={{ flexGrow: 1 }}
          placeholder='Deine E-Mail-Adresse'
          size='sm'
          value={email}
          onChange={setEmail}
          error={emailHasError}
        />
        <Button
          onClick={submit}
          variant='subtle'
          compact>
          Senden
        </Button>
      </Group>
    </Input.Wrapper>
  );
};

const CompareTableHeader = (props: {
  disableDifferences?: boolean;
  onlyDifferences: boolean;
  setOnlyDifferences: (val: boolean) => void;
  topMode?: boolean;
  scrollOnLoad?: boolean;
}) => {
  const { disableDifferences, onlyDifferences, setOnlyDifferences, topMode = false, scrollOnLoad = false } = props;

  const stateEnsurances = useAppSelector((state) => state.ensuranceList?.list);
  const selectedNames = useAppSelector((state) => state.ensCompare.visibleNames);

  const isStandaloneMode = useIsStandaloneMode();

  const offerByEnsNameMap = useMemo(() => {
    return (stateEnsurances || []).reduce((acc, ens) => ({ ...acc, [ens.name]: ens }), {});
  }, [stateEnsurances]);

  const [isSticky, setIsSticky] = React.useState(false);
  const [isButtonSticky, setIsButtonSticky] = React.useState(false);

  const gridGutter = 0;

  const theme = useMantineTheme();

  const toggleOnlyDiffs = useCallback(
    () => setOnlyDifferences(!onlyDifferences),
    [onlyDifferences, setOnlyDifferences]
  );

  const ShowDifferencesButton = !disableDifferences && (
    <Button
      w={'100%'}
      bg='white'
      compact
      variant={onlyDifferences ? 'filled' : 'outline'}
      onClick={toggleOnlyDiffs}>
      Nur Unterschiede anzeigen
      {onlyDifferences && (
        <>
          <FaCheckDouble
            color='white'
            size={'0.7em'}
          />
        </>
      )}
    </Button>
  );

  const ensurancesLength = selectedNames.length;

  const [headerRef, headerSize] = useResizeObserver();
  const [headerButtonRef, headerButtonSize] = useResizeObserver();

  const appReady = useRequiredFieldsLoaded();
  const loading = useAppSelector((state) => selectorIsLoading(state, { includeEnsuranceListLoading: true }));

  const isSmallHeight = window.innerHeight < 700;
  const { classes } = useStyles({ isSticky, topHeaderHeight: headerSize.height, isSmallHeight });

  return (
    <>
      {/* Vorallem in der Angebotsansicht muss gewartet werden bis alles da ist, da sich sonst noch das Layout weiter verschieben kann */}
      {!loading && appReady && scrollOnLoad && ensurancesLength > 0 && <ScrollInfoView />}
      <StickyObserver
        setIsSticky={setIsSticky}
        offset={theme.spacing.xl * 1.6}
      />
      <div className={classes.headerGroup}>
        <Grid
          ref={headerRef}
          columns={ensurancesLength || 12}
          gutter={gridGutter}>
          {selectedNames.map((ensName, i) => (
            <Grid.Col
              key={i}
              span={1}
              py={0}>
              <CompareTableHeaderItemTop
                offer={offerByEnsNameMap[ensName] || null}
                ensName={ensName}
                isSticky={isSticky}
                key={ensName}
                className={i === 0 ? classes.firstCard : i === ensurancesLength - 1 ? classes.lastCard : undefined}
                isSingleItem={ensurancesLength === 1}
              />
            </Grid.Col>
          ))}
        </Grid>
      </div>
      <Grid
        columns={ensurancesLength || 12}
        gutter={gridGutter}>
        {selectedNames.map((ensName, i) => (
          <Grid.Col
            key={i}
            span={1}
            pt={0}>
            <CompareTableHeaderItemMiddle
              offer={offerByEnsNameMap[ensName] || null}
              ensName={ensName}
              isSticky={isSticky}
              key={ensName}
              className={i === 0 ? classes.firstCard : i === ensurancesLength - 1 ? classes.lastCard : undefined}
              isSingleItem={ensurancesLength === 1}
            />
          </Grid.Col>
        ))}
      </Grid>
      <div className={classes.bottomHeaderGroup}>
        <Grid
          ref={headerButtonRef}
          columns={ensurancesLength || 12}
          gutter={gridGutter}>
          {selectedNames.map((ensName, i) => (
            <Grid.Col
              key={i}
              span={1}
              pt={0}>
              <CompareTableHeaderItemBottom
                offer={offerByEnsNameMap[ensName] || null}
                ensName={ensName}
                isSticky={isSticky}
                key={ensName}
                className={i === 0 ? classes.firstCard : i === ensurancesLength - 1 ? classes.lastCard : undefined}
                isSingleItem={ensurancesLength === 1}
              />
            </Grid.Col>
          ))}
        </Grid>
        {isButtonSticky && <Box h={0}>{ShowDifferencesButton}</Box>}
      </div>

      {!topMode && isStandaloneMode && (
        <Card
          mt='md'
          bg={'primary.0'}>
          <OrderMailComponent />
        </Card>
      )}

      <StickyObserver
        offset={-((headerSize.height + headerButtonSize.height) / window.devicePixelRatio)}
        setIsSticky={setIsButtonSticky}>
        {ShowDifferencesButton}
      </StickyObserver>
    </>
  );
};

export default CompareTableHeader;
