import React, { ReactElement, ReactNode, useContext } from 'react';
import { useEffect, useRef } from 'react';

const AppIsInViewContext = React.createContext<boolean>(false);

export const useAppIsInView = () => useContext(AppIsInViewContext);

export const AppInViewObserver = (props: { children: ReactElement<{ ref: React.Ref<HTMLDivElement> }> }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [isInView, setIsInView] = React.useState<boolean>(false);

  useEffect(() => {
    if (!ref.current) return () => {};

    const observer = new IntersectionObserver((e) => {
      e.forEach((entry) => {
        setIsInView(entry.isIntersecting);
      });
    });
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref]);

  const childrenWithRef = React.cloneElement(props.children, { ref });

  return <AppIsInViewContext.Provider value={isInView}>{childrenWithRef}</AppIsInViewContext.Provider>;
};
