import { IndexElementsType } from '@/indexElements';
import { createSlice } from '@reduxjs/toolkit';

const indexElementsSlice = createSlice({
  name: 'inEl',
  initialState: {} as {
    [x in keyof IndexElementsType]: string;
  },
  reducers: {},
});

export default indexElementsSlice.reducer;
