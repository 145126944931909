const labels = {
  bikeTypeName: 'Modellbezeichnung',
  bikeMarke: 'Hersteller',
  price: 'Kaufpreis',
  devicemode: 'Geräteart',
  ageInYears: 'Alter (in Jahren)',
  rahmennummer: 'Rahmennummer',
  iban: 'IBAN',
  voucher: 'Gutscheincode',
  _optional: '(Optional)'
};

export const getLabel = (field, addColon = false) => {
  return (labels[field] + (addColon ? ': ' : '')) || '';
};
