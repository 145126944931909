import { EnsuranceOffer } from '@/actions/types/EnsuranceOffer';
import { useAppSelector } from '@/hooks';
import { useCompareHeaderStyles } from './CompareTableHeaderStyles';
import { useMemo } from 'react';
import { getHideItemPriceInfo, useEyebrowProps, usePriceProps } from '@/forms/ensuranceList/EnsuranceItem';
import { Card, Flex, Space } from '@mantine/core';
import React from 'react';
import { Eyebrow, Price2 } from '@unserkunde/enscompare-components';

const PriceDisplay = ({ offer }: { offer: EnsuranceOffer }) => {
  const priceProps = usePriceProps(offer, true);
  const { classes } = useCompareHeaderStyles({ isSticky: false });

  if (getHideItemPriceInfo(offer)) return null;

  return (
    <div className={classes.noOverflow}>
      <Price2 {...priceProps} />
    </div>
  );
};

export const CompareTableHeaderItemTop = ({
  offer,
  isSticky,
  className,
  isSingleItem,
  ensName,
}: {
  offer: EnsuranceOffer | null;
  ensName: string;
  isSticky: boolean;
  className?: string;
  isSingleItem: boolean;
}) => {
  const { classes } = useCompareHeaderStyles({ isSticky, isSingleItem });

  const ensBaseInfo = useAppSelector((state) => state.ensCompare.ensuranceBaseInfo);

  const style = useMemo(
    () => ({
      transition: 'opacity 0.15s ease-in-out',
      opacity: isSticky ? 0 : 1,
      curstor: 'initial !important',
    }),
    [isSticky]
  );

  const eyebrowSettingns = useEyebrowProps(offer || ensBaseInfo[ensName], null, style, true);

  return (
    <Card className={`${classes.cardTop} ${className}`}>
      {!eyebrowSettingns?.children ? null : (
        <Eyebrow
          {...eyebrowSettingns}
          text={eyebrowSettingns?.children}
        />
      )}

      <Flex
        direction='column'
        align={isSingleItem ? 'center' : undefined}
        h={'100%'}
        gap={0}>
        <Flex justify={'end'}>
          <img
            alt={`Logo of insurance ${offer?.displayName}`}
            src={ensBaseInfo[ensName]?.logo_maybe_vector}
            className={classes.logo}
          />
        </Flex>
        <Space
          h='0'
          mih={'0.5em'}
          className={classes.fg}
        />
        {offer && <PriceDisplay offer={offer} />}
      </Flex>
    </Card>
  );
};
