import { useEffect, useState } from 'react';
import { getUrl } from '@/actions/data';
import { useAppSelector } from '@/hooks';
import { useDebounce } from '@/lib/useDebounce';

const defaultValue = {};

export const useAdressSuggestionData = (disableLoading = false, bikeId = null, plzField = 'plz') => {
  const [data, setData] = useState(defaultValue);

  const plz = useAppSelector((state) => (bikeId ? state.userData.bikes[bikeId] : state.userData)[plzField]);
  const plzDebounce: string = useDebounce(plz, 300);

  useEffect(() => {
    if (disableLoading || plzDebounce?.length !== 5) {
      setData({});
      return;
    }

    const plzUrl = getUrl(`/api/ens/v1/mrmaddress/country/DE/zip/${plzDebounce}`);

    fetch(plzUrl, { cache: 'force-cache' })
      .then((res) => {
        const date = res.headers.get('date'),
          dt = date ? new Date(date).getTime() : 0;
        if (res.status !== 200 || dt < Date.now() - 86_400_000) {
          return fetch(plzUrl, {
            cache: 'reload'
          });
        }
        return res;
      })
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      });
  }, [disableLoading, plzDebounce]);

  return disableLoading ? defaultValue : ((data || defaultValue) as any | { city: string[]; str: string[] });
};
