import { EnsuranceBaseInfo } from '@/actions/types/EnsuranceOffer';
import { createReducer } from '@reduxjs/toolkit';

const DefaultState = {
  loading: false,
  ensuranceInfo: {} as Record<string, EnsuranceBaseInfo>
};

export const EnsuranceInfoReducer = createReducer(DefaultState, (builder) => {
  builder.addCase('ENSURANCE_INFO_LOADING', (state, action) => {
    state.loading = true;
    return state;
  });
  builder.addCase('ENSURANCE_INFO_LOADED', (state, action: any) => {
    state.loading = false;
    state.ensuranceInfo = action.payload;
    return state;
  });
});
