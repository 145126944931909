import { GetPredef, GetPredefInfo } from '@/features/Predef';
import { getUrl } from '../data';
import { addCustomFilter, addUserDataPostFields } from './sharedPostField';

let signal = new AbortController();

export const uncompletedOrderSend = () => {
  return async (dispatch, getState) => {
    signal.abort('Got a new uncompleted order request');
    signal = new AbortController();

    const state: RootState = getState();
    const request = JSON.parse(JSON.stringify(state.userData));
    const checkout = state.checkout;
    const formData = new FormData();

    addUserDataPostFields(state, request, formData);
    addCustomFilter(state, formData);

    formData.append('ensName', checkout.name);

    const predefInfo = GetPredefInfo();
    if (predefInfo && predefInfo.source === 'offer_uncomp') {
      formData.append('__originalOfferUncompHash', predefInfo.key);
    }

    const result = await fetch(getUrl('/api/ens/v1/uncompletedOrder'), {
      body: formData,
      method: 'POST',
      signal: signal.signal,
    })
      .then((o) => o.json())
      .catch(() => {
        return 'Error fetching Result';
      });
  };
};
