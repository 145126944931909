import { createStyles } from '@mantine/core';

const headerBorderStyle = (theme: MantineEnsTheme) => '1px solid ' + theme.colors.fgMuted[2];

const cardBaseStyle = (theme: MantineEnsTheme) => ({
  background: 'white',
  borderRadius: '0',
  borderRight: headerBorderStyle(theme),
});

export const useCompareHeaderStyles = createStyles(
  (
    theme: MantineEnsTheme,
    { isSticky, ...props }: { isSticky?: boolean; isSmallHeight?: boolean; topHeaderHeight?: number }
  ) => ({
    headerGroup: {
      background: 'white',
      position: 'sticky',
      top: `-${theme.spacing.xl * 1.8}px`,
      // background: 'cyan',
      zIndex: 100,
      paddingTop: '0 !important',
      ...(!isSticky ? undefined : { filter: 'drop-shadow(0px 7px 5px #00000055)' }),
    },
    bottomHeaderGroup: {
      background: 'white',
      ...(props.isSmallHeight
        ? {}
        : { position: 'sticky', top: `${-theme.spacing.xl * 1.8 + props.topHeaderHeight}px`, zIndex: 100 }),
    },
    cardTop: {
      ...cardBaseStyle(theme),
      overflow: 'visible !important',
      paddingTop: theme.spacing.xl * 1.8 + 'px !important',
      height: '100%',
      borderTop: headerBorderStyle(theme),
      borderBottom: isSticky ? headerBorderStyle(theme) : undefined,
    },
    cardBottom: {
      ...cardBaseStyle(theme),

      paddingTop: 0,
      borderBottom: headerBorderStyle(theme),
    },
    cardMiddle: {
      ...cardBaseStyle(theme),

      paddingTop: 0,
    },
    firstCard: {
      borderLeft: headerBorderStyle(theme),
    },
    lastCard: {
      borderRight: headerBorderStyle(theme),
    },
    wrap: {
      overflowWrap: 'anywhere',
      flexGrow: 1,
    },
    noOverflow: {
      overflow: 'hidden',
    },
    logo: { maxWidth: '100%', maxHeight: '60px' },
    fg: {
      flexGrow: 1,
    },
  })
);
