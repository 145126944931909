import React, {
  DOMAttributes,
  JSXElementConstructor,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  TextInput,
  Select,
  Radio,
  Checkbox,
  NumberInput,
  Tooltip,
  Autocomplete,
  Group,
  Divider,
  Paper,
  createStyles,
  MantineTheme,
  Textarea,
  Input,
  Text,
  Box,
  TextProps,
} from '@mantine/core';

import { marked } from 'marked';

import TextInputDefaults from '@unserkunde/enscompare-components/src/components/inputs/TextInput';
import DateInput from '@unserkunde/enscompare-components/src/components/inputs/EnsDateInput';
import InputWrapperAutowrap from '@unserkunde/enscompare-components/src/components/inputs/InputWrapperAutowrap';
import { useIsXs } from '@/forms/shared/EnsContainerObserver';

import { UserDataOrBikeProp } from '../../reducer/userData';
import { useAdressSuggestionData } from '../checkout/inputs/useAdressSuggestionData';
import { AvailalbeEnsIcons, EnsIcon } from './AvailalbeEnsIcons';
import { UserDataHook, selectETargetValue, useAppSelector, useUserData } from '@/hooks';
import { PureHtml } from './style/components';

const genericErrorText = 'Bitte gültigen Wert eingeben';

export const NoStretchWidth = 250;

type HookedProps<T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>> = {
  field: UserDataOrBikeProp;
  bikeId?: string;
  defaultValue?: any;
  value?: any;
  onChange?: (...props: any[]) => any;
  userDataHook?: UserDataHook;
  label?: ReactNode;
  useFullHeight?: boolean;
  helpText?: string;
  errorTextOverride?: string;
  autofillName?: string;
  disableWrap?: boolean;
  autoFocus?: boolean;
  [x: string]: any;
} & Omit<Omit<React.ComponentProps<T>, 'children'>, 'defaultValue'>;

const useStyles = createStyles((theme: MantineTheme, props: any) => ({
  checkboxGroupBox: {
    ...TextInputDefaults.styles(theme, props || {}).input,
    borderWidth: '1px',
    borderStyle: 'solid',
  },
  checkboxContainer: {
    '> [class*="-Group-root"]': {
      paddingTop: 0,
    },
  },
}));

export const InfoIcon = ({ baseline = false }) => {
  const selectColor = useCallback((theme) => {
    return theme.fn.lighten(theme.fn.primaryColor(), 0.5);
  }, []);

  const style = useMemo(() => ({ verticalAlign: baseline ? 'baseline' : undefined }), [baseline]);

  return (
    <EnsIcon
      icon='FaInfoCircle'
      size={`${14 * 0.7}px`}
      useAppColor={true}
      selectColor={selectColor}
      style={style}
    />
  );
};

export const ActionHintLabel = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<TextProps> & DOMAttributes<HTMLDivElement>
>((props, ref) => {
  const { children, ...rest } = props;

  const style = useMemo(() => ({ display: 'inline-block', verticalAlign: 'middle', ...rest.style }), []);

  return (
    <Text
      ref={ref}
      {...rest}
      style={style}
      aria-atomic='false'>
      {children}&nbsp;
      <InfoIcon />
    </Text>
  );
});

export const TooltipLabel = ({ label, helpText }) => {
  return (
    <Tooltip label={helpText}>
      <ActionHintLabel>{label}</ActionHintLabel>
    </Tooltip>
  );
};

export const useMantineLabelProps = (label = null, helpText = null, useFullHeight = false) => {
  return useMemo(() => {
    const base = { h: useFullHeight ? '100%' : undefined };

    let labelHtml = label;

    if (typeof label === 'string') {
      labelHtml = marked.parse(label) as string;
      labelHtml = labelHtml.replace(/^<p>(.*)<\/p>\n?$/g, '$1');
      labelHtml = labelHtml.replace(/\\n/g, '<br />');
    }

    const labelContent =
      typeof label !== 'string' ? (
        labelHtml
      ) : (
        <PureHtml
          container={'span'}
          content={labelHtml}
        />
      );

    if (!helpText) return { label: labelContent, ...base };

    return {
      ...base,
      label: (
        <TooltipLabel
          helpText={helpText}
          label={labelContent}
        />
      ),
    };
  }, [label, helpText, useFullHeight]);
};

const useSelectValue = (value, selectKeys) => {
  return useMemo(() => {
    if (selectKeys.includes(value)) return value;

    const selectMatch = selectKeys.find((e) => e == value);
    if (selectMatch) return selectMatch;

    const selectBoolMatch = selectKeys.find((e) => e == (value ? 'true' : 'false'));
    if (selectBoolMatch) return selectBoolMatch;

    return value;
  }, [value, selectKeys]);
};

export const HookedAutocomplete = ({
  field,
  bikeId,
  userDataHook = useUserData,
  label,
  helpText,
  autofillName,
  onChange,
  data,
  useFullHeight = false,
  ...rest
}: HookedProps<typeof Select>) => {
  const [value, onHookChange, hasError] = userDataHook(field, bikeId);

  const labelProps = useMantineLabelProps(label, helpText, useFullHeight);

  const onChangeEvt = useCallback(
    (e) => {
      onHookChange(e);
      if (onChange) onChange(e);
    },
    [onChange, onHookChange]
  );

  // @ts-ignore
  const mappedData = useMemo(() => (data.find((e) => (e?.value || e) === value) ? [] : data), [value, data]);

  return (
    <InputWrapperAutowrap {...labelProps}>
      <Autocomplete
        value={value}
        limit={40}
        maxDropdownHeight={200}
        onChange={onChangeEvt}
        error={hasError ? genericErrorText : undefined}
        autoComplete={autofillName}
        {...rest}
        data={mappedData}
      />
    </InputWrapperAutowrap>
  );
};

export const HookedDateInput = ({
  field,
  bikeId,
  userDataHook = useUserData,
  label,
  helpText,
  useFullHeight = false,
  disableWrap,
  autoFocus,
  ...additionalProps
}: HookedProps<typeof DateInput>) => {
  const labelProps = useMantineLabelProps(label, helpText, useFullHeight);

  const [value, onChangeHook, hasError] = userDataHook(field, bikeId, (e) => e);

  const isXs = useIsXs();

  const computedProps = useMemo(() => {
    const { nostrech, type, disableWrap, isBday, ...rest } = additionalProps;

    return {
      ...(!isXs && nostrech ? { maw: NoStretchWidth + 4, gutter: 4 } : {}),
      isBday: !!isBday,

      ...rest,
    };
  }, [additionalProps, isXs]);

  const [objRef, setObjRef] = useState<HTMLDivElement>(null);
  useEffect(() => {
    if (!objRef || !autoFocus) return;
    const targetFocus = objRef.querySelector('input');
    targetFocus.focus();
  }, [objRef, autoFocus]);

  return (
    <InputWrapperAutowrap
      ref={setObjRef}
      {...labelProps}
      disableWrap={disableWrap}
      error={hasError ? genericErrorText : undefined}>
      <DateInput
        {...computedProps}
        value={value}
        onChange={onChangeHook}
        hasError={hasError}
      />
    </InputWrapperAutowrap>
  );
};

export const HookedTextarea = ({
  field,
  bikeId = null,
  userDataHook = useUserData,
  label = null,
  useFullHeight = false,
  helpText = null,
}: HookedProps<typeof Textarea>) => {
  const labelProps = useMantineLabelProps(label, helpText, useFullHeight);

  const [value, onHookedChange, hasError] = userDataHook(field, bikeId);

  const [intermedValue, setIntermedValue] = useState(value);

  useEffect(() => {
    if (value !== intermedValue) return;
    setIntermedValue(value);
  }, [value]);

  const changeIntedmed = useCallback((e) => setIntermedValue(e.target.value), []);

  const onBlur = useCallback(() => onHookedChange(intermedValue), [intermedValue]);

  return (
    <InputWrapperAutowrap {...labelProps}>
      <Textarea
        value={intermedValue}
        onChange={changeIntedmed}
        onBlur={onBlur}
        maxRows={8}
        error={hasError ? genericErrorText : undefined}
      />
    </InputWrapperAutowrap>
  );
};

export const HookedAutocompleteDynamicInput = ({
  field,
  dataSource = 'address',
  userDataHook = useUserData,
  label,
  helpText,
  bikeId,
  useFullHeight = false,
  ...props
}: Omit<HookedProps<typeof Autocomplete>, 'data'> & {
  dataSource: 'address';
  dataSourceField: 'plz';
  dataSourceValue: 'str' | 'city';
}) => {
  const { disableWrap } = props;

  const addressData = useAdressSuggestionData(dataSource !== 'address', bikeId, props.dataSourceField);

  const [value, onChangeHook, hasError] = userDataHook(field, bikeId);

  const completeMapper = useCallback(
    (data) => {
      switch (dataSource) {
        case 'address':
          return data[props.dataSourceValue || 'str'] || [];
        default:
          return data;
      }
    },
    [dataSource, props.dataSourceValue]
  );

  const completionData = useMemo(() => {
    if (dataSource === 'address') return completeMapper(addressData);
    return [];
  }, [addressData, dataSource, value]);

  const filteredData = useMemo(
    () =>
      completionData.filter((e) => !value || (e as string).toLowerCase().startsWith((value as string).toLowerCase())),
    [completionData, value]
  );

  const labelProps = useMantineLabelProps(label, helpText, useFullHeight);

  return (
    <InputWrapperAutowrap
      {...labelProps}
      disableWrap={disableWrap}>
      <Autocomplete
        value={value}
        onChange={onChangeHook}
        error={hasError ? genericErrorText : undefined}
        data={filteredData}
      />
    </InputWrapperAutowrap>
  );
};

export const HookedTextInput = ({
  field,
  bikeId = null,
  placeholder = null,
  userDataHook = useUserData,
  label = null,
  helpText = null,
  changeMode = 'default',
  useFullHeight = false,
  onChange,
  ...additionalProps
}: Exclude<HookedProps<typeof TextInput>, 'inputMode'> & {
  changeMode?: 'default' | 'blur';
  inputMode?: 'string' | 'number';
  onChange?: (...value: any[]) => any;
}) => {
  const { autoFocus, inputMode, icon, disableWrap } = additionalProps;

  const valueSelector = useMemo(() => {
    //(inputMode !== 'number' ? selectETargetValue : (e) => e)

    switch (inputMode) {
      case 'number':
        return (e) => e;
      default:
        return selectETargetValue;
    }
  }, [inputMode]);

  const valueFormatter = useCallback(
    (e) => {
      switch (inputMode) {
        case 'number':
          return typeof e === 'string' ? parseFloat(e) : e;
        default:
          return e || '';
      }
    },
    [inputMode]
  );

  const [value, onChangeHook, hasError] = userDataHook(field, bikeId, changeMode === 'blur' ? (e) => e : valueSelector);

  const Control = useMemo(
    () =>
      ({
        number: NumberInput,
        autocomplete: Autocomplete,
      })[inputMode] || TextInput,
    [inputMode]
  );

  const isXs = useIsXs();

  const computedProps = useMemo(() => {
    const { nostrech, autoFocus, disableWrap, icon, autofillName, ...rest } = additionalProps;

    return {
      ...(autofillName ? { autoComplete: autofillName } : {}),
      ...(nostrech && !isXs ? { maw: NoStretchWidth } : {}),
      ...(inputMode === 'number' ? { min: 0 } : {}),
      icon:
        icon === null ? null : typeof icon === 'string' && Object.keys(AvailalbeEnsIcons).includes(icon) ? (
          <EnsIcon icon={icon as keyof typeof AvailalbeEnsIcons} />
        ) : (
          icon
        ),
      ...rest,
    };
  }, [additionalProps, inputMode, isXs]);

  const onChangeValue = useCallback(
    (...args) => {
      onChangeHook(...args);
      if (onChange) onChange(valueSelector(args[0]));
    },
    [onChangeHook, onChange, valueSelector]
  );

  let changePropsOverride = null;
  if (changeMode === 'blur') {
    const [tmpValue, setTmpValue] = useState(value);

    changePropsOverride = {
      onChange: (e) => setTmpValue(valueSelector(e)),
      onBlur: () => onChangeValue(tmpValue),
      value: valueFormatter(tmpValue),
    };
  }

  const labelProps = useMantineLabelProps(label, helpText, useFullHeight);

  const [objRef, setObjRef] = useState<HTMLInputElement>(null);

  useEffect(() => {
    if (!objRef || !autoFocus) return;
    objRef.focus();
  }, [objRef, autoFocus]);

  return (
    <InputWrapperAutowrap
      {...labelProps}
      disableWrap={disableWrap}>
      <Control
        ref={setObjRef}
        value={valueFormatter(value)}
        onChange={onChangeValue}
        error={hasError ? genericErrorText : undefined}
        placeholder={placeholder}
        icon={!icon ? null : <EnsIcon icon={icon} />}
        {...computedProps}
        {...changePropsOverride}
      />
    </InputWrapperAutowrap>
  );
};

const BikeIndexView = (props: React.PropsWithChildren) => {
  return (
    <Box
      style={{
        fontSize: '2em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        marginTop: '5px',
        marginBottom: '5px',
      }}>
      {props.children}
    </Box>
  );
};

export const BikeIndex = ({ bikeKey, as = BikeIndexView, ...props }) => {
  const Control = as;

  const bikeKeys = useAppSelector((state) => Object.keys(state.userData.bikes));
  const index = bikeKeys.indexOf(bikeKey) + 1;

  // @ts-ignore
  return <Control {...props}>{index}</Control>;
};

export type SelectObjectEntry = { value: string; label: string };
export type SelectArrayEntry = [string | number | boolean | any, any];

export const HookedDevicemodeSelect = ({
  field,
  displayName,
  helpText,
  hideOptions,
  showOptions,
  ...props
}: Omit<React.ComponentProps<typeof HookedSelect>, 'values'> & { hideOptions?: string[]; showOptions?: string[] }) => {
  const devicetypes = useAppSelector((state) => state.ensfields.devicemodes);

  const values: SelectArrayEntry[] = useMemo(() => {
    return devicetypes
      .filter((d) => d.selectable && !d.virtual)
      .filter((d) => !(hideOptions || []).includes(d.devkey) && (showOptions || [d.devkey]).includes(d.devkey))
      .sort((a, b) => {
        return a.default ? -1 : b.default ? 1 : a.name.localeCompare(b.name);
      })
      .map((d) => [d.devkey, d.name]);
  }, [devicetypes, hideOptions, showOptions]);

  return (
    <HookedSelect
      field={field}
      values={values}
      defaultValue={values.length === 0 ? null : values[0][0]}
      label={displayName}
      helpText={helpText}
      {...props}
    />
  );
};

export const HookedSelect = ({
  field,
  values,
  defaultValue,
  onDefaultSet = null,
  inline = false,
  bikeId = null,
  label = null,
  helpText = null,
  userDataHook = useUserData,
  onChange = null,
  disableWrap = false,
  useFullHeight = false,
  ...additionalProps
}: Omit<HookedProps<typeof Select>, 'data'> & {
  useFullHeight?: boolean;
  values: (SelectArrayEntry | SelectObjectEntry)[];
}) => {
  const [value, onHookedChange, hasError] = userDataHook(field, bikeId);

  useEffect(() => {
    if (!value) {
      onHookedChange(defaultValue);
      if (!onDefaultSet) return;
      onDefaultSet(defaultValue);
    }
  }, []);

  const onValueChange = useCallback(
    (...props) => {
      onHookedChange(...props);
      if (onChange === null) return;
      onChange(...props);
    },
    [onHookedChange, onChange]
  );

  const mappedValues = useMemo(
    () => values.map((e) => (Array.isArray(e) ? { value: e[0], label: e[1] } : e)),
    [values]
  );

  const mappedSelectKeys = useMemo(() => mappedValues.map((e) => e.value), [mappedValues]);
  const mappedValue = useSelectValue(value, mappedSelectKeys);

  const labelProps = useMantineLabelProps(label, helpText, useFullHeight);

  const isXs = useIsXs();

  const computedProps = useMemo(() => {
    const { nostrech, ...rest } = additionalProps;
    return {
      ...(!isXs && nostrech ? { maw: NoStretchWidth } : {}),
      ...rest,
    };
  }, [additionalProps, isXs]);

  return (
    <InputWrapperAutowrap
      {...labelProps}
      disableWrap={disableWrap}>
      <Select
        data={mappedValues}
        onChange={(e) => onValueChange(e)}
        value={mappedValue || ''}
        error={hasError ? genericErrorText : undefined}
        {...computedProps}
      />
    </InputWrapperAutowrap>
  );
};

export const HookedRadioSwitchRow = ({
  field,
  bikeId = null,
  defaultValue = false,
  userDataHook = useUserData,
  errorTextOverride = null,
  values,
  title,
  helpText,
  autofillName,
  useFullHeight = false,
  disableWrap,
  autoFocus,
  ...additionalProps
}: HookedProps<typeof Radio.Group> & { values: [string, string | number][] }) => {
  const [value, onChange, hasError] = userDataHook(field, bikeId, (e) => e);

  useEffect(() => {
    if (value === null || value === undefined) {
      onChange(defaultValue);
    }
  }, []);

  const labelProps = useMantineLabelProps(title, helpText, useFullHeight);

  const { classes } = useStyles(null);

  const selectKeys = useMemo(() => values.map((e) => e[1]), [values]);
  const mappedValue = useSelectValue(value, selectKeys);

  const isXs = useIsXs();

  // check if the selected value is not an option
  useEffect(() => {
    if (!value) return;
    if (selectKeys.includes(value)) return;

    onChange(defaultValue);
  }, [value, values]);

  const [objRef, setObjRef] = useState<HTMLDivElement>(null);
  useEffect(() => {
    if (!objRef || !autoFocus) return;
    const targetFocus = objRef.querySelector('input');
    targetFocus.focus();
  }, [objRef, autoFocus]);

  return (
    <InputWrapperAutowrap
      ref={setObjRef}
      {...labelProps}
      disableWrap={disableWrap}>
      <Radio.Group
        value={mappedValue}
        error={hasError ? errorTextOverride || 'Bitte auswählen' : ''}
        {...additionalProps}
        onChange={onChange}
        className={classes.checkboxContainer}>
        <Paper
          w={isXs ? '100%' : undefined}
          shadow='xs'
          p='sm'
          className={classes.checkboxGroupBox}>
          <Group spacing={'md'}>
            {values.map((e, i) => (
              <React.Fragment key={e[0]}>
                <Radio
                  autoComplete={autofillName}
                  value={e[1]}
                  label={e[0]}
                />
                {i === values?.length - 1 ? null : <Divider orientation='vertical' />}
              </React.Fragment>
            ))}
          </Group>
        </Paper>
      </Radio.Group>
    </InputWrapperAutowrap>
  );
};

export const HookedCheckboxRow = ({
  field,
  bikeId,
  defaultValue = false,
  title,
  helpText,
  useFullHeight = false,
  disableWrap = false,
  noWrapWithTitle = false,
  ...additionalProps
}: Omit<HookedProps<typeof Checkbox>, 'Title'> & { title: string }) => {
  const [value, onChange, hasError] = useUserData(field, bikeId, (e) => e.target.checked);

  useEffect(() => {
    if (value === null || value === undefined) {
      onChange({ target: { checked: defaultValue } });
    }
  }, []);

  const labelProps = useMantineLabelProps(title, helpText, useFullHeight);

  const Wrapper = useMemo(() => {
    if (noWrapWithTitle) return ({ children }) => <Input.Wrapper {...labelProps}>{children}</Input.Wrapper>;
    if (!disableWrap) return ({ children }) => <InputWrapperAutowrap {...labelProps}>{children}</InputWrapperAutowrap>;
    return ({ children }) => <>{children}</>;
  }, [disableWrap, noWrapWithTitle, labelProps]);

  return (
    <Wrapper>
      <Checkbox
        onChange={onChange}
        checked={!!value}
        error={hasError ? 'Bitte auswählen' : undefined}
        label={disableWrap ? labelProps?.label : undefined}
        {...additionalProps}
      />
    </Wrapper>
  );
};
