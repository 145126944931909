import { getQueryStrings } from '@/actions/shared/QueryParams';
import { GetIsDevMode } from '@/forms/shared/IsDevMode';

export const getPresetObjectFromQueryParam = () => {
  const query = getQueryStrings();

  //base64 method
  if (query.presetObjects) {
    const decodedString = atob((query.presetObjects || '') as string);
    const parsedObjectSource = JSON.parse(decodedString);
    if (GetIsDevMode()) console.log('Lade presetObjects aus base64');
    if (parsedObjectSource) return parsedObjectSource;
  }

  const directPrefex = 'ens_data_';
  const directQueryParams = Object.keys(query).filter((key) => key.startsWith(directPrefex));

  if (directQueryParams.length > 0) {
    if (GetIsDevMode()) console.log('Es wurden direkte Query Parameter gefunden');

    const regex = /object(\d?)_([a-zA-Z0-9]+)/;

    let objectSource = null;

    directQueryParams.forEach((queryKey: string) => {
      const element = queryKey.slice(directPrefex.length);
      const matches = element.match(regex);

      if (GetIsDevMode()) console.log('matches for direct url preset', matches);

      const objectIndex = matches[1] || '';
      const objectKey = matches[2];

      objectSource = objectSource || {};

      objectSource['object' + objectIndex] ||= {};
      objectSource['object' + objectIndex][objectKey] = query[queryKey];
    });

    if (objectSource) return objectSource;
  } else if (GetIsDevMode()) {
    console.log('Keine PresetObjects gefunden', query, directQueryParams, directPrefex);
  }

  return null;
};
