import React, { useCallback, useEffect } from 'react';

type BackEvents = () => void;

export type GlobalBackContextType = {
  triggerBackEvent: BackEvents;
  registerBackEvent: (handler: BackEvents) => void;
  totalBackHandlers: number;
  registerBackHandler: () => void;
  unRegisterBackHandler: () => void;
  hasHandlers: boolean;
  hasEvents: boolean;
};

export const GlobalBackContext = React.createContext<GlobalBackContextType>(null);

export const useGlobalBackAction = (event: BackEvents) => {
  const context = React.useContext(GlobalBackContext);

  useEffect(() => {
    if (context.triggerBackEvent !== event) {
      context.registerBackEvent(event);
    }
    return () => context.registerBackEvent(null);
  }, [event]);

  return context.hasHandlers;
};

export const useGlobalBackHandler = () => {
  const context = React.useContext(GlobalBackContext);

  useEffect(() => {
    context?.registerBackHandler();
    return context?.unRegisterBackHandler;
  }, []);

  return context?.triggerBackEvent;
};

export const GlobalBackProvider = ({ children }) => {
  const [backEvents, setBackEvents] = React.useState<BackEvents>(null);

  const [backHandlers, setBackHandlers] = React.useState<number>(0);

  const registerBackHandler = useCallback(() => setBackHandlers((e) => e + 1), []);
  const unRegisterBackHandler = useCallback(() => setBackHandlers((e) => e - 1), []);

  const context: GlobalBackContextType = {
    registerBackEvent: (event: BackEvents) => {
      setBackEvents(() => event);
    },
    triggerBackEvent: backEvents,
    registerBackHandler,
    unRegisterBackHandler,
    hasEvents: !!backEvents,
    hasHandlers: !!backHandlers,
    totalBackHandlers: backHandlers,
  };

  return <GlobalBackContext.Provider value={context}>{children}</GlobalBackContext.Provider>;
};
