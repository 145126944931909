import { useIndexElements } from '@/indexElements';
import { useEffectWithStore } from '@/hooks';

export const CustomCss = () => {
  const stylesRoot = useIndexElements().stylesRoot;

  useEffectWithStore(
    (store) => {
      if (!store?.ensOptions?.customCssUrl) return;

      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = store.ensOptions.customCssUrl;
      stylesRoot.appendChild(link);
    },
    [stylesRoot]
  );

  return null;
};
