import queryString from 'query-string';
import { getOriginalWindowSearch } from './QueryParams';

declare let window: any;

export const getEnsFilter = (state?: RootState) => {
  return (
    queryString.parse(getOriginalWindowSearch()).ensFilter ||
    window.ensFilter ||
    state.ensOptions?.ensFilter ||
    state?.options?.filterkey ||
    ''
  );
};
