import { combineReducers } from 'redux';

import userData from './userData';
import ensuranceList from './ensuranceList';
import router from './router';
import checkout from './checkout';
import validation from './validation';
import ensCompare from './ensCompare';
import orderSubmit from './orderSubmit';
import devicemode from './devicemode';
import options from './options';
import ensfields from './ensfields';
import voucher from './voucher';
import inspectionVoucher from './inspectionVoucher';
import customFilter from './customFilter';
import app from './app';
import ensOptions from './ensOptions';
import ensPreset from './ensPreset';
import indexElements from './indexElements';
import { EnsuranceInfoReducer } from './ensuranceInfo';
import { CustomProductReducer } from './customProducts';
import { overlayReducer as overlay } from '@/processes/overlay/overlayReducer';
import { VisitorIdReducer as visitorId } from './visitorId';
import { GetIsTestMode } from '@/forms/shared/IsDevMode';
import { TestReducer } from 'tests/shared/waitForReduxEvent';
import { UncompletedOrderReducer } from './uncompletedOrder';

const rootReducer = combineReducers({
  ensuranceInfo: EnsuranceInfoReducer,
  customProducs: CustomProductReducer,
  userData,
  ensfields,
  ensuranceList,
  router,
  checkout,
  validation,
  orderSubmit,
  ensCompare,
  devicemode,
  options,
  visitorId,
  voucher,
  inspectionVoucher,
  customFilter,
  overlay,
  app,
  ensOptions,
  ensPreset,
  indexElements,
  uncompletedOrder: UncompletedOrderReducer,
  ...(!GetIsTestMode() ? undefined : { test: TestReducer }),
});

export default rootReducer;

export type RootReducer = ReturnType<typeof rootReducer>;
