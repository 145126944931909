import { EnsPage } from '@/actions/progress';
import { Action, AnyAction } from 'redux';
import { StepIsEnabledCallback } from './StepIsEnabledCallback';

export type ProcessSteps = Partial<Record<EnsPage, EnsProcessStep>>;

export type ProcessRendererProps<T extends ProcessSteps> = {
  target: keyof T;
  processDefinition: EnsProcessDefinition<T>;
};

export type EnsProcessRenderer<T extends ProcessSteps> = React.ComponentType<ProcessRendererProps<T>>;

export type ValidationFunction = (fieldName, userData, options: any[], state: RootState) => any[];

export type EnsProcessStep = {
  validate: ValidationFunction | ValidationFunction[];
  hideStepper?: boolean;
  useFluentProgress?: boolean;
  fluentPreventBack?: boolean;
  fluentPreventDirectSelect?: boolean;
  stepperTitle: string;
  title: React.ReactNode;
  component: React.ComponentType;

  stepIsVisible?: StepIsEnabledCallback;

  hideDefaultButtons?: true;

  previosStep?: EnsPage;
  prevStepAction?(dispatch: AppDispatch, nextAction?: (dispatch: AppDispatch) => any): any;

  nextStep?: EnsPage;
  nextStepAction?(dispatch: AppDispatch, nextAction?: (dispatch: AppDispatch) => any): any;
  nextStepLabel?: string;
};

export type EnsProcessDefinition<T extends ProcessSteps> = {
  processSteps: T;
};

export type EnsProcessDefinitionDefault = EnsProcessDefinition<ProcessSteps>;

export type AnyProcessDefinition = EnsProcessDefinition<{ [x: string]: EnsProcessStep }>;

// Helper function to infer type
export const createEnsProcessDefinition = <T extends ProcessSteps>(
  processDefinition: EnsProcessDefinition<T>
): EnsProcessDefinition<T> => {
  return processDefinition;
};

export type RendererFromProcess<T> = T extends EnsProcessDefinition<infer U> ? EnsProcessRenderer<U> : never;
