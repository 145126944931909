import { ReactNode, useMemo } from 'react';
import useBikeCoreInfos from './Bike/useBikeCoreInfos';
import { useAppSelector } from '@/hooks';

type ZusammenfassungByInstance = {
  useCoreInfos: () => [string, ReactNode][];
};

const useZusammenfassungByInstance = (): ZusammenfassungByInstance => {
  const instanceMode = useAppSelector((state) => state.ensfields.ens_instance_mode);

  return useMemo(() => {
    if (instanceMode === 'animal') return { useCoreInfos: () => useMemo(() => [], []) };

    return { useCoreInfos: useBikeCoreInfos };
  }, [instanceMode]);
};

export default useZusammenfassungByInstance;
