export type PresetIdState = '' | 'loading_finished' | 'not_present';

export type PresetIdData = any | null;

const defaultValue = {
  options: [],
  ens: [],
  preset: {},
  custom: [],
  devMode: '',
  settings: { primaryColor: null },
  presetIdData: null as PresetIdData,
  loading: false,
  presetIdLoading: false,
  presetIdState: '' as PresetIdState,
  presetId: null,
  predefState: '',
  filterkey: null,
};

export default function options(state = defaultValue, action): typeof defaultValue {
  const newState: typeof defaultValue = { ...state };
  if (action.type === 'ENSURANCE_LOADED') {
    newState.ens = action.offer?.options || [];
  } else if (action.type === 'SET_PRESET_ID_STATE') {
    newState.presetIdState = action.state;
  } else if (action.type === 'RESET_TO_ENSLIST') {
    newState.ens = [];
  } else if (action.type === 'DEVICEMODE_SELECTED') {
    newState.devMode = action.devkey;
  } else if (action.type === 'PRESET_ID_LOADING') {
    return { ...state, loading: true, presetIdLoading: true };
  } else if (action.type === 'PRESET_ID_LOADED') {
    newState.presetId = action.presetId;
    newState.presetIdData = action.data;
    newState.loading = false;
    newState.presetIdLoading = false;
  } else if (action.type === 'PREFED_LOADED' && action.data.devicekey) {
    newState.devMode = action.data.devicekey;
  } else if (action.type === 'PREDEF_LOADING') {
    newState.predefState = 'loading';
  } else if (action.type === 'PREDEF_COMPLETED') {
    if (action.notPresent) newState.predefState = 'not_present';
    else newState.predefState = action.success ? 'loaded' : 'error';
  } else if (action.type === 'PRESET_LOADED') {
    newState.devMode = action.devMode;
    newState.preset = action.preset;
  } else if (action.type === 'SET_CUSTOM_OPTIONS') {
    newState.custom = [...state.custom, ...action.options];
  } else {
    return state;
  }

  let options = [];
  let newFilterkey = '';
  let settings = defaultValue.settings;

  const devModeKey = newState.devMode || '_none';

  if (newState.preset?.mode === 'bydevkey' && newState.preset[devModeKey]) {
    newFilterkey = newState.preset[devModeKey].filterkey;
    options = newState.preset[devModeKey].options;
    settings = newState.preset[devModeKey].settings || {};
  }

  newState.options = [...(options || []), ...(newState.ens || []), ...(newState.custom || [])];
  newState.filterkey = newState.presetIdData?.filterkey || newFilterkey;
  newState.settings = settings;

  return newState;
}
