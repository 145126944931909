import queryString from 'query-string';
import { getWindowUrl } from '@/actions/shared/getWindowUrl';

export const getOriginalWindowSearch: () => string = () => {
  // @ts-ignore
  return window.ensOriginalWindowSearch || window.location.search;
};

let parsed = null;

export const getQueryStrings = () => {
  return parsed || (parsed = queryString.parse(getOriginalWindowSearch()));
};

export const getOriginalWindowHost = () => {
  const parser = document.createElement('a');
  parser.href = getWindowUrl();
  return parser.hostname;
};
