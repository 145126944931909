import { addDays, format } from 'date-fns';
import queryString from 'query-string';
import * as uuid from 'uuid';
import { getOriginalWindowSearch } from '@/actions/shared/QueryParams';
import { Devicemode_selectable } from './devicemode';
import { getUserInputEventDebugInfo } from '@/actions/form';
import { MergeProperties } from '@/actions/types/lib/MergeProperties';

function loadDefaults(ensName) {
  if (ensName === 'Enra') {
    return { paymentPeriod: 'once' };
  }
  return {};
}

export type VoucherUsageOption = 'customerCashback' | 'donation' | 'useForAdditionalProducts' | '';
export const getDefaultEnsuranceStartDate = () => {
  return format(addDays(new Date(), 1), 'yyyy-MM-dd');
};
export const bikeStartValue = {
  buyDate: '',
  lockPrice: '50',
  bikeTechnischEinwandfrei: true,
  rahmennummenNachreichen: false,
  manguaranteemonths: 24,
  ageInYears: undefined as number,
  newBuyed: '1',
  privateUse: '1',
  mostlyPrivateUsed: '1',
  devicemode: '',
};

export const dynamicObjectStartValue = {
  buyDate: format(new Date(), 'yyyy-MM-dd'),
};

export type BikeType = typeof bikeStartValue;

const defaultBikeId = uuid.v4();

const predefOverride = {
  lockPrice: '50',
  contractDurationYears: '3',
};

const internFields = {
  useNextAvailableEnsuranceStartDate: '1',
  ___savedEntryQuestionLoaded: false,
};

export const getResettingValue = (ensOptions: EnsOptions): Partial<UserDataType> => {
  return {
    voucherCode: (queryString.parse(getOriginalWindowSearch())?.ensVoucherCode ||
      ensOptions?.ensVoucherCode ||
      '') as string,
  };
};

const startValue = {
  bikes: {
    [defaultBikeId]: { ...bikeStartValue },
  },
  sofortschutz: false,
  versicherungsnummer: '',
  employerTag: '',
  defaultBikeId,
  bookPremiumservice: false,
  isPredef: false,
  isPreset: false,
  vorversicherung: '0',
  vorversicherung_name: '',
  vorversicherung_scheinnummer: '',
  vorschaedernFaelle5Jare: '0',
  ensuranceStartDate: getDefaultEnsuranceStartDate(),
  birthday: '',
  privateUse: true,
  forename: '',
  lastname: '',
  isFirma: false,
  manguaranteemonths: 24,
  beratungsverzicht: false,
  city: '',
  email: '',
  phone: '',
  plz: '',
  street: '',
  street_number: '',
  iban: '',
  paymentPeriod: '1',
  presetName: '',
  isSecondApplication: false,
  voucherCode: '',
  voucherUsage: '' as VoucherUsageOption,
  deviceModeOverride: '' as Devicemode_selectable,
  orderPremiumservice: false,
  __standaloneCompareTableId: '',

  erstinformationenAkzeptiert: false,
  beratungsgrundlageVvg60Akzeptiert: false,

  //stored display
  orderPremiumservice__text: '',
  orderGps__text: '',

  //tracking ids
  gclid: undefined,
  uetmsclkid: undefined,
  fbclid: undefined,

  //animal
  insuranceType: null,

  agbAkzeptiert: false,
  ...predefOverride,
  ...internFields,
};

const resetOverride = {
  bikes: {
    [defaultBikeId]: { ...bikeStartValue },
  },
  defaultBikeId,
  // Deprecated properties, use property "bikes" START
  bikeMarke: '',
  bikeTypeName: '',
  rahmennummer: '',
  rechnugnsnummer: '',
  akkunummer: '',
  schlossnummer: '',
  lockPrice: '',
  lockproducer: '',
  locktype: '',
  lockname: '',
  lockproducerSelect: '',
  // Deprecated properties, use property "bikes" END

  rechtsbelehrung: '',

  vorschaedernFaelle5Jare: '0',
  ammerlaenderFamilienraeder: 'false',
};

const ensListOverride = {
  paymentMethod: 'iban',
  ammerlaenderFamilienraeder: 'false',
};

const defaultValue = { ...startValue, ...resetOverride, ...ensListOverride };

export const DefaultUserData = defaultValue;
export const DefaultObjectUserData = bikeStartValue;

export type UserDataType = typeof defaultValue;
export type BikeUserDataType = typeof bikeStartValue;
export type UserDataOrBike = MergeProperties<UserDataType, typeof bikeStartValue>;
export type UserDataOrBikeProp = keyof UserDataOrBike;

export default function userDataReducer(state = defaultValue, action): UserDataType {
  if (action.type === 'APP_REQUIREMENTS_LOADED') {
    return { ...state, ...getResettingValue(action.ensOptions) };
  }
  if (action.type === 'ENS_PLZ_CITIES_LOADED' && action?.value?.length === 1 && !state.city) {
    return { ...state, city: action.value[0] };
  }
  if (action.type === 'ENSURANCE_LOADED') {
    if (!action?.offer?.options?.includes('withFirma')) {
      return { ...state, isFirma: false };
    }
  }
  if (action.type === 'ENSURANCE_SELECTED') {
    let staticOverrides = {};
    if (action.name === 'Fahrsicher' && new Date() < new Date('2021-02-08')) {
      staticOverrides = {
        ensuranceStartDate: format(addDays(new Date('2021-02-08'), 0), 'yyyy-MM-dd'),
      };
    }
    return { ...state, ...loadDefaults(action.name), ...staticOverrides };
  } else if (action.type === 'RESTART_PROCESS') {
    return {
      ...defaultValue,
      ...state,
      ...resetOverride,
      isSecondApplication: true,
    };
  } else if (action.type === 'RESET_TO_ENSLIST') {
    return { ...state, ...ensListOverride };
  } else if (action.type === 'PREFED_LOADED') {
    const bikes = Object.assign(
      {},
      ...Object.keys(action.data.bikes).map((bikeId) => ({
        [bikeId]: {
          ...bikeStartValue,
          ...Object.fromEntries(
            Object.entries(action.data.bikes[bikeId])
              .map(([key, value]) => {
                return [key, value === null || value === undefined ? bikeStartValue[key] : value];
              })
              .filter(([_, value]) => value !== null && value !== undefined)
          ),
        },
      }))
    );
    return { ...state, ...predefOverride, ...action.data, bikes };
  } else if (action.type === 'DUPLICATE_BIKE') {
    const newId = uuid.v4();
    const newBikes = {};
    Object.keys(state.bikes).forEach((bikeKey) => {
      newBikes[bikeKey] = state.bikes[bikeKey];
      if (bikeKey === action.bikeKey) {
        newBikes[newId] = { ...newBikes[bikeKey] };
      }
    });

    return {
      ...state,
      bikes: newBikes,
    };
  } else if (action.type === 'BIKE_OVERRIDES') {
    if (!action?.bikes?.length) return { ...state };
    const newState = { ...state, bikes: {} };
    action.bikes.forEach((bike, index) => {
      newState.bikes[index === 0 ? newState.defaultBikeId : uuid.v4()] = bike;
    });
    return newState;
  } else if (action.type === 'LOAD_SAVED_STATE') {
    const { bikes, ...userData } = action.payload;
    const firstBikeId = Object.keys(state.bikes)[0];
    return {
      ...state,
      ...userData,
      ___savedEntryQuestionLoaded: true,
      bikes: {
        ...state.bikes,
        [firstBikeId]: {
          ...state.bikes[firstBikeId],
          ...bikes[Object.keys(bikes)[0]],
        },
      },
    };
  } else if (action.type === 'DISABLE_SAVED_STATE') {
    return { ...state, ___savedEntryQuestionLoaded: false };
  } else if (action.type === 'RESET_SAVED_STATE') {
    return { ...defaultValue };
  }

  if (action.type === 'ADD_BIKE') {
    return {
      ...state,
      bikes: { ...state.bikes, [uuid.v4()]: { ...bikeStartValue } },
    };
  } else if (action.type === 'REMOVE_BIKE') {
    const newBikes = { ...state.bikes };
    let bikeId = action.bikeId || Object.keys(state.bikes).reverse()[0];
    delete newBikes[bikeId];
    return { ...state, bikes: newBikes };
  } else if (action.type === 'PREDEF_COMPLETED') {
    return { ...state, isPredef: action.success };
  } else if (action.type === 'PRESET_LOADED') {
    return { ...state, isPreset: true, presetName: action.name };
  } else if (action.type === 'PRESET_USERDATA') {
    return { ...state, ...action.userData };
  } else if (action.type === 'PREMIUMSERVICE_VSN_USER_LOADED') {
    return { ...state, iban: action.iban, ...action.userdata };
  } else if (action.type === 'DEVICEMODE_SELECTED' && action.devkey === '') {
    const bikes = JSON.parse(JSON.stringify(state.bikes));
    Object.keys(bikes).forEach((bikeKey) => {
      delete bikes[bikeKey].devicemode;
    });
    return { ...state, bikes };
  }

  if (action.type !== 'CHANGE_USER_INPUT') return state;

  if (!action.bikeId) {
    const newState = {
      ...state,
      [action.value.fieldName]: action.value.fieldValue,
    };
    return newState;
  }

  return {
    ...state,
    bikes: {
      ...state.bikes,
      [action.bikeId]: {
        ...state.bikes[action.bikeId],
        [action.value.fieldName]: action.value.fieldValue,
      },
    },
  };
}
