import { ReactElement } from 'react';
import { useAppSelector } from '@/hooks';
import { EnsInstanceMode } from '@/reducer/ensfields';

export default ({
  enable,
  disable,
  children
}: {
  enable?: EnsInstanceMode | EnsInstanceMode[];
  disable?: EnsInstanceMode | EnsInstanceMode[];
  children: ReactElement;
}) => {
  const instanceMode = useAppSelector((state) => state.ensfields.ens_instance_mode);

  if (enable !== undefined && (Array.isArray(enable) ? !enable.includes(instanceMode) : enable !== instanceMode)) {
    return null;
  }

  if (disable !== undefined && (Array.isArray(disable) ? disable.includes(instanceMode) : disable === instanceMode)) {
    return null;
  }

  return children;
};
