import { GetIsDevMode } from '@/forms/shared/IsDevMode';
import { get_ads_msclid } from '../data/sharedPostField';
import { TrackerType } from './TrackerType';

export const MicrosoftAdsTracker: TrackerType = (event) => {
  /* @ts-ignore */
  window.uetq = window.uetq || [];

  const msclkid = get_ads_msclid();

  const eventName = event.name;
  const params = !msclkid
    ? {}
    : {
        msclkid: msclkid,
      };

  if (GetIsDevMode()) console.log('MicrosoftAdsTracker', eventName, params, msclkid);

  /* @ts-ignore */
  window.uetq.push('event', eventName, params);
};
