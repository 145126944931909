import queryString from 'query-string';
import { getOriginalWindowSearch } from '@/actions/shared/QueryParams';

declare let window: any;

export const getPresetId = (ensPreset: RootState['ensPreset']['ensPreset']) => {
  const rawPresetId: string =
    queryString.parse(getOriginalWindowSearch()).presetId || ensPreset?.presetId || window?.ensPreset?.presetId;
  return rawPresetId ? rawPresetId.trim() : rawPresetId;
};
