export const filterPaymentMenthod = (fieldName) => {
  return (dispatch) => {
    if (fieldName === 'paymentMethod') {
      dispatch({
        type: 'CHANGE_USER_INPUT',
        value: { fieldName: 'paymentPeriod', fieldValue: '' }
      });
    }
  };
};
