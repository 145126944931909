import { ReactNode, useMemo } from 'react';

export default (): [string, ReactNode][] => {
  //const bikes = useAppSelector((state) => state.userData.bikes);


  return useMemo(
    () => [
      //['Anzeigename', "Wert"],
    ],
    []
  );
};
