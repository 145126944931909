import InstanceNaming from '@/forms/shared/InstanceNaming';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useGlobalBackAction } from '@/processes/shared/GlobalBackContext';
import { Box, Button, SimpleGrid, Title } from '@mantine/core';
import React, { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';

export const ProceduralSecondBikeSelection = (props: { onContinue; onBack }) => {
  useGlobalBackAction(props.onBack);

  const dispatch = useAppDispatch();
  const onAddSecondBike = useCallback(() => {
    dispatch({ type: 'ADD_BIKE' });
    props.onContinue();
  }, [dispatch]);

  const totalBikeCount = useAppSelector((state) => Object.keys(state.userData.bikes).length);

  useEffect(() => {
    if (totalBikeCount >= 2) props.onContinue();
  }, [totalBikeCount]);

  const [mainButton, refMainButton] = useState<HTMLButtonElement>(null);
  const [addButton, refSecondButton] = useState<HTMLButtonElement>(null);

  useMemo(() => {
    mainButton?.focus();
  }, [mainButton]);

  const keyHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft' && e.target === mainButton) addButton.focus();
      if (e.key === 'ArrowRight' && e.target === addButton) mainButton.focus();
    },
    [mainButton, addButton]
  );

  return (
    <Box>
      <Title
        ta='center'
        order={3}
        mb='lg'>
        Möchtest du ein zweites <InstanceNaming fieldName={'ensuranceObjectName'} /> hinzufügen?
      </Title>

      <SimpleGrid cols={2}>
        <Button
          ref={refSecondButton}
          onKeyUp={keyHandler}
          onClick={onAddSecondBike}
          color='secondary'
          variant='outline'>
          2. <InstanceNaming fieldName={'ensuranceObjectName'} /> hinzufügen
        </Button>
        <Button
          ref={refMainButton}
          onKeyUp={keyHandler}
          color='secondary'
          onClick={props.onContinue}>
          Weiter
        </Button>
      </SimpleGrid>
    </Box>
  );
};
