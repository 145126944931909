import { useAppDispatch, useAppSelector } from '@/hooks';
import { useEnsProcessContext } from './DefaultEnsProcessContext';
import { useMemo } from 'react';
import { EnsProcessStep } from './EnsProcess';

export const useVisibleStepProcesses = () => {
  const Progress = useEnsProcessContext();

  const devkey = useAppSelector((state) => state.devicemode.devkey);
  const devices = useAppSelector((state) => Object.keys(state.userData.bikes).length);

  const dispatch = useAppDispatch();

  return useMemo(() => {
    const allKeys = Object.keys(Progress.processSteps);
    const fullState = dispatch((_, getState) => getState());

    return allKeys.filter((key) => {
      const step = Progress.processSteps[key] as EnsProcessStep;
      if (!step.stepIsVisible) return true;
      return step.stepIsVisible({ state: fullState });
    });
  }, [devkey, devices]);
};
