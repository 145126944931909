import { backToEnsList } from './shared/backToEnsList';
import { navigateTo } from './shared/navigateTo';

export const navigateEnsuranceSelected = {
  next: () => {
    return async (dispatch, getState) => {
      const isGewerblich = getState().devicemode.devkey === 'gewerblich';
      dispatch(navigateTo(isGewerblich ? 'detailedBikeInput' : 'personalContactInfo'));
    };
  },
  prev: () => {
    return async (dispatch, getState) => {
      const isGewerblich = getState().devicemode.devkey === 'gewerblich';
      dispatch(navigateTo(isGewerblich ? 'bikeInput' : ''));
    };
  },
};

export const navigatePaymentinfo = {
  next: async (dispatch, getState) => {
    const isGewerblich = getState().devicemode.devkey === 'gewerblich';
    dispatch(navigateTo(isGewerblich ? 'zusammenfassung' : 'bikeinfo'));
  },
  prev: async (dispatch) => {
    dispatch(navigateTo('personalInfo'));
  },
};

export const navigatePersonalinfo = {
  prev: async (dispatch, getState) => {
    const isGewerblich = getState().devicemode.devkey === 'gewerblich';
    dispatch(!isGewerblich ? backToEnsList() : navigateTo('detailedBikeInput'));
  },
};
