import { createStyles } from '@mantine/core';

export const useCompareItemBorder = createStyles((theme) => ({
  outerContainer: {
    borderColor: theme.colors.gray[4],
    borderWidth: '1px',
    borderStyle: 'solid',
    padding: theme.spacing.xs,
    paddingTop: theme.spacing.xl,
    marginLeft: -theme.spacing.xs,
    marginRight: -theme.spacing.xs,
    borderRadius: `${theme.spacing.xs}px ${theme.spacing.xs}px 0 0`,
    boxShadow: 'inset 0px 8px 7px -3px #00000022',
  },
  groupContainer: {
    padding: theme.spacing.xs,
    marginLeft: -theme.spacing.xs,
    marginRight: -theme.spacing.xs,
    boxShadow: 'inset 0px 8px 7px -3px #00000022',

    borderTop: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: 0,

    '&:last-child': {
      borderRadius: `0 0 ${theme.spacing.xs} ${theme.spacing.xs}`,
    },
  },
}));
