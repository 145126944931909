import { getQueryStrings } from '../actions/shared/QueryParams';
import { EnsPage } from '@/actions/progress';

export const isInspectionVoucherTest = () => {
  return (
    Object.keys(getQueryStrings()).includes('inspectionvouchertest') && getQueryStrings().inspectionvouchertest == '1'
  );
};

const getStartValue = () : EnsPage => {
  return isInspectionVoucherTest() ? 'order_success' : '';
};

export default function router(state = getStartValue(), action): EnsPage {
  if (action.type !== 'NAVIGATED') return state;

  return action.target;
}
