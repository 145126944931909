import { Box, BoxProps, createStyles, keyframes } from '@mantine/core';
import React, { AnimationEvent } from 'react';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';

export const fadeOut = keyframes({
  '0%': { opacity: '1' },
  to: { opacity: '0' },
});

export const fadeIn = keyframes({
  '0%': { opacity: '0' },
  to: { opacity: '1' },
});

const useStyles = createStyles((theme, duration: string) => ({
  containerFadeOut: {
    animation: `${fadeOut} ${duration} ease-in-out`,
    animationFillMode: 'forwards',
  },
  containerFadeIn: {
    animation: `${fadeIn} ${duration} ease-in-out`,
    animationFillMode: 'forwards',
  },
}));

export const useFadeOutClick = (clickEvent, durationInMs = 200, resetTrack = null) => {
  const [isFading, setIsFading] = useState(false);

  const callback = useCallback(
    (...props) => {
      setIsFading(true);
      setTimeout(() => clickEvent(...props), durationInMs);
    },
    [clickEvent]
  );

  useLayoutEffect(() => {
    if (isFading) setIsFading(false);
  }, [resetTrack]);

  const { classes } = useStyles((durationInMs / 1000).toString() + 's');

  return [callback, isFading ? classes.containerFadeOut : '', !isFading ? classes.containerFadeIn : ''] as const;
};

export const useFadeOutClicks = (
  durationInMs = undefined as number,
  resetTrack = null,
  ...clickEvents: ((...props: any[]) => void)[]
) => {
  durationInMs = durationInMs ?? 200;

  const [opacity, setOpacity] = useState<number>(0);
  const [clickedIndex, setClickedIndex] = useState<number>(-1);
  const [clickParams, setClickParams] = useState<unknown[]>([]);

  useLayoutEffect(() => {
    setOpacity(1);
  }, [resetTrack]);

  const callbacks = useMemo(() => {
    return clickEvents.map((clickEvent, i) => {
      return (...params) => {
        setOpacity(0);
        setClickedIndex(i);
        setClickParams(params);
      };
    });
  }, [...clickEvents]);

  const { classes } = useStyles((durationInMs / 1000).toString() + 's');

  const Component = useCallback(
    (props: BoxProps & React.ComponentProps<'div'>) => {
      const className = opacity === 0 ? classes.containerFadeOut : classes.containerFadeIn;
      return (
        <Box
          onAnimationEnd={(e: AnimationEvent) => {
            if (e.animationName === fadeOut.name) {
              const callback = clickEvents[clickedIndex];
              callback(...clickParams);
            }
          }}
          className={!props.className ? className : `${props.className} ${className}`}
          {...props}>
          <>{props.children}</>
        </Box>
      );
    },
    [opacity, clickEvents, clickedIndex, clickParams]
  );

  return [callbacks, Component] as const;
};
