import { useAppSelector } from '@/hooks';
import { CustomProductNames } from '@/reducer/customProducts/customProducts.types';
import { useMemo } from 'react';

export const useSortedProductKeys = (includeNonListables = false) => {
  const serverCustomProducts = useAppSelector((state) => state.customProducs.serverProductSelection) || {};

  return useMemo(() => {
    return (Object.keys(serverCustomProducts) as CustomProductNames[])
      .filter((key) => includeNonListables || serverCustomProducts[key]?.listable !== false)
      .sort((keyA, keyB) => {
        const valA: number | string = serverCustomProducts[keyA]?.sortValue || 0;
        const valB: number | string = serverCustomProducts[keyB]?.sortValue || 0;

        if (valA < valB) return -1;
        if (valA > valB) return 1;
        return 0;
      }) as CustomProductNames[];
  }, [serverCustomProducts]);
};
