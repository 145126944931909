import { BikeUserDataType } from '@/reducer/userData';
import requestEnsurance from './data/requestEnsurance';
import { filterPaymentMenthod } from './form/userDataFilter';
import { filterVoucher } from './form/voucherFilter';
import stacktrace from 'stacktrace-js';
import { GetIsDevMode } from '@/forms/shared/IsDevMode';
import { IsCoreFilterProp, saveCurrentFilterValues } from './customFilter/customFilterSaver';

type PropsWithBikeId = [keyof BikeUserDataType, any, string];

type PropsWithoutBikeId = [keyof UserDataType, any];

export type ChangePropTargetField = keyof BikeUserDataType | keyof UserDataType;

type ChangeInputType = (
  ...args: PropsWithBikeId | PropsWithoutBikeId
) => (dispatch: AppDispatch, getState: RootStateGetter) => void;

// Untersuchen mit https://sourcemaps.info/
export const getUserInputEventDebugInfo = () =>
  GetIsDevMode()
    ? {
        stack: stacktrace.getSync().map((call) => {
          return call?.fileName + ':' + call?.lineNumber + ':' + call?.columnNumber;
        }),
      }
    : null;

export const changeUserInput: ChangeInputType =
  (fieldName, fieldValue, bikeId = null) =>
  async (dispatch, getState: RootStateGetter) => {
    const oldValue = bikeId ? getState().userData.bikes[bikeId][fieldName] : getState().userData[fieldName];

    await dispatch({
      type: 'CHANGE_USER_INPUT',
      value: { fieldName, fieldValue },
      bikeId,
      ...getUserInputEventDebugInfo(),
    });

    if (!bikeId) {
      await dispatch(filterPaymentMenthod(fieldName));
      await dispatch(filterVoucher(fieldName, fieldValue));
    }

    if (!bikeId && IsCoreFilterProp(fieldName)) {
      await dispatch(saveCurrentFilterValues());
    }

    if (getState().router !== '') {
      if (
        [
          'orderPremiumservice',
          'paymentPeriod',
          'privateUse',
          'buyDate',
          'newBuyed',
          'price',
          'contractDurationYears',
          'ammerlaenderFamilienraeder',
          'ensuranceStartDate',
          'vorversicherung_gekuendigtdurch',
        ].includes(fieldName) &&
        oldValue !== fieldValue
      ) {
        dispatch(requestEnsurance({ [fieldName]: fieldValue }));
      }
    }
  };
