import { useListState } from '@mantine/hooks';
import React, { useEffect, useMemo } from 'react';

export const addInlineStyle = (style: string, stylesRoot: HTMLDivElement) => {
  const styleElement = document.createElement('style');
  styleElement.innerHTML = style;
  stylesRoot?.appendChild(styleElement);
};

export const InlineStylesContext = React.createContext<{
  addedKeys: string[];
  addInlineStyle: (key: string, styleGetter: () => string) => void;
}>(null);

export const InlineStylesProvider = (props: React.PropsWithChildren) => {
  const [addedKeys, handlers] = useListState<string>([]);

  const styleRoot = useIndexElements().stylesRoot;

  const context = useMemo(
    () => ({
      addedKeys,
      addInlineStyle: (key: string, styleGetter: () => string) => {
        if (addedKeys.includes(key)) return;
        handlers.append(key);
        addInlineStyle(styleGetter(), styleRoot);
      },
    }),
    [addedKeys, handlers, styleRoot]
  );

  return <InlineStylesContext.Provider value={context}>{props.children}</InlineStylesContext.Provider>;
};

export const useInlineStyle = (key: string, styleGetter: () => string) => {
  const context = React.useContext(InlineStylesContext);

  const [initialized, setInitialized] = React.useState<string>(null);

  useEffect(() => {
    if (initialized === key) return;
    setInitialized(key);
    context.addInlineStyle(key, styleGetter);
  }, [key, initialized]);
};

export const reactRootElement = document.createElement('div');

export const enrichtWithUniqueId = <T extends HTMLDivElement>(element: T) => {
  if (element.id) return element;
  const newId = 'ens-elm-' + Math.random().toString(36).substring(2, 15);
  element.id = newId;
  element.setAttribute('data-ens-id', newId);

  window.ensEnrichIndexId = (window.ensEnrichIndexId || 0) + 1;
  element.setAttribute('data-ens-index', window.ensEnrichIndexId);
  return element;
};

export type IndexElementsType = {
  emotionCacheRoot: HTMLDivElement;
  reactRootElement: HTMLDivElement;
  stylesRoot: HTMLDivElement;
  outerDomRoot: HTMLDivElement;
};

export const IndexElementsContext = React.createContext<IndexElementsType>(null);

export const useIndexElements = () => React.useContext(IndexElementsContext);
