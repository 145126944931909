import { getQueryStrings } from '@/actions/shared/QueryParams';
import { DeepPartialExcept } from '@/actions/types/lib/DeepPartial';
import { useEnsOptions } from '@/reducer/ensOptions';
import { useMemo } from 'react';

type IsPremiumserviceProcessState = DeepPartialExcept<RootState, { ensOptions: { premiumserviceProcess } }>;

export const IsPremiumserviceProcess = (state: IsPremiumserviceProcessState): boolean => {
  return Object.keys(getQueryStrings()).includes('premiumserviceProcess') || state.ensOptions?.premiumserviceProcess;
};

export const useIsPremiumserviceProcess = (): boolean => {
  const ensOptions = useEnsOptions();
  const state = useMemo(() => ({ ensOptions }), [ensOptions]);
  return useMemo(() => IsPremiumserviceProcess(state), [state]);
};
