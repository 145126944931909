import { Col, Row } from 'react-grid-system';
import React from 'react';

const Zi = ({ data, children = null, c = undefined }) => {
  if ((c !== undefined && !c) || c === 'false' || c === false) {
    return null;
  }

  const [displayName, value, format = (o) => o] = data;
  return (
    <>
      <Row style={{ borderBottom: '1px solid #dddddd', padding: '2px 0 0 0' }}>
        <Col sm={6} style={{ fontWeight: 'bold' }}>
          {displayName}
          {displayName ? ':' : ''}
        </Col>
        <Col sm={6}>{format(value)}</Col>
      </Row>
      {children}
    </>
  );
};

export default Zi;
