import { getQueryStrings } from '@/actions/shared/QueryParams';
import { DeepPartialExcept } from '@/actions/types/lib/DeepPartial';
import { getAutoloadEnsurances } from '@/features/AutoloadEns';
import { useAppSelector } from '@/hooks';
import { useEnsOptions } from '@/reducer/ensOptions';
import { useMemo } from 'react';

export const useIsOfferRequestProcessProps = (): Parameters<typeof getIsOfferRequestProcess>[0] => {
  const presetIdData = useAppSelector((state) => state.options.presetIdData);
  const ensOptions = useEnsOptions();
  return useMemo(() => ({ options: { presetIdData }, ensOptions }), [ensOptions, presetIdData]);
};

export const useIsOfferRequestProcess = () => {
  const props = useIsOfferRequestProcessProps();
  return getIsOfferRequestProcess(props);
};

export const getIsOfferRequestProcess = (
  state: DeepPartialExcept<RootState, { options: { presetIdData }; ensOptions: { autoLoadEnsurances } }>
): boolean => {
  // Wenn autoload gesetzt ist, darf diese Modus nie aktiv sein
  // Das wir von den Angeboten benutzt. Wenn ein Angebot aus dem offerRequestProcess, welcher per presetId gesetzt wurde,
  // dann würde man auch im geöffneten Angebot den offerRequestProcess Modus haben und kommt nie zum eigentlichen Angebot.
  if (getAutoloadEnsurances(state)) return false;

  if (state.options.presetIdData?.ensCalcMode === 'offerRequestProcess') return true;

  const queryStrings = getQueryStrings();

  return Object.keys(queryStrings).includes('offerRequestProcess') || state.ensOptions?.offerRequestProcess;
};

type getOfferRequestSettingsState = DeepPartialExcept<
  RootState,
  { options: { presetIdData }; ensOptions: { offerRequestNoSettings; offerRequestProcess } }
>;

const _getOfferRequestSettings = (state: getOfferRequestSettingsState) => {
  const queryStrings = getQueryStrings();
  return {
    offerRequestNoSettings:
      Object.keys(queryStrings).includes('offerRequestNoSettings') ||
      !!state.ensOptions?.offerRequestNoSettings ||
      false,
  };
};

let offerRequestSettings: ReturnType<typeof _getOfferRequestSettings> = null;

const getOfferRequestSettings = (state: getOfferRequestSettingsState) => {
  if (offerRequestSettings == null) {
    offerRequestSettings = _getOfferRequestSettings(state);
  }

  // Wenn der Wert aus der PresetID kommt, sollen die Einstellungen nicht angezeigt werden
  // https://app.asana.com/0/1174272655772669/1207019953941243/f
  offerRequestSettings.offerRequestNoSettings ||= state?.options?.presetIdData?.ensCalcMode === 'offerRequestProcess';

  return offerRequestSettings;
};

export const useOfferRequestSettings = () => {
  const presetIdData = useAppSelector((state) => state.options.presetIdData);

  const ensOptions = useEnsOptions();
  return useMemo(() => getOfferRequestSettings({ options: { presetIdData }, ensOptions }), [ensOptions, presetIdData]);
};
