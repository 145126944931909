import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { Container } from '@mantine/core';
import { restart } from '../../actions/progress';
import { ZusammenfassungNoEdit } from '../zusammenfassung/Zusammenfassung';

import { SuccessBox, ErrorBox, InfoBox, PureHtml, Button, DynamicBox } from '../shared/style/components';

import InstanceNaming from '@/forms/shared/InstanceNaming';
import { PageTracking } from '@/actions/tracking/PageTracking';
import { useAppSelector } from '@/hooks';
import { StripeCheckout, SuccessControls } from './SuccessControls';
import { ScrollIntoView } from '../shared/ScrollInfoView';
import { usePushedQueryParameter } from '@/features/BrowserNavigationHandler';

const Success = ({ onRestart, success, successText, voucherInfoText }) => {
  const instanceMode = useAppSelector((state) => state.ensfields.ens_instance_mode);
  const purchaceTrackData = useMemo(() => ({ currency: 'EUR', value: instanceMode === 'animal' ? 100 : 40 }), []);

  const OSnotifications = useAppSelector((state) => state.orderSubmit.notifications);
  const productNotifications = useAppSelector((state) => state.customProducs?.submitResult?.messages);

  const notifications = useMemo(
    () => [...(OSnotifications ?? []), ...(productNotifications ?? [])],
    [OSnotifications, productNotifications]
  );

  const hasOrderGroup = useAppSelector((state) => state.orderSubmit.orderGroup);

  usePushedQueryParameter('ens_success_page', 'true');

  return (
    <>
      <Container>
        <ScrollIntoView />

        {success ? (
          <>
            <SuccessBox>
              <PureHtml
                preLine
                content={
                  successText ||
                  'Vielen Dank für ihren Auftrag. Sie erhalten in den nächsten 24 eine Bestätigung ihres Auftrags per Mail. Sie können das Fenster nun schließen.'
                }
              />
            </SuccessBox>
            {!voucherInfoText ? null : (
              <InfoBox>
                <PureHtml
                  preLine
                  content={voucherInfoText}
                />
              </InfoBox>
            )}
          </>
        ) : (
          <ErrorBox>
            <PureHtml
              preLine
              content={
                successText ||
                'Leider ist beim Abschicken ihres Auftrags ein technischer Fehler aufgetreten. Bitte melden Sie sich persönlich bei uns.'
              }
            />
          </ErrorBox>
        )}

        {notifications?.map((not, i) => (
          <DynamicBox
            key={i}
            type={not.type ?? 'info'}>
            <PureHtml
              preLine
              content={not.text}
            />
          </DynamicBox>
        ))}

        <SuccessControls>
          <Button onClick={onRestart}>
            Jetzt weiteres <InstanceNaming fieldName={'ensuranceObjectName'} /> versichern
          </Button>
        </SuccessControls>

        <StripeCheckout />

        {hasOrderGroup && <ZusammenfassungNoEdit />}
      </Container>
      <PageTracking
        name='insuranceBooked'
        data={purchaceTrackData}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  success: state.orderSubmit.success,
  successText: state.orderSubmit.text,
  voucherInfoText: state.orderSubmit.voucherInfoText,
});

const mapDispatchToProps = (dispatch) => ({
  onRestart: () => dispatch(restart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Success);
