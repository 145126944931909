import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import InputWrapperAutowrap from '@unserkunde/enscompare-components/src/components/inputs/InputWrapperAutowrap';

import { Title, Text, Space } from '@mantine/core';
import { changeUserInput } from '../../../actions/form';

import { OptionExclude } from '../../shared/Option';
import { Vertragslaufzeit } from '../../shared/ReusedFields';
import { getFamilienraederValues, getPaymentPeriodsBase } from '../../shared/Ensfeatures';
import { VersicherungsbeginnMonat, VersicherungsbeginnMonatProps } from '../controls/VersicherungsbeginnMonat';
import VersicherungsbeginInput from '../controls/VersicherungsbeginInput';

import CheckoutOfferErrorDisplay from 'forms/checkout/CheckoutOfferErrorDisplay';
import { HookedRadioSwitchRow, HookedTextInput } from '@/forms/shared/HookedInputs';
import DynamicEntryQuestion, { EntryQuestionContainer } from '@/forms/shared/DynamicEntryQuestion';
import { useAppSelector, useDynamicFields } from '@/hooks';
import { EnsuranceOffer } from '@/actions/types/EnsuranceOffer';
import { TabledPriceDisplay } from '@/forms/shared/pricing/FullPriceDisplay';

const PaymentInputRow = ({ offer, options }: { offer: EnsuranceOffer; options: any }) => {
  const customQuestions = useDynamicFields('payment');

  const iban = useAppSelector((state) => state.userData.iban);

  return (
    <>
      <EntryQuestionContainer>
        <Title order={2}>Bezahlung</Title>

        <HookedTextInput
          field='iban'
          autofillName='iban'
          label={'IBAN'}
        />

        {iban && iban.length >= 2 && !iban.toUpperCase().startsWith('DE') && (
          <HookedTextInput
            field='bic'
            label='BIC'
          />
        )}

        <InputWrapperAutowrap label=' '>
          {!offer?.offerCheckoutInfo?.paymentMethodInfoText ? null : (
            <Text>{offer.offerCheckoutInfo.paymentMethodInfoText}</Text>
          )}
        </InputWrapperAutowrap>

        <OptionExclude on={'no_abweichender_kontoinhaber'}>
          <HookedTextInput
            field='ibanOwner'
            label={
              <span>
                Kontoinhaber<span> </span>
                <span style={{ fontSize: `${14 * 0.7}px` }}>(Wenn abweichend vom Antragsteller)</span>
              </span>
            }
          />
        </OptionExclude>

        <OptionExclude on={'noPaymentPeriod'}>
          <HookedRadioSwitchRow
            field='paymentPeriod'
            title='Zahlweise'
            values={getPaymentPeriodsBase()}
          />
        </OptionExclude>
        {!options.includes('contractDurationInCheckout') ? null : <Vertragslaufzeit offer={offer} />}

        {customQuestions.map((e, i) => (
          <DynamicEntryQuestion
            setting={e}
            key={i}
          />
        ))}

        <Space h='lg' />

        <TabledPriceDisplay hideCustomProducts={true} />

        <CheckoutOfferErrorDisplay />
      </EntryQuestionContainer>
    </>
  );
};

const Versicherungsbeginn = ({ hasError, onChangeInput }: OpenType<{ hasError: HasErrorCheck }>) => {
  const options = useAppSelector((state) => state.options.options);

  const useCalculatedVersicherungsbeginn =
    options?.includes('versicherungsbeginnMonat') || options?.includes('versicherungsbeginn7Days');

  const nextAvailable: VersicherungsbeginnMonatProps['nextAvailable'] = useMemo(() => {
    if (options.includes('versicherungsbeginn7Days')) return '7days';
    return 'month';
  }, [options]);

  return useCalculatedVersicherungsbeginn ? (
    <>
      <VersicherungsbeginnMonat
        enableSofortschutz={options?.includes('versicherungsbeginnMonat_sofortschutz')}
        nextAvailableMode={nextAvailable}
      />
    </>
  ) : (
    <>
      <VersicherungsbeginInput />
      {/*<HookedTextInput
        changeMode="blur"
        type="date"
        label="Gewünschter Versicherungsbeginn"
        field="ensuranceStartDate"
      />*/}
    </>
  );
  /*
   */
};

const Familienraeder = () => {
  const options = useAppSelector((state) => state.options.options);

  if (!options?.includes('ammerlaenderFamilienraeder')) return null;

  return (
    <HookedRadioSwitchRow
      helpText='Auf Wunsch besteht zusätzlich zur beantragten Fahrrad-Vollkaskoversicherung für Ihr Pedelec / E-Bike auch Versicherungsschutz gegen Fahrraddiebstahl für alle Fahrräder der mit Ihnen im gleichen Haushalt lebenden Personen, wie zum Beispiel Ehepartner, Kinder, Lebenspartner, etc. Erstattet werden die Kosten für ein neues Fahrrad gleicher Art und Güte (Neuwert).'
      title='Wollen Sie zusätzliche Fahrräder & Pedelecs aus Ihrem Haushalt gegen Diebstahl versichern? '
      values={getFamilienraederValues()}
      field='ammerlaenderFamilienraeder'
    />
  );
};

const PaymentInput = (props) => {
  const customQuestions = useDynamicFields('paymentTop');

  return (
    <>
      <EntryQuestionContainer>
        <Versicherungsbeginn {...props} />
        {customQuestions.map((e, i) => (
          <DynamicEntryQuestion
            setting={e}
            key={i}
          />
        ))}
        <Familienraeder {...props} />
      </EntryQuestionContainer>
      <PaymentInputRow {...props} />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    offer: state.checkout.offer,
    options: state.options.options || [],
    hasError: (value) => state.validation.errors.includes(value),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeInput:
      (field, selector = (o) => o) =>
      (value) =>
        dispatch(changeUserInput(field, selector(value))),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInput);
