import { useEffect } from 'react';
import { detect } from 'detect-browser';
import Audit from '@/actions/data/audit';

const GlobalErrorHandler = () => {

  return null;

  useEffect(() => {
    const errorCallback = (e) => {
      const browser = detect();

      const errorElement = {
        message: e.message,
        filename: e.filename,
        lineno: e.lineno,
        colno: e.colno,
        error: e.error,
        browser
      };

      Audit(errorElement, 0, 'client:error');
      console.log('Error: ', errorElement);
    };

    window.addEventListener('error', errorCallback);

    return () => {
      window.removeEventListener('error', errorCallback);
    };
  }, []);

  return null;
};

export default GlobalErrorHandler;
