import IBAN from 'iban';
import { custom } from './validation';
import { IsPremiumserviceProcess } from 'processes/premiumserviceProcess/PremiumserviceProcessInfo';

export const validateInspectionVoucher = (_, __, ___, state: RootState) => {
  let err = [];

  if (!state.inspectionVoucher.userData.voucherSelectionMode.noVouchers) {
    err = custom(err, 'iban', !IBAN.isValid(state.userData.iban));
  }

  if (IsPremiumserviceProcess(state)) {
    err = custom(err, 'vsn', !state.inspectionVoucher.vsnFound);
    err = custom(err, 'lastname', !state.inspectionVoucher.vsnFound);
  }

  return err;
};
