import {
  parse,
  type CallExpression,
  type ArrayExpression,
  type BinaryExpression,
  type Program,
  type ExpressionStatement,
  Node,
  Expression,
} from 'acorn';

const formularCache = {};

export const GetFormularTree = (formular: string) => {
  if (!formular) return null;
  const tree: Program = formularCache[formular] || (formularCache[formular] = parse(formular, { ecmaVersion: 2020 }));

  if (!tree?.body || !tree.body[0] || !(tree.body[0] as ExpressionStatement).expression) {
    console.error('Parsed tree: ', tree);
    throw new Error(`FormularEvaluate: Cannot parse formular: ${tree}`);
  }

  return tree.body[0] as ExpressionStatement;
};

export type FilterFunctionCall = {
  funcName: string;
  args: string[];
};

const addUsedFieldToList = (node: Node, list: FilterFunctionCall[]) => {
  switch (node.type) {
    case 'BinaryExpression':
    case 'LogicalExpression':
      addUsedFieldToList((node as BinaryExpression).left, list);
      addUsedFieldToList((node as BinaryExpression).right, list);
      return list;
    case 'CallExpression':
      const tNode = node as CallExpression;
      const name = tNode.callee.name;

      const args = [];

      for (const argument of tNode.arguments) {
        if (argument.type === 'Literal') args.push(argument.value);
        else {
          args.push(null);
          addUsedFieldToList(argument, list);
        }
      }

      list.push({
        funcName: name,
        args: args,
      } satisfies FilterFunctionCall);
      return list;
    case 'ArrayExpression':
      ((node as ArrayExpression).elements as Node[]).forEach((element) => {
        addUsedFieldToList(element, list);
      });
      return list;
  }

  return list;
};

const usedValuesCache: Record<string, FilterFunctionCall[]> = {};
export const getUsedFieldsFromFilter = (filter: string) => {
  if (!filter) return [];

  return (
    usedValuesCache[filter] || (usedValuesCache[filter] = addUsedFieldToList(GetFormularTree(filter).expression, []))
  );
};
