export const groupBy = (elements: any[], selector) => {
  const selFunc = typeof selector === 'string' ? (e) => e[selector] : selector;
  return elements.reduce((elm, e) => {
    const group = selFunc(e) || '';
    if (!elm[group]) {
      elm[group] = [];
    }
    elm[group].push(e);
    return elm;
  }, {});
};
