import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import { ErrorBox, PureHtml, SuccessBox } from '../shared/style/components';
import { PrevNextButtonRow } from '../shared/Common';
import { NavigateTopMarker, navigateTo } from '@/actions/progress';
import { useIsStandaloneMode } from '@/features/AutoloadEns';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { Container, Space } from '@mantine/core';
import { useEnsOverviewStyles } from '../OverviewControls/EnsuranceOverview';
import { useBrowsersBack } from '@/features/BrowserNavigationHandler';
import { ButtonLink } from '@unserkunde/enscompare-components';
import { FaAngleLeft } from 'react-icons/fa';

function TopButtonRow() {
  const dispatch = useAppDispatch();
  const { classes } = useEnsOverviewStyles();

  const isStandaloneMode = useIsStandaloneMode();

  const onGoBack = useCallback(() => {
    dispatch(navigateTo(isStandaloneMode ? 'ensuranceList' : ''));
  }, [isStandaloneMode]);

  useBrowsersBack(onGoBack);

  return (
    <Container className={classes.topButtonRow}>
      <ButtonLink
        leftIcon={<FaAngleLeft />}
        onClick={onGoBack}>
        Zurück
      </ButtonLink>
    </Container>
  );
}

const AngebotfertigView = (props) => {
  const isStandaloneMode = useIsStandaloneMode();

  const dispatch = useAppDispatch();
  const onGoBack = useCallback(() => {
    dispatch(navigateTo(isStandaloneMode ? 'ensuranceList' : ''));
  }, [isStandaloneMode]);

  const Buttons = (nprops) => (
    <PrevNextButtonRow
      {...props}
      {...nprops}
      onPrev={() => onGoBack()}
      hideNext
      labelNextOverride='Angebot anfordern'
    />
  );

  return (
    <Container>
      <NavigateTopMarker />

      <TopButtonRow />

      {props.success ? (
        <SuccessBox>
          <PureHtml
            content={
              props.successText ||
              'Wir haben ihre Anfrage erhalten und werden Ihnen ihre angeforderten Angebote an die angegebene Email zusenden.'
            }
          />
        </SuccessBox>
      ) : (
        <ErrorBox>
          <PureHtml
            content={
              props.successText ||
              'Leider ist beim Abschicken ihres Auftrags ein technischer Fehler aufgetreten. Bitte melden Sie sich persönlich bei uns.'
            }
          />
        </ErrorBox>
      )}

<Space h="lg" />
      <TopButtonRow />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  success: state.orderSubmit.success,
  successText: state.orderSubmit.text,
});

export default connect(mapStateToProps)(AngebotfertigView);
