import { useAutoloadEnsurances } from '@/features/AutoloadEns';
import { useAppSelector } from '@/hooks';

export const useShowEntryQuestions = () => {
  const fieldsInComapreViewEnabled = useAppSelector(
    (state) => state.ensfields.ens_comparemode_show_entryquestions === '1'
  );

  const fromStandaloneState = useAutoloadEnsurances() === 'compare_standalone';

  return fieldsInComapreViewEnabled || fromStandaloneState;
};
