import { getUrl } from '../data';
import { getIsOfferRequestProcess } from 'processes/offerRequestProcess/IsOfferRequestProcess';

declare let window: any;

let _disableEnsFieldPreload = false;
export const disableEnsFieldPreload = (disable: boolean = true) => {
  _disableEnsFieldPreload = disable;
};

export const loadEnsField = () => {
  return async (dispatch, getState: RootStateGetter) => {
    const furl = getUrl('/wp-json/ens/v1/ensfields/default');

    const preload = _disableEnsFieldPreload ? null : window?.ensFieldsPreload?.default;

    const values = preload || (await fetch(furl).then((o) => o.json()));

    await dispatch({
      type: 'ENS_FIELDS_LOADED',
      values,
      mode: 'default',
    });

    let mode = 'default';
    if (getIsOfferRequestProcess(getState())) mode = 'offerRequestProcess';

    // No need to await it
    fetch(getUrl('/wp-json/ens/v1/ensfields/deferred?mode=' + mode), { credentials: 'same-origin' })
      .then((o) => o.json())
      .then((values) =>
        dispatch({
          type: 'ENS_FIELDS_LOADED',
          values,
          mode: 'deferred',
        })
      );
  };
};
