const hotjarTracking = (trackingObj) => {
  if (window.ignoreHotjar) {
    console.log(`Hotjar ignored: ${trackingObj.name}`);
    return;
  }

  const hjt = window.hj || ((_, __) => {/* Do nothing */});

  hjt('trigger', trackingObj.name);
};

export default hotjarTracking;
