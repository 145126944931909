import React, { CSSProperties } from 'react';

const browserCheckStyles: CSSProperties = {
  background: '#CF1619',
  borderRadius: '5px',
  color: '#fff',
  display: 'block',
  marginBottom: '20px',
  maxWidth: '1170px',
  padding: '10px',
  textAlign: 'center'
};

export const get_browser = () => {
  const ua = navigator.userAgent;
  let tem = null;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: 'Opera', version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1]
  };
};

const BrowserCheck = () => {
  const browser = get_browser();
  const isOldSafari = browser?.name.toLowerCase() === 'safari' && Number(browser.version) <= 11;
  const isOldIE = browser?.name.toLowerCase() === 'ie' && Number(browser.version) <= 10;

  if (isOldSafari || isOldIE)
    return (
      <div style={browserCheckStyles}>
        <p>Bitte verwenden Sie eine neuere Version ihres Browsers, um die optimale Funktionalität zu gewährleisten.</p>
      </div>
    );
  else return null;
};

export default BrowserCheck;
