import React, { useCallback, useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { HookedSelect } from '../shared/HookedInputs';
import { useAppDispatch, useInpectionUserData } from '../../hooks';
import { updateInspectionVoucherPrice } from '../../actions/data/inspectionVoucherActions';

const PremiumserviceSelect = () => {
  const dispatch = useAppDispatch();
  const onUpdatePrice = useCallback(() => {
    dispatch(updateInspectionVoucherPrice());
  }, []);

  const [bookPremiumservice, setBookPremiumservice] = useInpectionUserData('bookPremiumservice');

  useEffect(() => {
    if (!bookPremiumservice) {
      setBookPremiumservice(true);
    }
  }, []);

  return (
    <Row style={{ marginBottom: '15px', marginTop: '15px' }}>
      <Col>
        <HookedSelect
          disabled={true}
          field={'bookPremiumservice'}
          compact
          values={[
            [true, 'Ja'],
            [false, 'Nein']
          ]}
          userDataHook={useInpectionUserData}
          onChange={onUpdatePrice}
        />
        &nbsp;&nbsp;ich möchte den Premiumervice für 30,- € jährlich
        {!bookPremiumservice ? <b> nicht </b> : ' '}in Anspruch nehmen.
      </Col>
    </Row>
  );
};

export default PremiumserviceSelect;
