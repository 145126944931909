import { useAutoloadEnsurances } from '@/features/AutoloadEns';
import React, { useEffect, useRef } from 'react';
import { GetIsDevMode } from './IsDevMode';

export default function ScrollInfoView({ behavior = 'instant' as ScrollBehavior }) {
  const ref = useRef<HTMLDivElement>(null);

  const autoloadMode = useAutoloadEnsurances();

  useEffect(() => {
    if (autoloadMode === 'compare_standalone') return;
    setTimeout(() => {
      if (GetIsDevMode()) console.log('Scrolling into view on', ref?.current);
      ref?.current?.scrollIntoView({ behavior, block: 'start' });
    }, 10);
  }, [autoloadMode]);

  return <div ref={ref} />;
}

export const ScrollIntoView = ScrollInfoView;
