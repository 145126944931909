import { TrackerEventType } from './TrackerType';
import { track } from './index';
import { useDispatchEffect } from '@/hooks';

export const PageTracking = (props: TrackerEventType) => {
  useDispatchEffect((dispatch) => {
    dispatch(track(props));
  }, []);

  return null;
};
