import { configureStore, DeepPartial } from '@reduxjs/toolkit';
import rootReducer, { RootReducer } from './reducer/index';
import { getQueryStrings } from './actions/shared/QueryParams';

// @ts-ignore
const enableReduxDevTools = process.env.NODE_ENV !== 'production' || !!getQueryStrings()?.devmode;

export function storeCreator(startValue: DeepPartial<RootReducer> = {}) {
  return configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: { warnAfter: 128 },
        serializableCheck: { warnAfter: 128 },
      }),
    reducer: rootReducer,
    trace: enableReduxDevTools,
    traceLimit: 25,
    devTools: enableReduxDevTools,
    preloadedState: window.ensOptions?.__ens_start_store || startValue,
  });
}

export type AppStoreType = ReturnType<typeof storeCreator>;
export type AppStateGetterType = AppStoreType['getState'];

export type AppDispatch = AppStoreType['dispatch'];
