import { Box, createStyles } from '@mantine/core';
import React from 'react';

const useRechtsbelerungsStyle = createStyles((theme) => ({
  container: {
    border: `1px solid ${theme.colors.gray[2]}`,
    overflow: 'auto',
    height: '12em',
    minHeight: '8em',
    marginBottom: '10px',
    padding: '10px',
    listStyleType: 'disc',
    listStylePosition: 'outside',
    resize: 'vertical',

    ul: {
      paddingInlineStart: '2em',
    },

    li: {
      marginBottom: '1em',
      whiteSpace: 'pre-line',

      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
}));

const Rechtbelehrungscontainer = (props: React.PropsWithChildren) => {
  const { classes } = useRechtsbelerungsStyle();

  return <Box className={classes.container}>{props.children}</Box>;
};

export default Rechtbelehrungscontainer;
