import { Card, Code, Spoiler, Table, Title, Text, Box, Tooltip, Paper, Stack } from '@mantine/core';
import React, { useEffect, useMemo, useState } from 'react';
import { GetPredef } from './Predef';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { getHostUrl } from '@/ViteDynamicContentLoader';
import { useContainerSize } from '@/forms/shared/EnsContainerObserver';
import { DevmodeTracking } from './DevmodeDisplay.Tracking';
import { getEntryQuestionsStorageKey, loadFromLocalStorage, useSaverIsActive } from './EntryQuestionSaver';

import {
  getFilterValuesSavingKey,
  hasSavedFilterValues as getHasSavedFilterValues,
} from '@/actions/customFilter/customFilterSaver';
import { applyWebkitFix } from '@/indexAppEmotionCache';
import { detect } from 'detect-browser';
import { getPresetId } from './Preset';
import { DynamicEntryQuestionType } from '@/actions/types/DynamicEntryQuestionType';
import { useDisplayFilter } from '@/forms/shared/DynamicEntryQuestion';
import { EnsIcon } from '@/forms/shared/AvailalbeEnsIcons';
import { GetEvaluationContext } from '@/lib/FormularEvaluate';
import { useAutoloadEnsurances } from './AutoloadEns';

type HistoryItem = {
  timestamp: number;
  value: string | number | boolean;
};

const relativeStartTime = Date.now();

const useHistory = (val: string | number | boolean) => {
  const [value, setValue] = React.useState<string | number | boolean>(null);
  const [history, setHistory] = React.useState<HistoryItem[]>([]);

  useEffect(() => {
    if (val !== value) {
      setValue(val);
      setHistory((currentHist) => [...currentHist, { timestamp: Date.now(), value: val }]);
    }
  }, [val, value]);

  return history;
};

const HistoryDisplay = ({ history }: { history: HistoryItem[] }) => {
  return (
    <div>
      {history.map((item, i) => {
        if (i === 0 && !item.value) return null;
        return (
          <div key={i}>
            {item.value} ({(item.timestamp - relativeStartTime) / 1000})
          </div>
        );
      })}
    </div>
  );
};

const useShowTrackingDebug = () => {
  return useMemo(() => {
    return window.location.search.includes('showTrackingDebug');
  }, []);
};

const EntryQuestionRows = () => {
  const presetIdData = useAppSelector((state) => state.options.presetIdData);
  const presetName = useAppSelector((state) => state.userData.presetName);
  const storedEntryQuestions = loadFromLocalStorage(presetName, { options: { presetIdData: presetIdData } });

  const json = useMemo(() => JSON.stringify(storedEntryQuestions, null, 2), [storedEntryQuestions]);

  const hasLoadedEntryQuestions = useAppSelector((state) => state.userData.___savedEntryQuestionLoaded);
  const hasSavedFilterValues = useAppSelector(getHasSavedFilterValues);

  const isActive = useSaverIsActive();

  const [entryQuestionsFilterKey, setEntryQuestionsFilterKey] = React.useState<string | null>(null);
  const [filterKey, setFilterKey] = React.useState<string | null>(null);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch((_: any, getState: RootStateGetter) => {
      const state = getState();
      setFilterKey(getFilterValuesSavingKey(state, ''));
      setEntryQuestionsFilterKey(getEntryQuestionsStorageKey(state.userData.presetName, state));
    });
  });

  return (
    <tr>
      <td>Gespeicherte Eingangsfragen</td>
      <td>
        <Text>Saver: {isActive ? 'Aktiv' : 'Nicht aktiv'}</Text>
        <Text>
          Eingangsfragen: {hasLoadedEntryQuestions ? 'Vorhanden' : 'Nicht vorhanden'} (Key: {entryQuestionsFilterKey})
        </Text>
        <Text>
          Filterwerte: {hasSavedFilterValues ? 'Vorhanden' : 'Nicht vorhanden'} (Key: {filterKey})
        </Text>
        <Spoiler
          maxHeight={0}
          hideLabel={''}
          showLabel={''}>
          <Code children={json} />
        </Spoiler>
      </td>
    </tr>
  );
};

const SingleCustomQuestionDetails = (
  props: React.PropsWithChildren<{ question: DynamicEntryQuestionType; bikeId: string }>
) => {
  return (
    <Paper withBorder>
      {props.children}
      <Stack spacing={1}>
        <Code block>{props.question.displayFilter}</Code>
      </Stack>
    </Paper>
  );
};

const SingleCustomQuestion = (props: { question: DynamicEntryQuestionType }) => {
  const firstBikeId = useAppSelector((state) => Object.keys(state.userData.bikes)[0]);
  const show = useDisplayFilter(props.question, firstBikeId);

  const [showDetails, setShowDetails] = useState(false);

  const control = (
    <Box
      style={{ cursor: 'pointer' }}
      onClick={() => setShowDetails(!showDetails)}>
      <EnsIcon
        color={show ? 'green' : 'red'}
        icon={show ? 'MdOutlineCheck' : 'MdOutlineRemoveCircleOutline'}
      />{' '}
      {props.question.name}
    </Box>
  );

  if (!showDetails) return control;

  return (
    <SingleCustomQuestionDetails
      question={props.question}
      bikeId={firstBikeId}>
      {control}
    </SingleCustomQuestionDetails>
  );
};

export const CustomFieldsDisplay = () => {
  const customquestions = useAppSelector((state) => state.ensfields.customquestions);

  return (
    <Spoiler
      maxHeight={0}
      hideLabel={'Verbergen'}
      showLabel={'Anzeigen'}>
      <Stack spacing={2}>
        {customquestions.map((question, i) => (
          <SingleCustomQuestion
            key={question.name + i}
            question={question}
          />
        ))}
      </Stack>
    </Spoiler>
  );
};

export default function DevmodeDisplay() {
  const showTrackingDebug = useShowTrackingDebug();

  const autoloadEnsurances = useAutoloadEnsurances();
  const autoloadEnsurancesHistory = useHistory(autoloadEnsurances);

  const presetIdState = useAppSelector((state) => state.options.presetIdState);
  const presetIdStateHistory = useHistory(presetIdState);
  const presetId = getPresetId(useAppSelector((state) => state.ensPreset.ensPreset));
  const presetData = useAppSelector((state) => state.ensPreset.ensPresetData);

  const predefState = useAppSelector((state) => state.options.predefState);
  const predefStateHistory = useHistory(predefState);

  const devicemode = useAppSelector(
    (state) => (state.userData.deviceModeOverride || state.devicemode.devkey) + ' ' + state.userData?.insuranceType
  );
  const devicemodeHistory = useHistory(devicemode);

  const compareTableId = useAppSelector((state) => state.ensCompare.tableId);
  const compareTableIdHistory = useHistory(compareTableId);

  const hostUrl = getHostUrl();
  const hostUrlHistory = useHistory(hostUrl);

  const containerSize = useContainerSize().mtWidth;
  const containerSizeHistory = useHistory(containerSize);

  const router = useAppSelector((state) => state.router + ` (overlay: '${state.overlay.overlayName || ''}')`);
  const routerHistory = useHistory(router);

  const browser = detect();

  return (
    <Card
      withBorder
      bg={'transparent'}>
      <Title order={4}>Debug Info</Title>
      <Table
        striped
        withBorder
        withColumnBorders>
        <thead>
          <tr>
            <th>Status</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Autoload Ensurances</td>
            <td>
              <HistoryDisplay history={autoloadEnsurancesHistory} />
            </td>
          </tr>
          <tr>
            <td>Host Url</td>
            <td>
              <HistoryDisplay history={hostUrlHistory} />
            </td>
          </tr>
          <tr>
            <td>PresetId State ({presetId})</td>
            <td>
              <HistoryDisplay history={presetIdStateHistory} />
              <Spoiler
                maxHeight={0}
                hideLabel={'Verbergen'}
                showLabel={'Preset Daten anzeigen'}>
                <Code block>{JSON.stringify(presetData, null, 2)}</Code>
              </Spoiler>
            </td>
          </tr>
          <tr>
            <td>Predef State</td>
            <td>
              <HistoryDisplay history={predefStateHistory} />
            </td>
          </tr>
          <tr>
            <td>Predef</td>
            <td>{GetPredef()}</td>
          </tr>
          <tr>
            <td>Router</td>
            <td>
              <HistoryDisplay history={routerHistory} />
            </td>
          </tr>
          <tr>
            <td>Safari Emotion Fix</td>
            <td>{applyWebkitFix ? 'Ja' : 'Nein'}</td>
          </tr>
          <tr>
            <td>Browser Info</td>
            <td>
              {browser.name} - {browser.version} ({browser.type})<br />
              {browser.os}
            </td>
          </tr>
          <tr>
            <td>Devicemode</td>
            <td>
              <HistoryDisplay history={devicemodeHistory} />
            </td>
          </tr>
          <tr>
            <td>Compare Table Id</td>
            <td>
              <HistoryDisplay history={compareTableIdHistory} />
            </td>
          </tr>
          <tr>
            <td>Container Size</td>
            <td>
              <HistoryDisplay history={containerSizeHistory} />
            </td>
          </tr>
          <tr>
            <td>Custom Fields</td>
            <td>
              <CustomFieldsDisplay />
            </td>
          </tr>
          <EntryQuestionRows />
        </tbody>
      </Table>

      {showTrackingDebug && <DevmodeTracking />}
    </Card>
  );
}
