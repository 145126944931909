import { createAction, createReducer } from '@reduxjs/toolkit';

export const DefaultState = {
  consent: false,
  hash: null as string | null,
};

export const onSaveUncompletedOrderConsent = createAction('SAVE_UNCOMPLETED_OFFER_CONSENT');

export const UncompletedOrderReducer = createReducer(DefaultState, (builder) => {
  builder.addCase(onSaveUncompletedOrderConsent, (state, action) => {
    state.consent = true;
    return state;
  });
});
