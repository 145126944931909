import { LoadingStreamLoadingInfo } from '@/actions/shared/MultipartStreamParser';
import { EnsuranceOffer } from '@/actions/types/EnsuranceOffer';

const defaultValue = {
  list: null as EnsuranceOffer[] | null,
  requestedEnsurances: null as string[] | null,
  presetHeadpoints: {},
  submitting: false,
  loading: false,
  loadingSelectedInsurance: false,
  predefLoading: false,
  loadingInfo: {
    total: undefined as string,
    current: undefined as string,
  },
};

export default function ensuranceList(state = defaultValue, action): typeof defaultValue {
  if (action.type === 'ENSURANCES_LOADED') {
    return {
      ...state,
      list: action.list,
      requestedEnsurances: action.requestedEnsurances,
      loading: false,
      loadingInfo: defaultValue.loadingInfo,
    };
  } else if (action.type === 'ENSURANCES_LOADING_INFO') {
    const payload: LoadingStreamLoadingInfo = action.payload;

    const existingNames = new Set(state.list?.filter((o) => o).map((o) => o.name) || []);
    const newItems = payload.offersLoaded?.filter((o) => !existingNames.has(o.name));

    return {
      ...state,
      ...(!payload.offersLoaded ? null : { list: [...newItems, ...(state.list || [])] }),
      loadingInfo: {
        total: payload.totalItems,
        current: payload.currentItem,
      },
    };
  } else if (action.type === 'ENSURANCES_LOAD_STARTED') {
    return { ...state, loading: true, loadingInfo: defaultValue.loadingInfo, list: null };
  } else if (action.type === 'ENSURANCES_LOAD_END') {
    return { ...state, loading: false, loadingInfo: defaultValue.loadingInfo };
  } else if (action.type === 'ENSURANCES_RESET') {
    return { ...state, loading: false, loadingInfo: null, list: null };
  } else if (action.type === 'ENSURANCE_SELECTED') {
    return { ...state, loadingSelectedInsurance: true };
  } else if (action.type === 'SUBMIT_LOADING') {
    return { ...state, loading: true, submitting: true };
  } else if (action.type === 'SUBMIT_COMPLETED') {
    return { ...state, loading: false, submitting: false };
  } else if (action.type === 'ENSURANCE_SELECTED_COMPLETED') {
    return { ...state, loadingSelectedInsurance: false };
  } else if (action.type === 'PREDEF_LOADING') {
    return { ...state, predefLoading: true };
  } else if (action.type === 'PREDEF_COMPLETED') {
    return { ...state, predefLoading: false };
  } else if (action.type === 'PRESET_LOADED') {
    return { ...state, presetHeadpoints: action.preset.headpoints || {} };
  }

  return state;
}
