import { evaluateFilter } from '@/actions/customFilter';
import { CustomFilterSetActiveFilters } from '@/reducer/customFilter';

export const updateValidFilter = (skipValidityCheck = false) => {
  return async (dispatch, getState: RootStateGetter) => {
    const state = getState();

    const validFilters = (state.customFilter.filter || []).filter(
      (filter) => evaluateFilter(state, filter) || skipValidityCheck
    );

    await dispatch(CustomFilterSetActiveFilters(validFilters));
  };
};
