import { MantineSize, useMantineTheme } from '@mantine/core';
import { useResizeObserver } from '@mantine/hooks';
import React, { useContext, useMemo } from 'react';
import { createContext } from 'react';

type ContextType = {
  mtWidth: MantineSize | null;
  width: number | null;
  height: number | null;
  isXs: boolean;
  isMd: boolean;
};

const EnsContainerSizeObserverContext = createContext<ContextType>(null);

export const useIsContainerSize = (observerSize: MantineSize) => {
  const value = useContext(EnsContainerSizeObserverContext)?.mtWidth;

  return useMemo(() => {
    if (value === observerSize) return true;
    if (observerSize === 'xs') return true;

    if (observerSize === 'sm' && ['sm', 'md', 'lg', 'xl'].includes(value)) return true;

    if (observerSize === 'md' && ['md', 'lg', 'xl'].includes(value)) return true;

    if (observerSize === 'lg' && ['lg', 'xl'].includes(value)) return true;

    if (observerSize === 'xl' && ['xl'].includes(value)) return true;

    return false;
  }, [value, observerSize]);
};

export const useContainerSize = () => {
  return useContext(EnsContainerSizeObserverContext);
};

export const useIsXs = () => {
  return useContainerSize()?.isXs;
};

export const useIsMd = () => {
  return useContainerSize()?.isMd;
};

export const EnsContainerSizeObserverProvider = ({ children }) => {
  const [ref, rect] = useResizeObserver();

  const theme = useMantineTheme();

  const value: ContextType = useMemo(() => {
    const width = rect?.width || 0;

    let mtSIze: MantineSize = 'xs';

    if (width > theme.breakpoints.xl) mtSIze = 'xl';
    else if (width > theme.breakpoints.lg) mtSIze = 'lg';
    else if (width > theme.breakpoints.md) mtSIze = 'md';
    else if (width > theme.breakpoints.sm) mtSIze = 'sm';

    const isXs = theme.breakpoints.sm - 1 > width;
    const isMd = theme.breakpoints.md - 1 > width;

    return { mtWidth: mtSIze, width: rect?.width, height: rect?.height, isXs, isMd };
  }, [
    rect?.height,
    rect?.width,
    theme.breakpoints.sm,
    theme.breakpoints.md,
    theme.breakpoints.lg,
    theme.breakpoints.xl,
  ]);

  return (
    <div ref={ref}>
      <EnsContainerSizeObserverContext.Provider value={value}>{children}</EnsContainerSizeObserverContext.Provider>
    </div>
  );
};
