import { getQueryStrings } from '@/actions/shared/QueryParams';
import { DeepPartialExcept } from '../types/lib/DeepPartial';

export const getCountryCode = (state: DeepPartialExcept<RootState, { ensfields: { ens_instance_area } }> = null) => {
  const rawData = getQueryStrings()?.ensCountryCode;

  const ensInstanceSetting = state?.ensfields?.ens_instance_area;

  if (ensInstanceSetting) {
    return ensInstanceSetting;
  }

  let defaultCountryCode = typeof ensInstanceCountryCode !== 'undefined' ? ensInstanceCountryCode : 'DE';
  return (Array.isArray(rawData) ? rawData[0] : rawData) || defaultCountryCode;
};
