import React from 'react';
import { Text } from '@mantine/core';
import { useAppSelector } from '@/hooks';
import { GetComponentProps } from '@/lib/GetComponentProps';

export const EnsAgbControl = (props: { buttonLabel: string } & GetComponentProps<typeof Text>) => {
  const erstinformationenUrl = useAppSelector(
    (state) =>
      state.ensfields.ens_erstinformation_url ||
      'https://ebikeversicherungen.net/wp-content/uploads/2021/03/Erstinformation.pdf'
  );

  const { buttonLabel, ...textProps } = props;

  return (
    <Text {...textProps}>
      Mit Klick auf "{props.buttonLabel}", bestätige ich, die{' '}
      <a
        href={erstinformationenUrl}
        target="_blank"
        rel="noopener noreferrer">
        Erstinformationen und die Mitteilung zur Beratungsgrundlage, § 60 VVG
      </a>{' '}
      gelesen zu haben.
    </Text>
  );
};
