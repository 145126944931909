import { getIsAutloadStandaloneMode } from '@/features/AutoloadEns';
import { HasPredef } from '@/features/Predef';
import { localStorageGet, localStorageSet } from '@/lib/EnsLocalStorage';
import { MD5 } from 'object-hash';

type FilterType = '' | 'coreFilter';

export const getFilterValuesSavingKey = (state: RootState, mode: FilterType = '') => {
  let key = 'ens-compare-filter-' + state.devicemode.devkey + '-';

  if (getIsAutloadStandaloneMode(state)) {
    const windowHash = window.location.pathname;
    key += 'standalone-' + MD5(windowHash) + '-';
  }
  if (state.userData.insuranceType) key += state.userData.insuranceType + '-';

  if (mode) key += mode + '-';

  return key.endsWith('-') ? key.slice(0, -1) : key;
};

export const CoreFilterProps: (keyof UserDataType)[] = ['paymentPeriod', 'contractDurationYears'];
export const IsCoreFilterProp = (key: string): key is keyof UserDataType =>
  CoreFilterProps.includes(key as keyof UserDataType);

const getCoreFilterFromState = (state: RootState) => {
  return CoreFilterProps.reduce((acc, key) => ({ ...acc, [key]: state.userData[key] }), {});
};

export type EnsCoreFilter = ReturnType<typeof getCoreFilterFromState>;

export const saveCurrentFilterValues = () => {
  return async (_, getState: RootStateGetter) => {
    const state = getState();

    localStorageSet(getFilterValuesSavingKey(state), JSON.stringify(state.customFilter.filterValues));
    localStorageSet(getFilterValuesSavingKey(state, 'coreFilter'), JSON.stringify(getCoreFilterFromState(state)));
  };
};
export const getSavedFilterValues = (state: RootState, mode: FilterType = '') => {
  const key = getFilterValuesSavingKey(state, mode);

  const savedValues = localStorageGet(key, null);

  if (savedValues) return JSON.parse(savedValues);

  return null;
};

export const hasSavedFilterValues = (state: RootState, mode: FilterType = '') => {
  if (HasPredef()) return false;

  const key = getFilterValuesSavingKey(state, mode);
  return !!localStorageGet(key, null);
};

export const resetFilterValues = () => {
  return async (_, getState: RootStateGetter) => {
    const key = getFilterValuesSavingKey(getState());
    window.localStorage.removeItem(key);

    const coreKey = getFilterValuesSavingKey(getState(), 'coreFilter');
    window.localStorage.removeItem(coreKey);
  };
};
