import React from 'react';
import { useEnsfieldsValue } from '@/features/Ensfields';

export const InspectionVoucherAgbRaw = () => {
  const urlAgbInspectionVoucher = useEnsfieldsValue(
    'ens_agb_url_IV',
    'https://fahrsicherung.de/allgemeine-geschaeftsbedingungen-inspektionsgutscheine/'
  );
  const urlAgbPremiumservice = useEnsfieldsValue(
    'ens_agb_url_Premiumservice',
    'https://fahrsicherung.de/allgemeine-geschaeftsbedingungen-gegenueber-verbrauchern-b2c-premiumservice/'
  );
  const urlWiderrufPremiumservice = useEnsfieldsValue(
    'ens_widerruf_Premiumservice',
    'https://fahrsicherung.de/wp-content/uploads/2022/06/Widerruf.pdf'
  );

  return (
    <>
      Mit Klick auf "Premiumservice für 30,-€ jährlich hinzufügen" bestätige ich{' '}
      <a
        target="_blank"
        href={urlAgbInspectionVoucher} rel="noreferrer">
        die AGBs{' '}
      </a>
      der Inspektionsgutscheine,&nbsp;
      <a
        target="_blank"
        href={urlAgbPremiumservice} rel="noreferrer">
        die AGBs des Premiumservices
      </a>
      , sowie die{' '}
      <a
        target="_blank"
        href={urlWiderrufPremiumservice} rel="noreferrer">
        Widerrufsbelehrung
      </a>{' '}
      gelesen zu haben und akzeptiere diese. Hiermit ermächtige ich FahrSicherung, Zahlungen von meinem Konto mittels
      Lastschrift gemäß der AGB einzuziehen.
    </>
  );
};
