import React, { useEffect, useState } from 'react';
import { HookedTextInput } from './HookedInputs';
import { getQueryStrings } from '@/actions/shared/QueryParams';
import { Box, createStyles } from '@mantine/core';
import { useEffectWithStore } from '@/hooks';

const useStyles = createStyles(() => ({
  control: {
    opacity: '0.4',
    input: {
      textAlign: 'end',
    },
  },
}));

export const MitarbeiterkennungControl = (props: React.ComponentProps<typeof Box>) => {
  const [hideControl, setHideControl] = useState(false);

  useEffectWithStore((store) => {
    if (getQueryStrings()?.hideMitarbeiterkennung || store.ensOptions?.hideMitarbeiterkennung) {
      setHideControl(true);
    }
  }, []);

  const { classes } = useStyles();

  if (hideControl) return null;

  return (
    <Box
      {...props}
      title={'Dies kannst du eintragen, wenn du den Rechner im Auftrag deines Arbeitgebers nutzt.'}>
      <HookedTextInput
        disableWrap
        miw={300}
        className={classes.control}
        variant='unstyled'
        field={'employerTag'}
        transparent='true'
        placeholder={'Haben Sie eine Mitarbeiterkennung?'}
      />
    </Box>
  );
};
