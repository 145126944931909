import { useAppDispatch, useAppSelector, useEffectWithStore } from '@/hooks';
import { useEffect, useState } from 'react';
import { useAutoloadEnsurances } from './AutoloadEns';
import { compareDataRequested } from '@/actions/compare';
import { confirmEnsuranceOverview, navigateTo } from '@/actions/progress';
import { changeUserInput } from '@/actions/form';
import { tab } from '@testing-library/user-event/dist/types/convenience';
import requestOffers, { applyDefaultUserData } from '@/actions/data/requestOffers';
import { DeepPartialExcept } from '@/actions/types/lib/DeepPartial';
import { PresetIdData } from '@/reducer/options';
import { useIndexElements } from '@/indexElements';

export type StandaloneCompareTableEnsuranceMapping = {
  compareTableId: string;
  ensurances: string[];
};

export const getStandaloneCompareMapping = (
  state: DeepPartialExcept<
    RootState,
    { options: { presetIdData: PresetIdData }; ensOptions: { standaloneCompareMapping } }
  >
) => {
  const presetDataOverride = state.options.presetIdData?.compareStandaloneMappings?.map((data) => {
    const { ensurances, ...rest } = data;
    return { ...rest, ensurances: ensurances.split(',') };
  });

  return (presetDataOverride || state.ensOptions.standaloneCompareMapping) as
    | StandaloneCompareTableEnsuranceMapping[]
    | null;
};

export const SelectAutoloadStandaloneComapreTableId = (tableId) => {
  return async (dispatch: AppDispatch, getState: RootStateGetter) => {
    const mappings = getStandaloneCompareMapping(getState());
    const mapping = mappings.find((m) => m.compareTableId === tableId);

    await dispatch(compareDataRequested({ overrideTableId: tableId })).then(async () => {
      await dispatch(navigateTo('ensuranceList'));

      await dispatch({ type: 'SINGLE_ENS_COMPARE', name: mapping.ensurances });
    });
  };
};

export const RecalculateStandanloneCompareTableId = () => {
  return (dispatch: AppDispatch, getState: RootStateGetter) => {
    const tableId = getState().userData.__standaloneCompareTableId;
    const table = getStandaloneCompareMapping(getState()).find((m) => m.compareTableId === tableId);

    if (!table) return;

    const requestOffersParams: Parameters<typeof requestOffers> = [
      true,
      true,
      true,
      { skipResetCompareMode: true, skipBasicValidation: true, selectedNames: table.ensurances },
    ];

    dispatch(confirmEnsuranceOverview(requestOffersParams));
  };
};

export const AutoloadStandaloneFeature = () => {
  const requiredFieldsLoaded = useAppSelector((state) => state.ensfields.requiredFieldsLoaded);

  const dispatch = useAppDispatch();
  const predefState = useAppSelector((state) => state.options.predefState);
  const presetIdData = useAppSelector((state) => state.options.presetIdData);

  const selectedDevicemode = useAppSelector((state) => state.devicemode.devkey);

  const enableAutoload = useAutoloadEnsurances();

  const [isAutoloaded, setIsAutoloaded] = useState(false);

  useEffectWithStore(
    (store) => {
      if (!requiredFieldsLoaded || !predefState || predefState === 'loading' || isAutoloaded) return;
      if (enableAutoload !== 'compare_standalone') return;

      setIsAutoloaded(true);

      const mapping = getStandaloneCompareMapping(store);

      if (!mapping || mapping?.length < 1) {
        console.log('No mapping found for standalone compare');
        return;
      }

      const firstMapping = mapping[0] as StandaloneCompareTableEnsuranceMapping;

      dispatch(applyDefaultUserData());
      dispatch(changeUserInput('__standaloneCompareTableId', firstMapping.compareTableId));

      dispatch(SelectAutoloadStandaloneComapreTableId(firstMapping.compareTableId)).then(() => {
        dispatch({ type: 'COMPARE_ENS_ACTIVE_MODE' });
      });
    },
    [requiredFieldsLoaded, predefState, selectedDevicemode, enableAutoload, presetIdData, isAutoloaded]
  );

  return null;
};
