import { getQueryStrings } from '@/actions/shared/QueryParams';
import { useAppDispatch, useAppSelector, useDynamicFields } from '@/hooks';
import React, { useCallback, useEffect } from 'react';
import { ProceduralCheckoutInputs } from '../../shared/proceduralCheckout/ProceduralCheckoutInputs';
import { confirmEnsuranceOverview } from '@/actions/progress';
import { GetIsDevMode } from '../../shared/IsDevMode';
import { Box, Button } from '@mantine/core';
import { ProceduralSecondBikeSelection } from './ProceduralSecondBikeSelection';

export const getIsProcedural = () => !!getQueryStrings().ensProzeduralCheckout;

type ProceduralPage =
  | { questionsGetter: Parameters<typeof useDynamicFields>; bikeIndex?: number }
  | {
      type: 'special';
      page: 'secondBikeQuestion';
    };

const views: ProceduralPage[] = [
  {
    bikeIndex: 0,
    questionsGetter: ['objectEntryQuestionsTop'],
  },

  {
    questionsGetter: ['entryQuestionsTop'],
  },

  {
    bikeIndex: 0,
    questionsGetter: ['objectEntryQuestions'],
  },
  {
    type: 'special',
    page: 'secondBikeQuestion',
  },
  {
    bikeIndex: 1,
    questionsGetter: ['objectEntryQuestionsTop'],
  },
  {
    bikeIndex: 1,
    questionsGetter: ['objectEntryQuestions'],
  },
  {
    questionsGetter: ['entryQuestions'],
  },
  {
    questionsGetter: ['entryQuestionsMore'],
  },
];

export const ProceduralOverviewInput = (props: { onBack?: () => void }) => {
  const [viewsIndex, setViewsIndex] = React.useState(0);

  const dispatch = useAppDispatch();
  const onReloadEnsuranceList = useCallback(() => dispatch(confirmEnsuranceOverview()), [dispatch]);

  const currentView = views[viewsIndex];
  const isSpectialPage = 'type' in currentView;

  const questions = !isSpectialPage
    ? useDynamicFields(...currentView.questionsGetter)
    : useDynamicFields(null, () => false);

  const currentBikeIndex = !isSpectialPage ? currentView.bikeIndex : undefined;
  const currentBikeId = useAppSelector((state) =>
    currentBikeIndex !== undefined ? Object.keys(state.userData.bikes)[currentBikeIndex] : undefined
  );

  const onViewCompleted = useCallback(() => {
    if (viewsIndex + 1 === views.length) {
      onReloadEnsuranceList();
    } else {
      setViewsIndex((i) => i + 1);
    }
  }, [viewsIndex, questions, currentView, onReloadEnsuranceList]);

  const onViewBack = useCallback(() => {
    if (viewsIndex === 0) {
      props.onBack?.();
    } else {
      setViewsIndex((i) => i - 1);
    }
  }, [viewsIndex]);

  useEffect(() => {
    if (currentBikeIndex !== undefined && !currentBikeId) {
      onViewCompleted();
    }
  }, [currentBikeIndex, currentBikeId, onViewCompleted, currentView]);

  return (
    <Box
      mih='300px'
      style={{ display: 'flex', flexDirection: 'column' }}
      pt={'120px'}>
      {GetIsDevMode() && (
        <pre>
          {JSON.stringify(
            {
              currentView: currentView || '-keins-',
              isSpectialPage: isSpectialPage || false,
              currentBikeIndex: JSON.stringify(currentBikeIndex),
              currentBikeId: currentBikeId,
              viewsTotal: views.length,
              viewsIndex: viewsIndex,
              questions,
            },
            null,
            2
          )}
        </pre>
      )}

      {!isSpectialPage && (
        <ProceduralCheckoutInputs
          fields={questions}
          bikeId={currentBikeId}
          onCompleted={onViewCompleted}
          onPrevious={onViewBack}
          key={viewsIndex}
          pageId={viewsIndex}
        />
      )}
      {isSpectialPage && currentView.page === 'secondBikeQuestion' && (
        <ProceduralSecondBikeSelection
          onContinue={onViewCompleted}
          onBack={onViewBack}
        />
      )}
    </Box>
  );
};
