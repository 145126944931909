import { format } from 'date-fns';

export const formatPostValue = (value) => {
  if (value && typeof value.getMonth === 'function') {
    return format(value, 'yyyy-MM-dd');
  }

  if (typeof value === 'object' || Array.isArray(value)) {
    return JSON.stringify(value);
  }

  return value;
};
