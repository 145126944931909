import { useEffect, useMemo } from 'react';

import { loadEnsField } from '../actions/features/ensfields';
import { EnsFieldsReducer } from '@/reducer/ensfields';
import { useAppDispatch, useAppSelector } from '@/hooks';

function Ensfields() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadEnsField());
  }, [dispatch]);
  return null;
}

export default Ensfields;

export const useEnsfieldsValue = (ensfield: keyof EnsFieldsReducer, defaultValue: string | number | null = null) => {
  const ensFields = useAppSelector((state) => state.ensfields);
  return useMemo(() => ensFields[ensfield] || defaultValue, [ensfield, defaultValue]);
};
