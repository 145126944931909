import Numeral from 'numeral';
import 'numeral/locales/de';

const base = { ...(Numeral.locales.de || Numeral.locales.en) };

base.abbreviations = {
  thousand: ' Tsd',
  million: ' Mio',
  billion: ' Mrd',
  trillion: ' Tril'
};

Numeral.register('locale', 'de-formation', base);

Numeral.locale('de');

export const ENS_NUMERAL_LOCALE = 'de';
export const ENS_NUMERAL_LOCALE_FORMATION = 'de-formation';