import { GetIsDevMode } from '@/forms/shared/IsDevMode';

export const focusByClass = (className, focusField = true) => {
  return (_, getState: RootStateGetter) => {
    const rootId = getState().indexElements.outerDomRoot;
    const reactRootElement = document.getElementById(rootId)?.shadowRoot;

    if (!reactRootElement) {
      console.warn(`reactRootElement '${rootId}' not found for focusing '.${className}'`);
      return;
    }

    const item = reactRootElement.querySelectorAll('.' + className).item(0);

    if (!item) {
      if (GetIsDevMode())
        console.warn(`item '.${className}' cannot be fond inside reactRootElement '${rootId}' for focussing`);
      return;
    }

    item.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
    if (focusField) {
      //@ts-ignore
      item.focus();
    }
  };
};
