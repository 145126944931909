import React, { Ref, useCallback, useContext, useEffect, useMemo, useState } from 'react';

export const InnerSizeObserver = (props: { render: (size: DOMRectReadOnly | null, ref) => React.ReactNode }) => {
  const { render, ...rest } = props;

  const [ref, setRef] = useState<HTMLElement>(null);
  const [size, setSize] = useState<DOMRectReadOnly | null>(null);

  useEffect(() => {
    if (!ref) return () => {};

    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      setSize(entry.contentRect);
    });

    resizeObserver.observe(ref);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);
  return render(size, setRef);
};

export type ObservableComponents = 'CompareTableHeaderTop';
export const SizeObserverContext = React.createContext<
  {
    [x in ObservableComponents]?: Partial<DOMRectReadOnly> | null;
  } & { setSize: (target: ObservableComponents, value: Partial<DOMRectReadOnly>) => void }
>(null);

export const useGlobalSizeObserverRef = (component: ObservableComponents, disabled = false) => {
  const [ref, setRef] = useState<HTMLElement>(null);
  const context = useContext(SizeObserverContext);

  useEffect(() => {
    if (!ref || disabled) return () => {};

    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      context.setSize(component, entry.contentRect);
    });

    resizeObserver.observe(ref);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref, component, context.setSize, disabled]);

  return setRef;
};

export const SizeObserverContextProvider = (props: React.PropsWithChildren) => {
  const [sizes, setSizes] = useState<{ [x in ObservableComponents]?: Partial<DOMRectReadOnly> | null }>({});

  const setSize = useCallback((target: ObservableComponents, value: Partial<DOMRectReadOnly>) => {
    setSizes((prev) => ({ ...prev, [target]: value }));
  }, []);

  const contextValue = useMemo(
    () => ({
      ...sizes,
      setSize,
    }),
    [sizes, setSize]
  );

  return <SizeObserverContext.Provider value={contextValue}>{props.children}</SizeObserverContext.Provider>;
};

export const useObservedSize = (component: ObservableComponents) => {
  const context = useContext(SizeObserverContext);
  return context[component] || null;
};
