import React, { useCallback } from 'react';

import { Container, Grid } from '@mantine/core';
import { ButtonLink, Button } from '@unserkunde/enscompare-components/src/components/components';

import { FaAngleLeft, FaArrowRight } from 'react-icons/fa';

import { confirmEnsuranceOverview, removeSecondBike } from '../../actions/progress';

import { useInsuranceObjectName } from '../shared/InstanceNaming';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { EnsIcon } from '../shared/AvailalbeEnsIcons';
import { useEnsOverviewStyles } from './EnsuranceOverview';
import OverviewBikeInput from './OverviewBikeInput';

const ButtonRow = () => {
  const dispatch = useAppDispatch();
  const onRemoveBike = useCallback(() => dispatch(removeSecondBike()), [dispatch]);

  const { classes } = useEnsOverviewStyles();

  return (
    <Container
      px={0}
      className={classes.topButtonRow}>
      <ButtonLink
        leftIcon={<FaAngleLeft />}
        onClick={onRemoveBike}>
        Zurück
      </ButtonLink>
    </Container>
  );
};

const ButtomButtonRow = () => {
  const dispatch = useAppDispatch();
  const onReloadEnsuranceList = useCallback(() => dispatch(confirmEnsuranceOverview()), [dispatch]);
  const onRemoveBike = useCallback(() => dispatch(removeSecondBike()), [dispatch]);

  const insuranceObjectName = useInsuranceObjectName();

  return (
    <Container px={0}>
      <Grid>
        <Grid.Col
          xs={12}
          sm={6}>
          <Button
            fullWidth
            variant='outline'
            onClick={onRemoveBike}>
            <EnsIcon
              useAppColor={true}
              icon={'FaArrowLeft'}
            />
            &nbsp;2. {insuranceObjectName} entfernen
          </Button>
        </Grid.Col>
        <Grid.Col
          xs={12}
          sm={6}>
          <Button
            color='secondary'
            fullWidth
            onClick={onReloadEnsuranceList}>
            Angebote ansehen&nbsp;&nbsp;
            <FaArrowRight color='white' />
          </Button>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

const EnsuranceOverview = () => {
  const { classes } = useEnsOverviewStyles();
  const bikeId = useAppSelector(
    ({ userData }) => Object.keys(userData.bikes).filter((o) => o !== userData.defaultBikeId)[0]
  );

  return (
    <Container
      fluid
      px={0}
      className={classes.page}>
      <ButtonRow />
      <OverviewBikeInput
        bikeId={bikeId}
        bikeIndex={'2'}
        fieldsPosition='objectEntryQuestionsTop'
      />
      <OverviewBikeInput bikeId={bikeId} />
      <ButtomButtonRow />
    </Container>
  );
};

export default EnsuranceOverview;
