import React from 'react';
import { connect } from 'react-redux';

import { Col, Row } from 'react-grid-system';
import { Button, Hr } from './style/components';

const PrevNextButtonRow = ({
  onNext = null,
  onPrev = null,
  labelPrevOverride = null,
  labelNextOverride = null,
  hideNext = null,
  hideprev = null,
  logo = null,
  logoHref = null,
  loading = null,
  hideDivider = false,
  topMode = false
}) => {
  const topStyle = { marginTop: '0rem' };
  const buttomStyle = { margin: '5px 0' };
  const seperator = (
    <Row style={!topMode ? topStyle : {}}>
      <Col>
        <Hr />
      </Col>
    </Row>
  );
  const usedLogo = logo ? logo : logoHref;

  return (
    <>
      {!topMode && !hideDivider ? seperator : null}
      <Row style={topMode ? topStyle : {}}>
        <Col style={{ display: 'flex', position: 'relative', alignItems: 'center' }}>
          {!hideprev
            ? (Array.isArray(onPrev) ? onPrev : [onPrev]).map((onPrefFunc, prevIndex) => (
                <Button
                  disabled={loading}
                  key={prevIndex}
                  style={{ ...buttomStyle, marginRight: '0.5rem' }}
                  onClick={onPrefFunc}
                >
                  {!labelPrevOverride
                    ? 'Zurück'
                    : !Array.isArray(labelPrevOverride)
                    ? labelPrevOverride
                    : labelPrevOverride[prevIndex]}
                </Button>
              ))
            : null}
          <div style={{ flexGrow: '1' }} />
          {usedLogo ? (
            <>
              <img src={usedLogo} alt="Logo" style={{ height: '100%', maxHeight: '60px' }} />
              <div style={{ flexGrow: '1' }} />
            </>
          ) : null}

          {!hideNext ? (
            <>
              {(Array.isArray(onNext) ? onNext : [onNext]).map((sOnNext, i) => (
                <Button disabled={loading} key={i} style={{ ...buttomStyle, marginLeft: '0.5rem' }} onClick={sOnNext}>
                  {[labelNextOverride].flat()[i] || 'Weiter'}
                </Button>
              ))}
            </>
          ) : null}
        </Col>
      </Row>
      {topMode && !hideDivider ? seperator : null}
    </>
  );
};

const mapStateToProp = (state) => ({
  logoHref: state.options.settings.logoHref,
  loading: state.ensuranceList.loading || state.ensCompare.loading || state.options.loading
});

export default connect(mapStateToProp)(PrevNextButtonRow);
