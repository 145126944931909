import React, { useCallback } from 'react';
import { MdDoneAll } from 'react-icons/md';
import { Button, ToggleButton } from '../shared/style/components';
import { useAppDispatch, useUserData } from '../../hooks';
import { submitInspectionVoucherLegacy } from '../../actions/data/inspectionVoucherActions';

const InspectionVoucherSubmitButton = () => {
  const dispatch = useAppDispatch();
  const onSubmit = useCallback(() => dispatch(submitInspectionVoucherLegacy()), []);

  return (
    <Button display="small" onClick={onSubmit}>
      Zahlungspflichtig bestellen
    </Button>
  );
};

InspectionVoucherSubmitButton.Toggle = () => {
  const [orderPremiumservice, setOrderPremiumservice] = useUserData('orderPremiumservice', null);
  const updateorderPremiumservice = useCallback(
    () => setOrderPremiumservice(!orderPremiumservice),
    [orderPremiumservice]
  );

  return (
    <ToggleButton
      display={null}
      checked={!!orderPremiumservice}
      fadeOnChecked={false}
      onClick={updateorderPremiumservice}
    >
      {!orderPremiumservice ? null : <MdDoneAll color="white" />}
      Premiumservice {!orderPremiumservice ? 'hinzufügen' : 'hinzugefügt'}
    </ToggleButton>
  );
};

export default InspectionVoucherSubmitButton;
