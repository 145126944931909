import requestEnsurance from '@/actions/data/requestEnsurance';
import { updateVoucher } from '@/actions/data/updateVoucher';
import { useAppDispatch } from '@/hooks';
import React, { useEffect } from 'react';

export const useCustomProductsInitialLoaded = () => {
  const [loaded, setLoaded] = React.useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateVoucher({ skipUpdateEnsurance: true }))
      .then(() => dispatch(requestEnsurance()))
      .then(() => setLoaded(true));
  }, []);

  return loaded;
};
