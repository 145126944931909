import ValidatorJS from 'validatorjs';
import { DynamicEntryQuestionType, EntryQuestionPosition } from '@/actions/types/DynamicEntryQuestionType';
import { evaluateFilter } from 'forms/shared/DynamicEntryQuestion';
import { customFieldToValidatorJs, mapValidatorJsErrors } from '@/actions/validation/lib/validatorJsMapper';

const getValuesFromFields = (fields: DynamicEntryQuestionType[], sourceObject) => {
  const checkObject = {};

  fields.forEach((field) => {
    if (field.format === 'number') {
      checkObject[field.name] = parseFloat(sourceObject[field.name]);

      if (isNaN(checkObject[field.name])) {
        checkObject[field.name] = undefined;
      }
    } else {
      checkObject[field.name] = sourceObject[field.name];
    }
  });

  return checkObject;
};

export const validateDynamic = (
  userData,
  positionOrSelector: EntryQuestionPosition | ((field: DynamicEntryQuestionType) => boolean),
  state: RootState,
  bikeId: string | null = null
) => {
  let err = [];

  const objUserData = bikeId ? userData.bikes[bikeId] : userData;

  const allFieldsToCheck = state.ensfields.customquestions.filter(
    (o) =>
      (typeof positionOrSelector !== 'string' || o.position === positionOrSelector) &&
      (typeof positionOrSelector !== 'function' || positionOrSelector(o)) &&
      (o.required || o.customValidation) &&
      evaluateFilter(o, bikeId, state)
  );

  const validationRules = customFieldToValidatorJs(allFieldsToCheck, state);

  const parsedUserData = getValuesFromFields(allFieldsToCheck, objUserData);
  const validatorJsRules = new ValidatorJS(parsedUserData, validationRules);

  if (validatorJsRules.fails()) {
    err = mapValidatorJsErrors(err, validatorJsRules);
  }

  return err;
};

export const validateBikeDynamic = (
  userData,
  positionOrSelector: EntryQuestionPosition | ((field: DynamicEntryQuestionType) => boolean),
  state: RootState,
  bikeId
) => {
  const bikeErrors = {
    errors: [],
    bikeErrors: {
      [bikeId]: validateDynamic(userData, positionOrSelector, state, bikeId)
    }
  };

  return bikeErrors;
};
