import { useAppSelector } from '@/hooks';
import { EnsuranceOptionFlag } from '@/actions/types/EnsuranceOffer';

export function OptionExclude({ children, on }: { children: any; on: EnsuranceOptionFlag }) {
  const options = useAppSelector((state) => state.options.options);

  if (options.includes(on)) {
    return null;
  }
  return children;
}

export function OptionInclude({ children, on, condition }: { [x: string]: any; condition?: boolean }) {
  const options = useAppSelector((state) => state.options.options);

  if (!options.includes(on) || (typeof condition === 'boolean' && !condition)) {
    return null;
  }
  return children || null;
}

export function OptionCustom({ children, on }: { children: any; on: (options: string[]) => boolean }) {
  const options = useAppSelector((state) => state.options.options);
  if (!on(options)) {
    return null;
  }

  return children || null;
}
