import requestOffers from '../data/requestOffers';
import { saveCurrentFilterValues } from './customFilterSaver';
import { updateDisabledFilter } from './updateDisabledFilter';

export const setFilterValue = (name, value, skipReload = false, skipDisabledFilterCheck = false) => {
  return async (dispatch, getState: RootStateGetter) => {
    await dispatch({ type: 'SET_FILTER_VALUE', name, value });

    
    await dispatch(updateDisabledFilter(name, skipDisabledFilterCheck));
    
    await dispatch(saveCurrentFilterValues());

    if (!skipReload) await dispatch(requestOffers(true, true, true));
  };
};
