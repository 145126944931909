import React from 'react';

const VoucherBox = (props) => (
  <div className={'voucher-box' + (props.className ? ` ${props.className}` : '')}>
    <div className='voucher-box__wrap'>
      <div className='voucher-box__outer'>
        {props.patternLeft && <span className='voucher-box__pattern pattern-left'></span>}
        <span className='voucher-box__outer-background'></span>
        {props.patternRight && <span className='voucher-box__pattern pattern-right'></span>}
      </div>
      <div className='voucher-box__inner'>
        <div className='voucher-box__inner-wrap'>
          {props.patternLeft && <span className='voucher-box__pattern pattern-left'></span>}
          <div className='voucher-box__inner-content'>{props.children}</div>
          {props.patternRight && <span className='voucher-box__pattern pattern-right'></span>}
        </div>
      </div>
    </div>
  </div>
);

export default VoucherBox;
