import { GetIsDevMode } from '@/forms/shared/IsDevMode';

const defaultValue: {
  errors: any[];
  bikeErrors: any;
} = { errors: [], bikeErrors: {} };

export type ValidationState = typeof defaultValue;

export default function validate(state = defaultValue, action): typeof defaultValue {
  if (action.type === 'ENSURANCE_SELECTED') {
    return { ...state, errors: [] };
  }

  if (action.type === 'CHANGE_VALIDATION_ERRORS') {
    if (GetIsDevMode()) {
      console.log('Validierungsfehler: ', action.errors, action.bikeErrors);
    }
    return { errors: action.errors, bikeErrors: action.bikeErrors };
  }

  return state;
}
